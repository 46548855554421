import * as types from '../actions';
import { ErrorSuccessResponseInterface, ActionInterface } from '../interfaces';

const defaultResponseValue = {
  data: {},
  success: false,
  message: '',
};

interface UserInitialStateInterface {
  passphraseVerificationResponse: ErrorSuccessResponseInterface;
  createPassPhraseResponse: ErrorSuccessResponseInterface;
  editUserResponse: ErrorSuccessResponseInterface;
  updatePasswordResponse: ErrorSuccessResponseInterface;
  getUserNonceResponse: ErrorSuccessResponseInterface;
  emailVerificationResponse: ErrorSuccessResponseInterface;
  signUpInvitedUserResponse: ErrorSuccessResponseInterface;
  userOrganizationsResponse: ErrorSuccessResponseInterface;
  organizationUserResponse: ErrorSuccessResponseInterface;
  removeOrganizationUserResponse: ErrorSuccessResponseInterface;
  addUserToOrganizationResponse: ErrorSuccessResponseInterface;
  userUsedSpace: number;
  updateProfileLoading: boolean;
  userSignedNonce: string;
}

const initialState: UserInitialStateInterface = {
  passphraseVerificationResponse: defaultResponseValue,
  createPassPhraseResponse: defaultResponseValue,
  editUserResponse: defaultResponseValue,
  updatePasswordResponse: defaultResponseValue,
  getUserNonceResponse: defaultResponseValue,
  emailVerificationResponse: defaultResponseValue,
  signUpInvitedUserResponse: defaultResponseValue,
  userOrganizationsResponse: defaultResponseValue,
  organizationUserResponse: defaultResponseValue,
  removeOrganizationUserResponse: defaultResponseValue,
  addUserToOrganizationResponse: defaultResponseValue,
  userUsedSpace: 0,
  updateProfileLoading: false,
  userSignedNonce: 'NOT_SIGNED',
};

const userReducer = (
  state = initialState,
  action: ActionInterface = { type: '', response: {} }
) => {
  const { response } = action;

  switch (action.type) {
    case types.VERIFY_PASS_PHRASE:
      return { ...state, response };
    case types.VERIFY_PASS_PHRASE_RESPONSE:
      return { ...state, passphraseVerificationResponse: { ...response } };

    case types.CREATE_USER_KEY:
      return { ...state, response };
    case types.CREATE_USER_KEY_RESPONSE:
      return { ...state, createPassPhraseResponse: { ...response } };

    case types.GET_USER_NONCE:
      return { ...state, response };
    case types.GET_USER_NONCE_RESPONSE:
      return { ...state, getUserNonceResponse: { ...response } };

    case types.EMAIL_VERIFICATION:
      return { ...state, response };

    case types.EMAIL_VERIFICATION_RESPONSE:
      return { ...state, emailVerificationResponse: response };
    case types.UPDATE_USER_USED_SPACE:
      return { ...state, userUsedSpace: response };

    case types.SIGN_UP_INVITED_USER:
      return { ...state, response };
    case types.SIGN_UP_INVITED_USER_RESPONSE:
      return { ...state, signUpInvitedUserResponse: response };

    case types.USER_ORGANIZATIONS:
      return { ...state, response };
    case types.USER_ORGANIZATIONS_RESPONSE:
      return { ...state, userOrganizationsResponse: response };

    case types.ORGANIZATION_USER:
      return { ...state, response };

    case types.ORGANIZATION_USER_RESPONSE:
      return { ...state, organizationUserResponse: response };

    case types.REMOVE_ORGANIZATION_USER:
      return { ...state, response };

    case types.REMOVE_ORGANIZATION_USER_RESPONSE:
      return { ...state, removeOrganizationUserResponse: response };

    case types.ADD_USER_TO_ORGANIZATION:
      return { ...state, response };

    case types.ADD_USER_TO_ORGANIZATION_RESPONSE:
      return { ...state, addUserToOrganizationResponse: response };

    case types.EDIT_USER:
      return { ...state, response };
    case types.EDIT_USER_SUCCESS:
      return { ...state, editUserResponse: { ...response } };
    case types.UPDATE_PASSWORD:
      return { ...state, response };
    case types.UPDATE_PASSWORD_SUCCESS:
      return { ...state, updatePasswordResponse: { ...response } };
    case types.SET_PROFILE_UPDATE_LOADING:
      return { ...state, updateProfileLoading: action.payload };

    case types.USER_SIGN_THE_NONCE:
      return { ...state, userSignedNonce: action.payload };
    default:
      return state;
  }
};
export default userReducer;
