/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import {
  CreateBucketInterface,
  DeleteBucketInterface,
  GetBucketsByUserIdInterface,
} from '../interfaces/bucketInterface';
import { ErrorSuccessResponseInterface } from '../interfaces/responseInterface';
import { LIMIT } from '../utils/constant';

export const createBucketService = async (
  payload: CreateBucketInterface | any
): Promise<ErrorSuccessResponseInterface> => {
  try {
    const bucketInfo = payload?.bucket;
    const data = {
      name: bucketInfo?.name,
      salt: bucketInfo?.salt,
      organizationId: bucketInfo?.userId,
    };
    const authToken = payload?.bucket?.accessToken;
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}bucket/add`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      data,
    });
    const actualResponse = response.data;
    return actualResponse;
  } catch (err: any) {
    console.error(err);
    return {
      success: false,
      message: err?.response?.data?.message || err?.message,
    };
  }
};

export const getBucketsByUserIdService = async (
  payload: GetBucketsByUserIdInterface | any
): Promise<ErrorSuccessResponseInterface> => {
  try {
    const response = await axios({
      method: 'get',
      url: `${
        process.env.REACT_APP_API_BASE_URL
      }bucket/listAll?sortQuery=${JSON.stringify(
        payload?.sortQuery || payload?.payload?.sortQuery || { _id: -1 }
      )}`,
      params: {
        page: payload?.page || payload?.payload?.page || 0,
        limit: LIMIT,
        userId: payload?.userId || payload?.payload?.userId,
      },
      headers: {
        Authorization: `Bearer ${
          payload?.authToken || payload?.payload?.authToken
        }`,
      },
    });
    const actualResponse = response.data;
    return actualResponse;
  } catch (err: any) {
    return err?.response?.message || err.message;
  }
};

export const deleteBucketService = async (
  payload: DeleteBucketInterface | any
): Promise<ErrorSuccessResponseInterface> => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}bucket/delete`,
      headers: {
        Authorization: `Bearer ${payload?.payload?.authToken}`,
      },
      data: { bucketId: payload?.payload?.bucketId },
    });
    const actualResponse = response.data;
    return actualResponse;
  } catch (err: any) {
    return err?.response?.message || err.message;
  }
};
