import {
  EmailVerificationInterface,
  ForgetPasswordInterface,
  UpdatePasswordInterface,
  UserGetDetailsInterface,
  UserLoginDetailsAndHandlerInterface,
  UserSignUpInterface,
} from '../interfaces';
import * as types from './index';

export const registerUserAction = (user: UserSignUpInterface) => ({
  type: types.REGISTER_USER,
  user,
});

export const loginUserAction = (
  payload: UserLoginDetailsAndHandlerInterface
) => ({
  type: types.LOGIN_USER,
  payload,
});

export const getUserDetailsAction = (user: UserGetDetailsInterface) => ({
  type: types.GET_USER_DETAILS,
  user,
});

export const forgetPasswordEmail = (user: ForgetPasswordInterface) => ({
  type: types.FORGET_PASSWORD_EMAIL,
  user,
});

export const resetPasswordAction = (user: UpdatePasswordInterface) => ({
  type: types.RESET_PASSWORD,
  user,
});

export const emailVerificationAction = (user: EmailVerificationInterface) => ({
  type: types.VERIFY_EMAIL,
  user,
});

export const logoutAction = () => ({
  type: types.LOGOUT_USER,
});
