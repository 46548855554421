import * as types from '../../actions';
import { ActionInterface } from '../../interfaces';
const initialState = {
  deleteBucketLoading: false,
};
// eslint-disable-next-line default-param-last
const deleteBucketReducer = (state = initialState, action: ActionInterface) => {
  const { response } = action;

  switch (action.type) {
    case types.DELETE_BUCKET_SUCCESS:
      return { ...state, response };
    case types.DELETE_BUCKET_ERROR:
      return { ...state, response };
    case types.SET_BUCKET_DELETE_LOADING:
      return { ...state, deleteBucketLoading: action.payload };
    default:
      return state;
  }
};
export default deleteBucketReducer;
