import { STORAGE_CHAIN_MINTING_CONTRACT_ADDRESS } from '../config';
import { getWeb3 } from '../web3';
import { getContract } from './commonContractFunctions';

export const safeMint = async (tokenUri: string) => {
  try {
    const web3: any = await getWeb3();
    const accounts = await web3.eth.getAccounts();
    const contract = await getContract(
      web3,
      STORAGE_CHAIN_MINTING_CONTRACT_ADDRESS
    );

    const safeMintBlockchainResponse = await contract.methods
      .safeMint(accounts[0], tokenUri)
      .send({
        from: accounts[0],
      });

    return safeMintBlockchainResponse;
  } catch (err) {
    console.error(
      'file: storageChainMintingContract.ts: ~ safeMint ~ err:',
      err
    );
    throw err;
  }
};

// _burn
export const burnNft = async (tokenId: string) => {
  try {
    const web3: any = await getWeb3();
    const accounts = await web3.eth.getAccounts();

    const contract = await getContract(
      web3,
      STORAGE_CHAIN_MINTING_CONTRACT_ADDRESS
    );

    const burnNftBlockchainResponse = await contract.methods
      .burn(tokenId)
      .send({
        from: accounts[0],
      });

    return burnNftBlockchainResponse;
  } catch (err) {
    console.error('file: storageChainMintingContract.ts ~ burnNft ~ err:', err);
    throw err;
  }
};
