import axios from 'axios';
import {
  CreatePaymentInformationInterface,
  ErrorSuccessResponseInterface,
} from '../interfaces';
import {
  CancelCheckoutInterface,
  CancelSubscriptionInterface,
  PackageSubscriptionPayload,
  UpdatePackageSubscriptionPayload,
} from '../interfaces/paymentInterface';

const createPaymentService = async (
  createPaymentInfromation: CreatePaymentInformationInterface
): Promise<ErrorSuccessResponseInterface> => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}payment/checkout`,
      data: {
        packageName: createPaymentInfromation?.packageName,
        packageID: createPaymentInfromation.packageID,
        amount: createPaymentInfromation.amount,
        currency: createPaymentInfromation.currency,
        spaceAllowed: createPaymentInfromation.selectedPackage.spaceAllowed,
        spaceAllowedIn: createPaymentInfromation.selectedPackage.spaceAllowedIn,
        cancelUrl: createPaymentInfromation.cancelUrl,
        successUrl: createPaymentInfromation.successUrl,
        email: createPaymentInfromation.email,
      },
      headers: {
        Authorization: `Bearer ${createPaymentInfromation?.authToken}`,
      },
    });
    return response.data;
  } catch (err: any) {
    console.error('file: paymentService ~ err:', err);
    return err.response.data || err.message;
  }
};
const cancelSubscriptionService = async (
  payload: CancelSubscriptionInterface
) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}payment/cancelSubscription`,
      headers: {
        Authorization: `Bearer ${payload?.authToken}`,
      },
    });
    return response.data;
  } catch (err: any) {
    console.error(
      'file: paymentService.ts ~ cancelSubscriptionService ~ err:',
      err
    );
    return err.response.data || err.message;
  }
};

const getPurchasedSpaceService = async (authToken: string) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_URL}payment/purchased-space`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    return response.data;
  } catch (err: any) {
    console.error(
      'file: paymentService.ts ~ cancelSubscriptionService ~ err:',
      err
    );
    return err.response.data || err.message;
  }
};

const cancelCheckoutSessionService = async (
  payload: CancelCheckoutInterface
) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}payment/cancelCheckoutSession`,
      headers: {
        Authorization: `Bearer ${payload.authToken}`,
      },
    });
    return response.data;
  } catch (err: any) {
    console.error(
      'file: paymentService.ts ~ cancelSubscriptionService ~ err:',
      err
    );
    return err.response.data || err.message;
  }
};

const addSubscriptionPackage = async (
  payload: PackageSubscriptionPayload,
  access_token: string
) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}payment/add-package-subscription`,
      data: payload,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    console.log('err:', err);

    return err.response.data || err.message;
  }
};

const updateSubscriptionPackage = async (
  payload: UpdatePackageSubscriptionPayload,
  access_token: string
) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}payment/update-packageSubscription`,
      data: payload,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    console.log('err:', err);

    return err.response.data || err.message;
  }
};

export {
  createPaymentService,
  cancelSubscriptionService,
  getPurchasedSpaceService,
  cancelCheckoutSessionService,
  addSubscriptionPackage,
  updateSubscriptionPackage,
};
