import {
  CANCEL_CHECKOUT_SESSION,
  CANCEL_SUBSCRIPTION,
  CREATE_PAYMENT,
  PURCHASED_SPACE,
  UPDATE_PAYMENT_STATUS,
} from './index';

import {
  CreatePaymentInformationInterface,
  UpdatePaymentStatusInterface,
} from '../interfaces';
import {
  CancelCheckoutInterface,
  CancelSubscriptionInterface,
} from '../interfaces/paymentInterface';

const createPaymentAction = (payload: CreatePaymentInformationInterface) => ({
  type: CREATE_PAYMENT,
  payload,
});

const updatePaymentStatusAction = (payload: UpdatePaymentStatusInterface) => ({
  type: UPDATE_PAYMENT_STATUS,
  payload,
});

const cancelSubscriptionAction = (payload: CancelSubscriptionInterface) => ({
  type: CANCEL_SUBSCRIPTION,
  payload,
});

const getPurchasedSpaceAction = (authToken: string) => ({
  type: PURCHASED_SPACE,
  payload: authToken,
});

const cancelCheckoutSessionAction = (payload: CancelCheckoutInterface) => ({
  type: CANCEL_CHECKOUT_SESSION,
  payload,
});

export {
  createPaymentAction,
  updatePaymentStatusAction,
  cancelSubscriptionAction,
  getPurchasedSpaceAction,
  cancelCheckoutSessionAction,
};
