/* eslint-disable @typescript-eslint/ban-ts-comment */
import { call, put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  uploadFileService,
  addFileInDbService,
  fetchUserFiles,
  grantAccess,
  getFileAccess,
  getUserAccess,
  revokeAccess,
  setInvitesAccessService,
  getUserAccessesService,
  mintFileService,
  getMintedFiles,
  getNetworkUsedStorageSpaceService,
  updateFileFolderNameService,
  fetchUserSharedFolderFilesService,
  moveFileAndFolderService,
} from '../services/fileService';

import {
  ClusterFileUploadResponse,
  FileAddDbInterface,
  FileUploadInterface,
  FilUploadSagaInteface,
  FetchUserFilesSagaInterface,
  ErrorSuccessResponseInterface,
  PaginationInterface,
  GrantAccessSagaInterface,
  GetFileAccessSagaInterface,
  GetUserAccessSagaInterface,
  RevokeAccessSagaInterface,
  InvitesAccessSagaInterface,
  GetUserInviteAccessSagaInterface,
  MintFileSagaInterface,
  MintedFilesSagaInterface,
  // UpdateInformationInterface,
  UpdateInformationInterfaceSaga,
  SharedFolderFilesQueryObjectSaga,
  MoveFileAndFolderSagaInterface,
} from '../interfaces';
import {
  FETCH_USER_FILES,
  UPDATE_FILE_CHUNKS_RESPONSE,
  UPDATE_USER_FILES,
  UPDATE_USER_ACCESS,
  SAVE_SHARE_FILE_ACCESS,
  SAVE_USER_ACCESS,
  GET_USER_ACCESS,
  GET_USER_USED_SPACE,
  SAVE_USER_INVITE_ACCESS,
  USER_FILES_LOADER,
  MINT_FILE_RESPONSE,
  MINTED_FILES_RESPONSE,
  MINT_FILE_LOADING,
  NETWORK_USED_STORAGE_SPACE_RESPONSE,
  NETWORK_USED_STORAGE_SPACE_LOADER,
  FILE_FOLDER_NAME_UPDATE_RESPONSE,
  SHARED_FOLDER_FILES_RESPONSE,
  MOVE_FILES_AND_FOLDERS_RESPONSE,
} from '../actions';

interface FilUploadSagaAction {
  type: string;
  payload: FilUploadSagaInteface;
  dispatch?: any;
  setLoading: Function;
}

export function* fetchUserFilesSaga(action: FetchUserFilesSagaInterface) {
  yield put({ type: USER_FILES_LOADER, response: true });

  const userFiles: ErrorSuccessResponseInterface = yield call(
    // @ts-ignore
    fetchUserFiles,
    action.payload.paginationData.userId,
    action.payload.paginationData.bucketId,
    action.payload.authToken,
    action.payload.paginationData.page,
    action.payload.paginationData?.sortQuery,
    action.payload.paginationData.folderId,
    action.payload.paginationData.searchedText,
    action.payload.paginationData.metaSearch
  );
  yield put({
    type: UPDATE_USER_FILES,
    response: {
      data: userFiles.data.data,
      pagination: userFiles.data.pagination,
    },
  });
  yield put({ type: USER_FILES_LOADER, response: false });
}

export function* grantAccessSaga(action: GrantAccessSagaInterface) {
  const fileAccess: ErrorSuccessResponseInterface = yield call(
    grantAccess,
    action.payload.data,
    action.payload.authToken
  );

  yield put({
    type: UPDATE_USER_ACCESS,
    response: fileAccess.data,
  });
}

export function* setInvitesAccessSaga(action: InvitesAccessSagaInterface) {
  const fileAccess: ErrorSuccessResponseInterface = yield call(
    setInvitesAccessService,
    action.payload.data,
    action.payload.authToken
  );

  if (fileAccess.success) {
    toast.success('Access shared successfully');
  }
}

export function* getUserAccessesSaga(action: GetUserInviteAccessSagaInterface) {
  const userAccesses: ErrorSuccessResponseInterface = yield call(
    getUserAccessesService,
    action.payload.authToken,
    action.payload.page
  );
  yield put({
    type: SAVE_USER_INVITE_ACCESS,
    response: userAccesses.data,
  });
}

export function* getFileAccessSaga(action: GetFileAccessSagaInterface) {
  const fileAccess: ErrorSuccessResponseInterface = yield call(
    getFileAccess,
    action.payload.accessKey
  );

  yield put({
    type: SAVE_SHARE_FILE_ACCESS,
    response: fileAccess.data,
  });
}

export function* getUserAccessSaga(action: GetUserAccessSagaInterface) {
  const userAccess: ErrorSuccessResponseInterface = yield call(
    getUserAccess,
    action.payload.authToken
  );

  yield put({
    type: SAVE_USER_ACCESS,
    response: userAccess.data,
  });
}

export function* revokeAccessSaga(action: RevokeAccessSagaInterface) {
  yield call(revokeAccess, action.payload.authToken, action.payload.accessId);

  yield put({
    type: GET_USER_ACCESS,
    payload: { authToken: action.payload.authToken },
  });
}

export function* uploadFile(action: FilUploadSagaAction) {
  try {
    if (action?.payload?.setLoading) {
      action?.payload?.setLoading(true);
    }
    const { dispatch } = action?.payload ?? {};
    const { fileData, authToken, bucketId } = action.payload;
    const response: ClusterFileUploadResponse = yield call(
      uploadFileService,
      fileData
    );
    const fileUploadingProgress: FileUploadInterface = yield select(
      (state: any) => state.fileReducer.filesUploadingProgress
    );

    // File has multiple chunks then update each chunk uploading state in store.
    if (fileData.totalChunks > 1) {
      // @ts-ignore
      fileUploadingProgress[response.name] = {
        // @ts-ignore
        ...fileUploadingProgress[response.name],
        // @ts-ignore
        totalChunksUploaded: fileUploadingProgress[response.name]
          .totalChunksUploaded
          ? [
              // @ts-ignore
              ...fileUploadingProgress[response.name].totalChunksUploaded,
              response,
            ]
          : [response],
      };

      yield put({
        type: UPDATE_FILE_CHUNKS_RESPONSE,
        response: fileUploadingProgress,
      });
    }

    if (fileData.totalChunks === 1) {
      const fileDbData: FileAddDbInterface = {
        name: fileData.name,
        iv: fileData.iv,
        totalChunks: fileData.totalChunks,
        fileSize: fileData.size,
        fileType: fileData.fileType,
        ipfsCid: response.cid,
        chunksMetaData: [response],
        bucketId,
        objectType: 'file',
        folderId: fileData?.folderId,
        userId: action?.payload?.userId,
        organizationId: fileData?.organizationId,
      };

      yield call(addFileInDbService, fileDbData, authToken);
      const fileUploadingProgress: FileUploadInterface = yield select(
        (state: any) => state.fileReducer.filesUploadingProgress
      );

      // @ts-ignore
      delete fileUploadingProgress[fileData.name];
      yield put({
        type: UPDATE_FILE_CHUNKS_RESPONSE,
        response:
          Object.keys(fileUploadingProgress)?.length > 0
            ? fileUploadingProgress
            : {},
      });
      dispatch({ type: 'REMOVE_FILE' });
      dispatch({ type: 'PROCESS_COMPLETE' });
      // const paginationData: PaginationInterface = {
      //   page: 1,
      //   userId: action?.payload?.userId,
      //   sortQuery: { _id: -1 },
      // };

      // if (bucketId) {
      //   paginationData.bucketId = bucketId;
      // }

      // if (fileData?.folderId) {
      //   paginationData.folderId = fileData.folderId;
      // }

      // const fetchUserFilesPayload = {
      //   paginationData,
      //   authToken,
      // };

      // yield put({ type: FETCH_USER_FILES, payload: fetchUserFilesPayload });
    }

    const fileUploadingProgressLatest: FileUploadInterface = yield select(
      (state: any) => state.fileReducer.filesUploadingProgress
    );

    if (
      fileData.totalChunks > 1 &&
      // @ts-ignore
      fileUploadingProgressLatest[fileData.name].totalChunksUploaded?.length ===
        fileData.totalChunks
    ) {
      const fileDbData: FileAddDbInterface = {
        name: fileData.name,
        iv: fileData.iv,
        totalChunks: fileData.totalChunks,
        fileSize: fileData.size,
        fileType: fileData.fileType,
        ipfsCid: response.cid,
        chunksMetaData:
          // @ts-ignore
          fileUploadingProgressLatest[fileData.name].totalChunksUploaded,
        bucketId,
        objectType: 'file',
        folderId: fileData?.folderId,
        userId: action?.payload?.userId,
        organizationId: fileData?.organizationId,
      };

      yield call(addFileInDbService, fileDbData, authToken);

      // @ts-ignore
      delete fileUploadingProgress[fileData.name];
      yield put({
        type: UPDATE_FILE_CHUNKS_RESPONSE,
        response:
          Object.keys(fileUploadingProgress)?.length > 0
            ? fileUploadingProgress
            : {},
      });
      dispatch({ type: 'REMOVE_FILE' });
      dispatch({ type: 'PROCESS_COMPLETE' });
      const paginationData: PaginationInterface = {
        page: 1,
        userId: action?.payload?.userId,
        sortQuery: { _id: -1 },
      };

      if (bucketId) {
        paginationData.bucketId = bucketId;
      }
      if (fileData?.folderId) {
        paginationData.folderId = fileData?.folderId;
      }

      const fetchUserFilesPayload = {
        paginationData,
        authToken,
      };

      yield put({ type: FETCH_USER_FILES, payload: fetchUserFilesPayload });
    }
    yield put({ type: GET_USER_USED_SPACE, payload: { authToken } });
  } catch (error) {
    // yield put({ type: types.VERIFY_EMAIL_ERROR, error });
  } finally {
    if (action?.payload?.setLoading) {
      action?.payload?.setLoading(true);
    }
  }
}

export function* mintFileSaga(payload: MintFileSagaInterface) {
  try {
    const response: ErrorSuccessResponseInterface = yield call(
      mintFileService,
      payload.payload
    );

    if (response?.success) {
      payload.payload.loadUserFiles();
      toast.success(response?.message);
    }
    if (!response?.success) {
      toast.error(response?.message);
    }
    yield put({ type: MINT_FILE_RESPONSE, response });
  } catch (err) {
    console.error('file: fileSaga.ts ~ function*mintFileSaga ~ err:', err);
    yield put({ type: MINT_FILE_RESPONSE, err });
  } finally {
    payload.payload.setMintLoading({ loading: false, fileId: '' });
  }
}

export function* getMintedFilesSaga(payload: MintedFilesSagaInterface) {
  try {
    yield put({ type: MINT_FILE_LOADING, response: true });
    const response: ErrorSuccessResponseInterface = yield call(
      getMintedFiles,
      payload.payload
    );

    yield put({ type: MINTED_FILES_RESPONSE, response });
  } catch (err) {
    console.error(
      'file: fileSaga.ts: ~ function*getMintedFilesSaga ~ err:',
      err
    );
    yield put({ type: MINTED_FILES_RESPONSE, err });
  } finally {
    yield put({ type: MINT_FILE_LOADING, response: false });
  }
}

export function* getNetworkUsedStorageSpaceSaga() {
  try {
    yield put({ type: NETWORK_USED_STORAGE_SPACE_LOADER, response: true });
    const response: ErrorSuccessResponseInterface = yield call(
      getNetworkUsedStorageSpaceService
    );
    yield put({ type: NETWORK_USED_STORAGE_SPACE_RESPONSE, response });
  } catch (err) {
    console.error(
      'file: fileSaga.ts ~ function*getNetworkUsedStorageSpaceSaga ~ err:',
      err
    );
    yield put({ type: NETWORK_USED_STORAGE_SPACE_RESPONSE, response: false });
  } finally {
    yield put({ type: NETWORK_USED_STORAGE_SPACE_LOADER, response: false });
  }
}
export function* moveFileAndFolderSaga(
  payload: MoveFileAndFolderSagaInterface
) {
  try {
    const response: ErrorSuccessResponseInterface = yield call(
      moveFileAndFolderService,
      payload.payload
    );

    if (!response?.success) {
      toast.error(response?.message);
    }

    yield put({ type: MOVE_FILES_AND_FOLDERS_RESPONSE, response });
  } catch (err) {
    yield put({ type: MOVE_FILES_AND_FOLDERS_RESPONSE, err });
  } finally {
    payload.payload.setMoveFileAndFolder({});
    payload?.payload?.loadUserFiles();
  }
}

export function* updateFileFolderNameSaga({
  payload,
}: UpdateInformationInterfaceSaga) {
  try {
    const response: ErrorSuccessResponseInterface = yield call(
      updateFileFolderNameService,
      payload
    );
    yield put({ type: FILE_FOLDER_NAME_UPDATE_RESPONSE, response });
  } catch (err) {
    console.error(
      'file: fileSaga.ts:370 ~ function*updateFileFolderNameSaga ~ err:',
      err
    );
    yield put({ type: FILE_FOLDER_NAME_UPDATE_RESPONSE, response: false });
  }
}
export function* getSharedFolderFilesSaga({
  payload,
}: SharedFolderFilesQueryObjectSaga) {
  try {
    yield put({ type: USER_FILES_LOADER, response: true });
    const response: ErrorSuccessResponseInterface = yield call(
      fetchUserSharedFolderFilesService,
      payload
    );

    yield put({ type: SHARED_FOLDER_FILES_RESPONSE, response });
  } catch (err) {
    console.error('file: fileSaga.ts ~ function*mintFileSaga ~ err:', err);
    yield put({ type: USER_FILES_LOADER, response: false });
    yield put({ type: SHARED_FOLDER_FILES_RESPONSE, err });
  } finally {
    yield put({ type: USER_FILES_LOADER, response: false });
    console.log('TODO: Loading Implementation while loading shared files.');
  }
}

export default {};
