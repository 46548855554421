import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import {
  useDispatch,
  // useDispatch,
  useSelector,
} from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';
// import { loadStripe } from '@stripe/stripe-js';
import styles from './styles.module.scss';
import SVG from '../../../assets/images/Svg';
// import { createPaymentAction } from '../../../actions/paymentActions';
// import useLocalStorage from '../../../hooks/useLocalStorage';
import { generatePackages } from '../../../utils/constant';
import {
  SinglePackageInterface,
  PackageMetadataInterface,
  PackageInterface,
} from '../../../interfaces';
import { loginWithMetaMask } from '../../../web3/web3';
import {
  getCurrentPriceForWSTORCoinOnlyValue,
  verifyWalletAddress,
} from '../../../utils/helpers/helper';
import useLocalStorage from '../../../hooks/useLocalStorage';
import { purchasePackage } from '../../../web3/ContractIntegration';
import UpgradeWarningPopupModal from './UpgradeWarningPopup';

interface Props {
  show: boolean;
  setShow: Function;
}

function ModalWrap({ show, setShow }: Props) {
  const dispatch = useDispatch();
  const [userDetails] = useLocalStorage('user', {});
  const [authToken] = useLocalStorage('accessToken', '');
  const [openWarningPopup, setOpenWarningPopup] = useState<boolean>(false);
  const [selectedPackage, setSelectedPackage] =
    useState<SinglePackageInterface>({} as SinglePackageInterface);

  const paymentLoader = useSelector(
    (state: any) => state.paymentReducer.loader
  );
  const [_connectedWallet, setConnectedWallet] = useLocalStorage(
    'walletAddress',
    ''
  );

  const userPurchasedSpace = useSelector(
    (state: any) => state?.paymentReducer?.purchaseSpaceResponse
  );

  const [selectedPaymentType, setSelectedPaymentType] = useState<string>('USD');
  // const [authToken] = useLocalStorage('accessToken', '');
  // const [user] = useLocalStorage('user', {});
  const [packages, setPackages] = useState<PackageInterface | null>(null);
  console.log('ModalWrap ~ packages:', packages);

  const handlePurchaseClick = async (singlePackage: SinglePackageInterface) => {
    console.log('handlePurchaseClick ~ singlePackage:', singlePackage);
    try {
      if (selectedPaymentType === 'USD') {
        setSelectedPackage(singlePackage);
        setOpenWarningPopup(true);
      }
      if (selectedPaymentType === 'STOR') {
        try {
          const PACKAGES_ALLOWED_SPACE = {
            '3TB Plan': 3 * 1024 * 1024 * 1024 * 1024,
            '5TB Plan': 5 * 1024 * 1024 * 1024 * 1024,
          };
          const connectedAccount = await loginWithMetaMask();

          const isConnectedWalletAssociatedWithAccount =
            await verifyWalletAddress(userDetails?.walletAddress);

          if (!isConnectedWalletAssociatedWithAccount) {
            throw new Error(
              `Please connect with your associated wallet address. ${userDetails?.walletAddress}`
            );
          }

          setConnectedWallet(connectedAccount);

          await purchasePackage(
            {
              userId: userDetails?._id,
              packageName: singlePackage?.planTitle,
              currency: 'STOR',
              amount: singlePackage?.packagePrice?.toString(),
              status: 'pending',
              paymentStatus: 'pending',
              spaceAllowed:
                PACKAGES_ALLOWED_SPACE[
                  singlePackage?.planTitle as keyof typeof PACKAGES_ALLOWED_SPACE
                ],
              expireDate: moment().add(1, 'years').unix()?.toString(),
            },
            authToken,
            dispatch
          );
        } catch (err: any) {
          console.error('handlePurchaseClick ~ err:', err);
          toast.error(err?.message);
        }
      }
    } catch (err: any) {
      toast.error(err?.message);
      console.error('file: index.tsx ~ handlePurchaseClick ~ err:', err);
    }
  };

  const handlePaymentTypeChange = (paymentType: string) => {
    setSelectedPaymentType(paymentType);
  };

  useEffect(() => {
    const fetchPackages = async () => {
      const coinPrice: any = await getCurrentPriceForWSTORCoinOnlyValue();
      console.log('fetchPackages ~ coinPrice:', coinPrice);

      const generatedPackage = await generatePackages(coinPrice);
      setPackages(generatedPackage);
    };
    fetchPackages();
  }, []);

  return (
    <Modal
      show={show}
      centered
      className={styles.modal}
      onHide={() => {
        setShow(false);
      }}
    >
      <Modal.Header className='bg-secondary'>
        <Modal.Title id='contained-modal-title-vcenter'>
          Upgrade to Pro Account
        </Modal.Title>
        <div className={styles.cross} onClick={() => setShow(false)}>
          <SVG.Cross />
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className='d-flex align-items-center justify-content-between'>
          <p className='fs-20 fw-bold ff-poppins'>Add Payment Method</p>
          <div className='tabs d-flex align-items-center'>
            <span
              className={`${selectedPaymentType === 'STOR' && 'active'}`}
              onClick={() => handlePaymentTypeChange('STOR')}
            >
              STOR Token
            </span>
            <span
              className={`ms-12 ${selectedPaymentType === 'USD' && 'active'}`}
              onClick={() => handlePaymentTypeChange('USD')}
            >
              Card
            </span>
          </div>
        </div>

        <div className='packages-card mt-10'>
          <div className='row'>
            {packages &&
              packages[selectedPaymentType as keyof typeof packages].map(
                (singlePackage: SinglePackageInterface) => (
                  <div key={singlePackage?.planTitle} className='col-md-6'>
                    <div className='pricing-card'>
                      <div className='plan-heading'>
                        {singlePackage.planTitle}
                      </div>
                      <div className='pricing my-10'>
                        {singlePackage.planCharges}
                      </div>
                      <div className='plan-time'>
                        {singlePackage.billingType}
                      </div>
                      <div className='d-flex flex-column mt-10'>
                        {singlePackage.metadata.map(
                          (packageMetadata: PackageMetadataInterface) => (
                            <div
                              key={packageMetadata?.description}
                              className='d-flex align-items-center plan-point'
                            >
                              {packageMetadata?.checked ? (
                                <SVG.CheckIcon className='me-12' />
                              ) : (
                                <SVG.UnCheckIcon className='me-12' />
                              )}
                              <div>{packageMetadata?.description}</div>
                            </div>
                          )
                        )}
                      </div>
                      <button
                        className='btn btn-secondary rounded-pill w-100 py-10'
                        onClick={() => handlePurchaseClick(singlePackage)}
                        disabled={
                          paymentLoader ||
                          userPurchasedSpace?.data?.packageName ===
                            singlePackage?.planTitle
                        }
                      >
                        {userPurchasedSpace?.data?.packageName ===
                        singlePackage?.planTitle
                          ? 'Current Plan'
                          : 'Purchase'}
                      </button>
                    </div>
                  </div>
                )
              )}
          </div>
        </div>
      </Modal.Body>
      <UpgradeWarningPopupModal
        onHide={() => {
          setOpenWarningPopup(false);
        }}
        show={openWarningPopup}
        title='Helcim Checkout Warning'
        body='You are being redirected to the Helcim payment checkout page, where you will be asked to provide your name and email. Please ensure that you enter the  email address and name associated with your StorageChain account when processing the payment. Thank you.'
        proceedButtonClick={() => {
          const PACKAGES = {
            '3TB Plan': process.env.REACT_APP_2TB_PACKAGE,
            '5TB Plan': process.env.REACT_APP_5TB_PACKAGE,
          };

          const newWindow = window.open(
            PACKAGES[selectedPackage?.planTitle as keyof typeof PACKAGES],
            '_blank'
          );
          if (newWindow) newWindow.opener = null;
        }}
      />
    </Modal>
  );
}

export default ModalWrap;
