import React from 'react';
import Modal from 'react-bootstrap/Modal';
import styles from './styles.module.scss';

interface UpgradeWarningPopupProps {
  title: string;
  body: string;
  show: boolean;
  onHide: () => void;
  proceedButtonClick: () => void;
}

function UpgradeWarningPopupModal(props: UpgradeWarningPopupProps) {
  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      className={styles.encryptionModal}
    >
      <Modal.Header closeButton className='p-30 pb-0 border-0'></Modal.Header>
      <Modal.Body className='p-30 pt-0 d-flex flex-column align-items-center'>
        {/* <SVG.Encrypt /> */}
        <h5 className='text-light my-3 fw-bold mt-20 mb-3'>{props?.title}</h5>
        <p className='text-light ff-poppins text-center mb-30'>{props?.body}</p>
        <div className='d-flex gap-10'>
          <button
            className='btn btn-primary me-10 nowrap d-flex align-items-center'
            onClick={() => props?.onHide()}
          >
            Cancel
          </button>
          <button
            className='btn btn-secondary  me-10 nowrap d-flex align-items-center'
            onClick={() => props?.proceedButtonClick()}
          >
            Agree & Proceed
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default UpgradeWarningPopupModal;
