import React, { useState, useEffect, ReactNode } from 'react';

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

// eslint-disable-next-line react/function-component-definition
const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({
  children,
}: ErrorBoundaryProps) => {
  const [errorState, setErrorState] = useState<ErrorBoundaryState>({
    hasError: false,
  });

  useEffect(() => {
    const handleError = (error: ErrorEvent) => {
      setErrorState({ hasError: true });
      console.error(error);
    };

    window.addEventListener('error', handleError);

    return () => {
      window.removeEventListener('error', handleError);
    };
  }, []);
  if (errorState.hasError) {
    return (
      <div className='coming-soon authpage'>
        <div className='backdrop access-page'>
          <div className='py-150 d-flex flex-column align-items-center'>
            <h2 className='text-light mb-30 ff-poppins'>
              Oops! Something went wrong. Please try again later.
            </h2>
          </div>
        </div>
      </div>
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
export default ErrorBoundary;
