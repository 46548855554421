// eslint-disable-next-line import/no-unresolved
import Web3 from 'web3';
import { networks } from './networksParams';

interface NativeCurrencyInterface {
  name: string;
  symbol: string;
  decimals: number;
}

interface NetworkParamsInterface {
  chainId: string;
  chainName: string;
  nativeCurrency: NativeCurrencyInterface;
  rpcUrls: Array<string>;
  blockExplorerUrls: Array<string>;
}

const isMetaMaskAvailable = (): boolean => {
  if ((window as any).ethereum === undefined) {
    return false;
  }
  return true;
};

const getWeb3 = async (): Promise<Web3 | undefined> => {
  if (!isMetaMaskAvailable()) throw new Error('MetaMask is not installed.');
  const web3 = new Web3((window as any)?.ethereum);
  const accounts = await web3.eth.getAccounts();
  if (accounts?.length > 1) {
    console.error(
      '~ file: web3.tsx:31~ getWeb3 ~ Error:',
      'You have to connect only one account at a time with the site. Goto MetaMask and diconnect all other accounts from this site'
    );
    throw new Error(
      'You have to connect only one account at a time with the site. Goto MetaMask and diconnect all other accounts from this site'
    );
  }
  return web3;
};

const getCustomRPCWeb3Instance = async (RPC: string) => {
  try {
    if (!isMetaMaskAvailable()) return;

    const web3Provider = new Web3.providers.HttpProvider(RPC);
    const web3 = new Web3(web3Provider);
    const accounts = await web3.eth.getAccounts();
    if (accounts?.length > 1) {
      console.error(
        '~ file: web3.tsx:51~ getWeb3 ~ Error:',
        'You have to connect only one account at a time with the site. Goto MetaMask and diconnect all other accounts from this site'
      );
      throw new Error(
        'You have to connect only one account at a time with the site. Goto MetaMask and diconnect all other accounts from this site'
      );
    }
  } catch (err) {
    console.error('~ file: web3.tsx:53 ~ getCustomRPCWeb3Instance ~ err:', err);
  }
};

// eslint-disable-next-line arrow-body-style
const getChainId = async () => {
  return parseInt(
    await (window as any)?.ethereum.request({ method: 'eth_chainId' })
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const addChainNetworkIfNotExists = async (
  networkParams: NetworkParamsInterface
) => {
  try {
    return await (window as any)?.ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [networkParams],
    });
  } catch (err) {
    console.error('~ file: config.tsx ~ line  ~ err', err);
    return err;
  }
};

const switchMetaMaskNetwork = async (chainId: string, networkParams: any) => {
  try {
    return await (window as any)?.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId }],
    });
  } catch (err: any) {
    console.error('~ file: web3.tsx: ~ err:', err);

    if (err.code === 4902) {
      try {
        await addChainNetworkIfNotExists(networkParams);
        return true;
      } catch (err) {
        return false;
      }
    }

    return err;
  }
};

const checkForMetamaskNetwork = async () => {
  try {
    const checkForMetamaskAvailability = await isMetaMaskAvailable();

    if (!checkForMetamaskAvailability) return false;

    const chainId = await getChainId();

    if (
      process.env.REACT_APP_NETWORK === 'devnet' &&
      chainId !== parseInt(process.env.REACT_APP_STORAGECHAIN_CHAINID || '0')
    ) {
      const switchNetworkResponse = await switchMetaMaskNetwork(
        process.env.REACT_APP_STORAGE_CHAIN_NETWORK_SWITCH as string,
        networks.storageChain.testnet
      );

      if (switchNetworkResponse?.code === 4001) {
        return switchNetworkResponse;
      }
      return true;
    }

    if (
      process.env.REACT_APP_NETWORK === 'mainnet' &&
      chainId !== parseInt(process.env.REACT_APP_STORAGECHAIN_CHAINID || '0')
    ) {
      const switchNetworkResponse = await switchMetaMaskNetwork(
        process.env.REACT_APP_STORAGE_CHAIN_NETWORK_SWITCH as string,
        networks.storageChain.mainnet
      );
      if (switchNetworkResponse?.code === 4001) {
        return switchNetworkResponse;
      }
      return true;
    }
    return true;
  } catch (err) {
    console.error('~ file: web3.tsx:149 ~ checkForMetamaskNetwork ~ err:', err);
    throw err;
  }
};

const getAccounts = async () => {
  const accounts = await (window as any)?.ethereum.request({
    method: 'eth_requestAccounts',
  });
  return accounts;
};

const getConnectedAccount = async () => {
  const web3: any = await getWeb3();
  const accounts = await web3?.eth?.getAccounts();
  return accounts;
};

const getUserNonceForMetaMask = async (
  walletAddress: string,
  nonceString: string,
  nonceValue: number
) => {
  try {
    const web3 = await getWeb3();
    if (!web3) {
      return false;
    }

    const signature = await web3.eth.personal.sign(
      web3.utils.fromUtf8(nonceString),
      walletAddress,
      'One time nonce signature'
    );
    return { success: true, signature, nonce: nonceValue };
  } catch (err: any) {
    console.error(
      '~ file: config.tsx ~ line ~ getUserNonceForMetaMask ~ err',
      err
    );
    return { success: false, message: err.message };
  }
};

const loginWithMetaMask = async () => {
  try {
    const networkResponse = await checkForMetamaskNetwork();

    if (networkResponse?.code === 4001) {
      throw new Error(networkResponse?.message);
    }
    if (!networkResponse) {
      throw new Error(
        'Somethign went wrong while checking the metamask network.'
      );
    }

    let connectedAccounts = await getConnectedAccount();

    if (!connectedAccounts?.length) {
      connectedAccounts = await getAccounts();
    }

    return connectedAccounts[0];
  } catch (err) {
    console.error('~ file: config.tsx ~ line ~ loginWithMetaMask ~ err', err);
    throw err;
  }
};

export {
  getWeb3,
  isMetaMaskAvailable,
  checkForMetamaskNetwork,
  loginWithMetaMask,
  getUserNonceForMetaMask,
  getCustomRPCWeb3Instance,
  getAccounts,
  getChainId,
  getConnectedAccount,
};
