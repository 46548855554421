/* eslint-disable default-param-last */

import {
  ErrorSuccessResponseInterface,
  ActionInterface,
  DownloadFolderReducerInterface,
  FileInterface,
} from '../interfaces';
import * as types from '../actions';

interface FolderInitialStateInterface {
  createFolderResponse: ErrorSuccessResponseInterface;
  deleteFolderResponse: ErrorSuccessResponseInterface;
  deleteFolderLoading: boolean;
  downloadFolderResponse: DownloadFolderReducerInterface;
}

const initialState: FolderInitialStateInterface = {
  createFolderResponse: {
    data: {},
    message: '',
    success: false,
  },
  deleteFolderResponse: {
    data: {},
    message: '',
    success: false,
  },
  deleteFolderLoading: false,
  downloadFolderResponse: { files: [] as unknown as [FileInterface] },
};

const folderReducer = (state = initialState, action: ActionInterface) => {
  const { response } = action;

  switch (action.type) {
    case types.CREATE_FOLDER:
      return { ...state, response };
    case types.CREATE_FOLDER_RESPONSE:
      return { ...state, createFolderResponse: response };
    case types.DELETE_FOLDER_RESPONSE:
      return { ...state, deleteFolderResponse: response };
    case types.SET_FOLDER_LOADING:
      return { ...state, deleteFolderLoading: action.payload };
    case types.DOWNLOAD_FOLDER:
      return { ...state, downloadFolderResponse: { ...action.payload } };
    default:
      return state;
  }
};

export default folderReducer;
