import { COLLATERAL_CONTRACT_ADDRESS } from '../config';
import { getWeb3 } from '../web3';
import { getContract } from './commonContractFunctions';

const unStakeCollateral = async (nodeId: string) => {
  try {
    const web3 = await getWeb3();
    const accounts = await web3?.eth.getAccounts();

    const contract = await getContract(web3, COLLATERAL_CONTRACT_ADDRESS);

    if (!accounts?.length) {
      throw new Error('No account connected.');
    }
    const unStakeCollateralResponse = await contract.methods
      .unStakeTokens(nodeId)
      .send({ from: accounts[0] });

    return unStakeCollateralResponse;
  } catch (err) {
    console.error(
      'file: collateralContractFunctions.ts: ~ unStakeCollateral ~ err:',
      err
    );
    throw err;
  }
};

const pauseUnPauseContract = async (
  functionName: string,
  CONTRACT_INFO: any
) => {
  console.log('CONTRACT_INFO:', CONTRACT_INFO);
  try {
    const web3 = await getWeb3();

    const accounts = await web3?.eth.getAccounts();

    if (!accounts?.length) {
      throw new Error('No account connected.');
    }
    const contract = await getContract(web3, CONTRACT_INFO);

    const pauseUnpauseContractResponse = await contract.methods[
      functionName
    ]().send({
      from: accounts[0],
    });

    return pauseUnpauseContractResponse;
  } catch (err) {
    console.error(
      'file: collateralContractFunctions.ts ~ pauseUnPause ~ err:',
      err
    );
    throw err;
  }
};

const fillTreasury = async (fillAmount: number) => {
  try {
    const web3 = await getWeb3();

    const accounts = await web3?.eth.getAccounts();

    if (!accounts?.length) {
      throw new Error('No account connected.');
    }
    const contract = await getContract(web3, COLLATERAL_CONTRACT_ADDRESS);

    const fillAmountInWei = web3?.utils.toWei(fillAmount?.toString());

    const fillTreasuryResponse = await contract.methods.fillTreasury().send({
      value: fillAmountInWei,
      from: accounts[0],
    });

    return fillTreasuryResponse;
  } catch (err: any) {
    console.error(
      'file: collateralContractFunctions.ts ~ fillTreasury ~ err:',
      err
    );
    throw new Error(err.message);
  }
};

const checkContractStatus = async () => {
  try {
    const web3 = await getWeb3();

    const contract = await getContract(web3, COLLATERAL_CONTRACT_ADDRESS);

    const checkPauseUnPause = await contract.methods.paused.call();

    return checkPauseUnPause;
  } catch (err) {
    console.error(
      'file: collateralContractFunctions.ts ~ checkContractStatus ~ err:',
      err
    );
    throw err;
  }
};

const getCurrentBalanceOfContract = async () => {
  const web3 = await getWeb3();
  const accounts = await web3?.eth.getAccounts();

  if (!accounts?.length) {
    throw new Error('No account connected.');
  }

  const contract = await getContract(web3, COLLATERAL_CONTRACT_ADDRESS);

  const checkTreasuryBalanceResponse = await contract.methods
    .checkTreasuryBalance()
    .call({
      from: accounts[0],
    });

  const treasuryBalance = web3?.utils.fromWei(
    checkTreasuryBalanceResponse,
    'ether'
  );

  return treasuryBalance;
};

export {
  unStakeCollateral,
  pauseUnPauseContract,
  checkContractStatus,
  fillTreasury,
  getCurrentBalanceOfContract,
};
export default unStakeCollateral;
