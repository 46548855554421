import { toast } from 'react-toastify';
import { SUBSCRIPTION_CONTRACT_ADDRESS } from '../config';
import { getWeb3 } from '../web3';
import { getContract } from './commonContractFunctions';
import { PackageSubscriptionPayload } from '../../interfaces';
import {
  addSubscriptionPackage,
  updateSubscriptionPackage,
} from '../../services/paymentService';
import { getPurchasedSpaceAction } from '../../actions/paymentActions';

const purchasePackage = async (
  packageDetails: PackageSubscriptionPayload,
  access_token: string,
  dispatch: any
) => {
  console.log('packageDetails:', packageDetails);
  try {
    let recordedTransaction: any;
    const web3 = await getWeb3();
    const accounts = await web3?.eth.getAccounts();

    const contract = await getContract(web3, SUBSCRIPTION_CONTRACT_ADDRESS);

    if (!accounts?.length) {
      throw new Error('No account connected.');
    }

    const packagePriceInWei = web3?.utils.toWei(
      packageDetails?.amount,
      'ether'
    );
    console.log('packageDetails packagePriceInWei:', packagePriceInWei);

    await contract.methods
      .purchasePackage(
        packageDetails?.userId,
        packageDetails?.packageName,
        packageDetails?.expireDate,
        packageDetails?.spaceAllowed
      )
      .send({ from: accounts[0], value: packagePriceInWei })
      .on('transactionHash', async (txHash: string) => {
        console.log('.on ~ txHash:', txHash);
        // Save the details with transaction Hash
        recordedTransaction = await addSubscriptionPackage(
          { ...packageDetails, receiptUrl: txHash },
          access_token
        );
        if (!recordedTransaction?.status) {
          throw new Error('Unable to record the transactions');
        }

        toast.success(
          'Transaction of subscription recorded waiting for transaction confirmation on blockchain'
        );
      })
      .on('receipt', async (txnReceipt: any) => {
        console.log('.on ~ txnReceipt:', txnReceipt);
        toast.success(
          'Subscription Completed! We are verifying your transaction. This process may take several minutes. Once your transaction is verified, your package will be automatically subscribed, and you will be notified via email.'
        );

        console.log('recordedTransaction', recordedTransaction);
        const updateTransactionResponse = await updateSubscriptionPackage(
          {
            paymentStatus: 'APPROVED',
            status: 'active',
            subscriptionId: recordedTransaction?.data?._id,
          },
          access_token
        );

        if (updateTransactionResponse?.status) {
          dispatch(getPurchasedSpaceAction(access_token));
          toast.success('Package Subscribed');
        }
        // TODO: Update transaction on success
      });
  } catch (err: any) {
    console.error('err:', err);
    throw new Error(err?.message);
  }
};

export { purchasePackage };
export default purchasePackage;
