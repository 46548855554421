import { put, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import * as types from '../actions';
import {
  deleteNodeOnErrorService,
  editNodeDetailsService,
  getNetworkTotalStorage,
  getUserNodeRewardsService,
  getUserNodesService,
  removeNodeWithCollateralReturnService,
} from '../services/nodeService';
import {
  DeleteNodeSagaInterface,
  EditNodeMetadataSagaInterface,
  ErrorSuccessResponseInterface,
  PendingNodeRewardsSagaInterface,
  RemoveNodeWithCollateralReturnSagaInterface,
} from '../interfaces';

function* getUserNodesSaga(payload: { authToken: string }) {
  try {
    yield put({ type: types.USER_NODES_LOADER, response: true });

    const response: ErrorSuccessResponseInterface = yield call(
      getUserNodesService,
      payload?.authToken
    );

    yield put({ type: types.USER_NODES_RESPONSE, response });
  } catch (err) {
    console.error('file: paymentSaga.ts ~ err:', err);
    yield put({ type: types.USER_NODES_RESPONSE, err });
  } finally {
    yield put({ type: types.USER_NODES_LOADER, response: false });
  }
}

function* getUserNodeDetailsSaga() {
  try {
    yield console.log('Saga: User Node Details');
  } catch (err) {
    console.error(
      'file: nodeSaga.ts ~ function*getUserNodeDetails ~ err:',
      err
    );
  }
}

function* deleteNodeOnErrorSaga(payload: DeleteNodeSagaInterface) {
  try {
    const response: ErrorSuccessResponseInterface = yield call(
      deleteNodeOnErrorService,
      payload.payload
    );

    if (payload?.payload?.showMessage) {
      if (response?.success && payload.payload.fetchUserNodes) {
        payload?.payload?.fetchUserNodes();
        toast.success(response?.message);
      }
      if (!response?.success) {
        toast.error(response?.message);
      }
    }

    yield put({ type: types.DELETE_NODE_ON_ERROR_RESPONSE, response });
  } catch (err) {
    console.error(
      'file: nodeSaga.ts ~ function*deleteNodeOnErrorSaga ~ err:',
      err
    );
    yield put({ type: types.DELETE_NODE_ON_ERROR_RESPONSE, err });
  }
}

function* removeNodeWithCollateralReturnSaga(
  payload: RemoveNodeWithCollateralReturnSagaInterface
) {
  try {
    const response: ErrorSuccessResponseInterface = yield call(
      removeNodeWithCollateralReturnService,
      payload.payload
    );

    if (!response?.success) {
      toast.error(response?.message);
    }

    if (response?.success) {
      toast.success(response?.message);
      payload.payload.fetchUserNodes();
    }

    yield put({
      type: types.REMOVE_NODE_WITH_COLLATERAL_RETURN_RESPONSE,
      response,
    });
  } catch (err) {
    console.error(
      'file: nodeSaga.ts ~ function*removeNodeWithCollateralReturnSaga ~ err:',
      err
    );
    yield put({
      type: types.REMOVE_NODE_WITH_COLLATERAL_RETURN_RESPONSE,
      err,
    });
  } finally {
    payload?.payload?.setDeleteLoading(false);
    payload?.payload?.setShowDeleteModal(false);
  }
}

function* getPendingNodeRewardsSaga({
  payload,
}: PendingNodeRewardsSagaInterface) {
  console.log('file: nodeSaga.ts:36 ~ payload:', payload);
  try {
    yield put({ type: types.USER_REWARDS_LOADER, response: true });
    const response: ErrorSuccessResponseInterface = yield call(
      getUserNodeRewardsService,
      payload
    );

    yield put({ type: types.USER_NODE_REWARDS_RESPONSE, response });
  } catch (err) {
    console.error(
      'file: nodeSaga.ts:37 ~ function*getPendingNodeRewardsSaga ~ err:',
      err
    );

    yield put({ type: types.USER_NODE_REWARDS_RESPONSE, err });
  } finally {
    yield put({ type: types.USER_REWARDS_LOADER, response: false });
  }
}

function* getNetworkTotalStorageSaga() {
  try {
    yield put({ type: types.NETWORK_STORAGE_STATS_LOADER, response: true });

    const response: ErrorSuccessResponseInterface = yield call(
      getNetworkTotalStorage
    );
    yield put({ type: types.NETWORK_STORAGE_STATS_RESPONSE, response });
  } catch (err) {
    console.error(
      'file: nodeSaga.ts ~ function*getNetworkTotalStorageSaga ~ err:',
      err
    );
    yield put({ type: types.NETWORK_STORAGE_STATS_RESPONSE, response: false });
  } finally {
    yield put({ type: types.NETWORK_STORAGE_STATS_LOADER, response: false });
  }
}

function* editNodeDetailsSaga({ payload }: EditNodeMetadataSagaInterface) {
  try {
    yield put({ type: types.EDIT_NODE_DETAILS_LOADER, response: true });
    const response: ErrorSuccessResponseInterface = yield call(
      editNodeDetailsService,
      payload
    );

    if (response?.success) {
      toast.success(response?.message);
      yield put({ type: types.USER_NODES_LOADER, response: true });

      const updatedUserNodes: ErrorSuccessResponseInterface = yield call(
        getUserNodesService,
        payload?.authToken
      );

      yield put({
        type: types.USER_NODES_RESPONSE,
        response: updatedUserNodes,
      });
    }

    yield put({ type: types.EDIT_NODE_DETAILS_RESPONSE, response });
  } catch (err: any) {
    console.error(
      'file: nodeSaga.ts:159 ~ function*editNodeDetails ~ err:',
      err?.response?.data?.message || err?.message
    );
    toast.error(err.message);
    yield put({ type: types.EDIT_NODE_DETAILS_RESPONSE, response: false });
  } finally {
    yield put({ type: types.EDIT_NODE_DETAILS_LOADER, response: false });
    yield put({ type: types.USER_NODES_LOADER, response: false });
  }
}
export {
  getUserNodesSaga,
  getUserNodeDetailsSaga,
  deleteNodeOnErrorSaga,
  removeNodeWithCollateralReturnSaga,
  getPendingNodeRewardsSaga,
  getNetworkTotalStorageSaga,
  editNodeDetailsSaga,
};
