/* eslint-disable default-param-last */

import {
  ErrorSuccessResponseInterface,
  ActionInterface,
  NodeIPDetailsInterface,
} from '../interfaces';
import * as types from '../actions';

interface NodeReducerInitialStateInterface {
  userNodes: ErrorSuccessResponseInterface;
  deleteNodeOnErrorResponse: ErrorSuccessResponseInterface;
  removeNodeWithCollateralReturnResponse: ErrorSuccessResponseInterface;
  userNodeRewardsResponse: ErrorSuccessResponseInterface;
  rewardLoader: Boolean;
  nodesLoader: Boolean;
  networkStorageStatsLoader: Boolean;
  networkStorageStatsResponse: ErrorSuccessResponseInterface;
  adminMapNodesResponse: NodeIPDetailsInterface[];
  editNodeDetailsResponse: ErrorSuccessResponseInterface;

  editNodeDetailsLoader: Boolean;
}

const defaultResponse = {
  data: {},
  message: '',
  success: false,
};

const initialState: NodeReducerInitialStateInterface = {
  rewardLoader: false,
  userNodes: defaultResponse,
  deleteNodeOnErrorResponse: defaultResponse,
  removeNodeWithCollateralReturnResponse: defaultResponse,
  userNodeRewardsResponse: defaultResponse,
  nodesLoader: false,
  networkStorageStatsResponse: defaultResponse,
  networkStorageStatsLoader: false,
  adminMapNodesResponse: [],
  editNodeDetailsResponse: defaultResponse,
  editNodeDetailsLoader: false,
};

const nodeReducer = (state = initialState, action: ActionInterface) => {
  const { response } = action;

  switch (action.type) {
    case types.USER_REWARDS_LOADER:
      return { ...state, rewardLoader: response };

    case types.USER_NODES_LOADER:
      return { ...state, nodesLoader: response };

    case types.USER_NODES:
      return { ...state, response };
    case types.USER_NODES_RESPONSE:
      return { ...state, userNodes: response };

    case types.DELETE_NODE_ON_ERROR:
      return { ...state, response };
    case types.DELETE_NODE_ON_ERROR_RESPONSE:
      return { ...state };

    case types.REMOVE_NODE_WITH_COLLATERAL_RETURN:
      return { ...state, response };
    case types.REMOVE_NODE_WITH_COLLATERAL_RETURN_RESPONSE:
      return { ...state, removeNodeWithCollateralReturnResponse: response };
    case types.USER_NODE_REWARDS:
      return { ...state, response };
    case types.USER_NODE_REWARDS_RESPONSE:
      return { ...state, userNodeRewardsResponse: response };

    case types.NETWORK_STORAGE_STATS_LOADER:
      return { ...state, networkStorageStatsLoader: response };

    case types.NETWORK_STORAGE_STATS:
      return { ...state, response };
    case types.NETWORK_STORAGE_STATS_RESPONSE:
      return { ...state, networkStorageStatsResponse: response };

    case types.ADMIN_MAP_NODES:
      return { ...state, adminMapNodesResponse: action?.payload };
    case types.EDIT_NODE_DETAILS:
      return { ...state, response };
    case types.EDIT_NODE_DETAILS_RESPONSE:
      return { ...state, editNodeDetailsResponse: response };
    case types.EDIT_NODE_DETAILS_LOADER:
      return { ...state, editNodeDetailsLoader: response };

    default:
      return state;
  }
};

export default nodeReducer;
