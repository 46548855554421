import * as types from '../actions';

import { ErrorSuccessResponseInterface, ActionInterface } from '../interfaces';

const defaultResponseValue = {
  data: {},
  success: false,
  message: '',
};

interface PaymentInitialStateInterface {
  loader: boolean;
  createPaymentResponse: ErrorSuccessResponseInterface;
  updatePaymentStatusResponse: ErrorSuccessResponseInterface;
  cancelSubscriptionResponse: ErrorSuccessResponseInterface;
  purchaseSpaceResponse: ErrorSuccessResponseInterface;
  cancelUserSubscriptionCheckoutResponse: ErrorSuccessResponseInterface;
}

const initialState: PaymentInitialStateInterface = {
  loader: false,
  createPaymentResponse: defaultResponseValue,
  updatePaymentStatusResponse: defaultResponseValue,
  cancelSubscriptionResponse: defaultResponseValue,
  purchaseSpaceResponse: defaultResponseValue,
  cancelUserSubscriptionCheckoutResponse: defaultResponseValue,
};

const paymentReducer = (
  state = initialState,
  action: ActionInterface = { type: '', response: {} }
) => {
  const { response } = action;

  switch (action.type) {
    case types.PAYMENT_LOADER:
      return { ...state, loader: response };
    case types.CREATE_PAYMENT:
      return { ...state, response };
    case types.CREATE_PAYMENT_RESPONSE:
      return { ...state, createPaymentResponse: response };

    case types.UPDATE_PAYMENT_STATUS:
      return { ...state, response };

    case types.UPDATE_PAYMENT_STATUS_RESPONSE:
      return { ...state, updatePaymentStatusResponse: response };

    case types.CANCEL_SUBSCRIPTION:
      return { ...state, response };
    case types.CANCEL_SUBSCRIPTION_RESPONSE:
      return { ...state, cancelSubscriptionResponse: response };

    case types.PURCHASED_SPACE:
      return { ...state, response };
    case types.PURCHASED_SPACE_RESPONSE:
      return { ...state, purchaseSpaceResponse: response };

    case types.CANCEL_CHECKOUT_SESSION:
      return { ...state, response };
    case types.CANCEL_CHECKOUT_SESSION_RESPONSE:
      return { ...state, cancelUserSubscriptionCheckoutResponse: response };
    default:
      return state;
  }
};

export default paymentReducer;
