import { put, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import * as types from '../actions';
import {
  createFolderService,
  removeFolderService,
} from '../services/folderService';
import {
  ErrorSuccessResponseInterface,
  FolderInterface,
  removeFolderInterface,
} from '../interfaces';
import { deleteFolderLoadingAction } from '../actions/folderActions';

interface FolderCreateSagaActionInterface {
  type: string;
  payload: FolderInterface;
}

interface FolderRemoveSagaActionInterface {
  type: string;
  payload: removeFolderInterface;
}

export function* createFolderSaga(payload: FolderCreateSagaActionInterface) {
  try {
    const response: ErrorSuccessResponseInterface = yield call(
      createFolderService,
      payload.payload
    );

    if (response?.success && response?.message) {
      toast.success(response?.message);
      yield put({ type: types.CREATE_FOLDER_RESPONSE, response });
    }
    if (!response?.data?.success && response?.data?.message) {
      toast.error(response?.data?.message);
      yield put({ type: types.CREATE_FOLDER_RESPONSE, response });
    }
  } catch (err) {
    yield put({ type: types.CREATE_FOLDER_RESPONSE, err });
  }
}

export function* removeFolderSaga(payload: FolderRemoveSagaActionInterface) {
  try {
    yield put(deleteFolderLoadingAction(true));
    const response: ErrorSuccessResponseInterface = yield call(
      removeFolderService,
      payload.payload
    );
    if (response?.success && response.message) {
      toast.success(response?.message);
    }
    if (!response?.success && response.message) {
      toast.error(response?.message);
    }
    yield put(deleteFolderLoadingAction(false));
    yield put({ type: types.DELETE_FOLDER_RESPONSE, response });
  } catch (err) {
    yield put(deleteFolderLoadingAction(false));
    yield put({ type: types.DELETE_FOLDER_RESPONSE, err });
  }
}

export function* resetFolderSagaState() {
  try {
    yield put({ type: types.CREATE_FOLDER_RESPONSE, response: {} });
  } catch (err) {
    yield put({ type: types.CREATE_FOLDER_RESPONSE, err });
  }
}
