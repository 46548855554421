import axios from 'axios';

interface AxiosOptionInterface {
  method: string;
  url: string;
  data?: any;
  params?: any;
}

const apiRequestHandler = async (
  axiosOptions: AxiosOptionInterface,
  authToken = ''
) => {
  try {
    const response = await axios({
      ...axiosOptions,
      ...(authToken && {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }),
    });

    return response?.data;
  } catch (err) {
    console.error('file: axiosHelper.ts ~ apiRequestHandler ~ err:', err);
    throw err;
  }
};

export default apiRequestHandler;
