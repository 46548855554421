import {
  CREATE_FOLDER,
  DELETE_FOLDER,
  DOWNLOAD_FOLDER,
  SET_FOLDER_LOADING,
} from './index';
import {
  DownloadFolderReducerInterface,
  FolderInterface,
  removeFolderInterface,
} from '../interfaces';

export const createFolderAction = (payload: FolderInterface) => ({
  type: CREATE_FOLDER,
  payload,
});

export const removeFolderAction = (payload: removeFolderInterface) => ({
  type: DELETE_FOLDER,
  payload,
});
export const deleteFolderLoadingAction = (deleteFolderLoading: boolean) => ({
  type: SET_FOLDER_LOADING,
  payload: deleteFolderLoading,
});

export const resetCreateFolderAction = () => ({
  type: CREATE_FOLDER,
});

export const downloadFolderUpdateAction = (
  downloadFolderResponse: DownloadFolderReducerInterface
) => ({
  type: DOWNLOAD_FOLDER,
  payload: downloadFolderResponse,
});
