import { combineReducers } from 'redux';
import registerReducer from './registerReducer';
import loginReducer from './loginReducer';
import authReducer from './authReducer';
import forgotPasswordReducer from './forgotPasswordReducer';
import resetPasswordReducer from './resetPasswordReducer';
import verifyEmailReducer from './verifyEmailReducer';
import addBucketReducer from './buckets/addBucketReducer';
import getBucketsByUserIdReducer from './buckets/getBucketsByUserReducer';
import toastReducer from './toastReducer';
import fileReducer from './fileReducer';
import deleteBucketReducer from './buckets/deleteBucketReducer';
import userReducer from './userReducer';
import folderReducer from './folder.reducer';
import paymentReducer from './paymentReducer';
import queueReducer from './queueReducer';
import nodeReducer from './nodeReducer';

const rootReducer = combineReducers({
  registerReducer,
  loginReducer,
  authReducer,
  forgotPasswordReducer,
  resetPasswordReducer,
  verifyEmailReducer,
  addBucketReducer,
  getBucketsByUserIdReducer,
  fileReducer,
  toastReducer,
  deleteBucketReducer,
  userReducer,
  folderReducer,
  paymentReducer,
  queueReducer,
  nodeReducer,
});

export default rootReducer;
