/* eslint-disable import/prefer-default-export */

import {
  CREATE_USER_KEY,
  GET_USER_NONCE,
  VERIFY_PASS_PHRASE,
  EMAIL_VERIFICATION,
  GET_USER_USED_SPACE,
  SIGN_UP_INVITED_USER,
  USER_ORGANIZATIONS,
  ORGANIZATION_USER,
  REMOVE_ORGANIZATION_USER,
  ADD_USER_TO_ORGANIZATION,
  GER_USERS_LIST_EMAIL_BY_USER,
  EDIT_USER,
  UPDATE_PASSWORD,
  RESET_UPDATE_PASSWORD_STATE,
  SET_PROFILE_UPDATE_LOADING,
  RESET_VERIFY_EMAIL,
  RESET_USER_NONCE_RESPONSE,
  USER_SIGN_THE_NONCE,
} from './index';
import {
  GetUserNoncePayload,
  UserPassPhrasePayload,
  EmailVerificationPayload,
  GetUserUsedSpace,
  SignUpInvitedUserInterface,
  EditUserPayload,
  UpdatePasswordPayload,
} from '../interfaces';

export const verifyUserPassPhraseAction = (payload: UserPassPhrasePayload) => ({
  type: VERIFY_PASS_PHRASE,
  payload,
});

export const createUserKeyAction = (payload: UserPassPhrasePayload) => ({
  type: CREATE_USER_KEY,
  payload,
});

export const getUserNonceAction = (payload: GetUserNoncePayload) => ({
  type: GET_USER_NONCE,
  payload,
});

export const userSignTheNoneAction = (payload: string) => ({
  type: USER_SIGN_THE_NONCE,
  payload,
});

export const getUsersListByEmail = (payload: GetUserNoncePayload) => ({
  type: GER_USERS_LIST_EMAIL_BY_USER,
  payload,
});

export const emailCompleteVerificationAction = (
  payload: EmailVerificationPayload
) => ({ type: EMAIL_VERIFICATION, payload });

export const getUserUsedSpace = (payload: GetUserUsedSpace) => ({
  type: GET_USER_USED_SPACE,
  payload,
});
export const signUpInvitedUserAction = (
  payload: SignUpInvitedUserInterface
) => ({ type: SIGN_UP_INVITED_USER, payload });

export const userOrganizationsAction = (authToken: string, page: number) => ({
  type: USER_ORGANIZATIONS,
  payload: { authToken, page },
});

export const organizationUsersAction = (authToken: string, page: number) => ({
  type: ORGANIZATION_USER,
  payload: { authToken, page },
});

export const removeOrganizationUserAction = (payload: {
  authToken: string;
  userId: string;
}) => ({ type: REMOVE_ORGANIZATION_USER, payload });

export const addUserToOrganizationAction = (payload: {
  authToken: string;
  email: string;
  userType: string;
}) => ({ type: ADD_USER_TO_ORGANIZATION, payload });

export const editUserAction = (
  payload: EditUserPayload,
  setUserDetails: any
) => ({
  type: EDIT_USER,
  payload: { ...payload, setUserDetails },
});

export const updatePasswordAction = (
  payload: UpdatePasswordPayload,
  navigate: any
) => ({
  type: UPDATE_PASSWORD,
  payload: { ...payload, navigate },
});

export const resetState = () => ({
  type: RESET_UPDATE_PASSWORD_STATE,
});

export const updateProfileLoadingAction = (updateProfileLoading: boolean) => ({
  type: SET_PROFILE_UPDATE_LOADING,
  payload: updateProfileLoading,
});

export const resetVerifyEmailAction = (verifyEmailResponse: any) => ({
  type: RESET_VERIFY_EMAIL,
  payload: verifyEmailResponse,
});
export const resetUserNonceAction = (userNonceResponse: any) => ({
  type: RESET_USER_NONCE_RESPONSE,
  payload: userNonceResponse,
});
