import { getWeb3 } from '../web3';

export const initContract = (
  web3: any,
  contractABI: any,
  contractAddress: string | undefined
) => {
  const contract = new web3.eth.Contract(contractABI, contractAddress);
  return contract;
};

export const getContract = async (web3: any, CONTRACT_DETAILS_ARRAY: any) => {
  const networkChainId = await web3.eth.getChainId();

  const contractDetails =
    CONTRACT_DETAILS_ARRAY[
      networkChainId as keyof typeof CONTRACT_DETAILS_ARRAY
    ];

  console.log('getContract ~ contractDetails:', contractDetails);
  const contract = initContract(
    web3,
    contractDetails?.contractABI,
    contractDetails?.address
  );
  return contract;
};

export const readOrWriteFunction = async (
  method: string,
  CONTRACT_DETAILS_ARRAY: any,
  value: boolean | undefined,
  callOrSend: 'call' | 'send',
  ...args: any[]
) => {
  try {
    console.log(method, args);
    const web3 = await getWeb3();
    const connectedAccounts = await web3?.eth.getAccounts();

    if (!connectedAccounts?.length) {
      throw new Error('No connected account.');
    }

    let msgValue: any;
    let inputs = args;

    if (value) {
      const [mValue, ...mInputs] = args;
      msgValue = mValue;
      inputs = mInputs;
    }

    const contract = await getContract(web3, CONTRACT_DETAILS_ARRAY);

    const methodResponse = await contract.methods[method](...inputs)[
      callOrSend
    ]({
      from: connectedAccounts[0],
      ...(msgValue && { value: msgValue }),
    });
    console.log('methodResponse:', methodResponse);

    return methodResponse;
  } catch (err: any) {
    console.error('writeFunction ~ err:', err);
    throw new Error(err.message);
  }
};

// export const ReadFunction = async (
//   method: string,
//   CONTRACT_DETAILS_ARRAY: any,
//   value: boolean | undefined,
//   ...args: any[]
// ) => {
//   try {
//     console.log(method, args);
//     const web3 = await getWeb3();
//     const connectedAccounts = await web3?.eth.getAccounts();

//     if (!connectedAccounts?.length) {
//       throw new Error('No connected account.');
//     }

//     let msgValue: any;
//     let inputs = args;

//     if (value) {
//       const [mValue, ...mInputs] = args;
//       msgValue = mValue;
//       inputs = mInputs;
//     }

//     const contract = await getContract(web3, CONTRACT_DETAILS_ARRAY);

//     const methodResponse = await contract.methods[method](...inputs).send({
//       from: connectedAccounts[0],
//       ...(msgValue && { value: msgValue }),
//     });
//     console.log('methodResponse:', methodResponse);

//     return methodResponse;
//   } catch (err) {
//     console.error('writeFunction ~ err:', err);
//     return err;
//   }
// };

// Future Use

// export const getTotalSupply = async () => {
//   try {
//     const web3: any = await getWeb3();

//     const contract = await getContract(web3);

//     const totalSupplyContractResponse = await contract.methods
//       .totalSupply()
//       .call();

//     const ethConvertedValue = web3.utils.fromWei(
//       totalSupplyContractResponse,
//       'ether'
//     );

//     return ethConvertedValue;
//   } catch (err) {
//     console.error(
//       'file: storageChainContract.js:21 ~ getTotalSupply ~ err:',
//       err
//     );
//     return err;
//   }
// };
