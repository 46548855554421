import { fork } from 'redux-saga/effects';
import {
  watchFileSaga,
  watchUserAuthentication,
  watchUserSaga,
  watchFolderSaga,
  watchPaymentSaga,
  watchNodeSaga,
} from './watchers';

export default function* startForman() {
  yield fork(watchUserAuthentication);
  yield fork(watchFileSaga);
  yield fork(watchUserSaga);
  yield fork(watchFolderSaga);
  yield fork(watchPaymentSaga);
  yield fork(watchNodeSaga);
}
