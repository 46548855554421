import * as types from '../actions';
import { ActionInterface } from '../interfaces';

// eslint-disable-next-line default-param-last
const loginReducer = (state = [], action: ActionInterface) => {
  const { response } = action;

  switch (action.type) {
    case types.LOGOUT_USER:
      return { response: undefined };
    case types.LOGIN_USER_SUCCESS:
      return { ...state, response };
    case types.LOGIN_USER_ERROR:
      return { ...state, response };
    default:
      return state;
  }
};
export default loginReducer;
