import axios from 'axios';
import {
  DownloadFolderInterface,
  FolderDetailsInterface,
  FolderInterface,
  removeFolderInterface,
} from '../interfaces';

export const createFolderService = async (payload: FolderInterface) => {
  if (!payload?.authToken) {
    return { success: false, message: '', data: {} };
  }

  const response = await axios({
    method: 'post',
    url: `${process.env.REACT_APP_API_BASE_URL}folder/add`,
    data: payload,
    headers: {
      Authorization: `Bearer ${payload?.authToken}`,
    },
  });

  return response?.data;
};

export const removeFolderService = async (payload: removeFolderInterface) => {
  try {
    if (!payload?.authToken) {
      return { success: false, message: '', data: {} };
    }
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}folder/remove/${payload?._id}`,
      headers: {
        Authorization: `Bearer ${payload?.authToken}`,
      },
    });
    return response?.data;
  } catch (err: any) {
    return err.response || err.message;
  }
};

export const downloadFolderService = async (
  payload: DownloadFolderInterface
) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_URL}folder/download`,
      params: payload,
    });
    return response?.data;
  } catch (err: any) {
    console.error('file: folderService.ts:44 ~ downloadFolder ~ err:', err);
    return err.response || err.message;
  }
};

export const getFolderDetails = async (payload: FolderDetailsInterface) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_URL}folder/details`,
      params: { ...payload, authToken: undefined },
      headers: {
        Authorization: `Bearer ${payload?.authToken}`,
      },
    });
    return response?.data;
  } catch (err: any) {
    console.error('file: folderService.ts:44 ~ downloadFolder ~ err:', err);
    return err.response || err.message;
    // throw err;
  }
};
