/* eslint-disable import/prefer-default-export */

import axios from 'axios';
import {
  GetUserNoncePayload,
  UserPassPhrasePayload,
  EmailVerificationPayload,
  SignUpInvitedUserInterface,
  GetUserEmailListPayload,
  EditUserPayload,
  UpdatePasswordPayload,
  UsersListQueryParamsInterface,
  UserUpdateDetailsByAdminInterface,
} from '../interfaces';
import { LIMIT } from '../utils/constant';
export const verifyUserPassPhraseService = async (
  payload: UserPassPhrasePayload
) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_URL}auth/verify-user-pass-phrase`,
      params: {
        userKey: payload?.userKey,
        organizationId: payload?.organizationId,
      },
      headers: {
        Authorization: `Bearer ${payload?.authToken}`,
      },
    });
    return response?.data;
  } catch (err: any) {
    return err?.response?.data;
  }
};

export const createUserKeyService = async (payload: UserPassPhrasePayload) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}auth/create-user-key`,
      data: {
        userKey: payload?.userKey,
      },
      headers: {
        Authorization: `Bearer ${payload?.authToken}`,
      },
    });
    return response?.data;
  } catch (err: any) {
    return err?.response?.data;
  }
};

export const getUsersListByEmail = async (payload: GetUserEmailListPayload) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_URL}auth/list/${payload.email}`,
      headers: {
        Authorization: `Bearer ${payload?.authToken}`,
      },
    });
    return response?.data.data;
  } catch (err: any) {
    return err?.response?.data;
  }
};

export const getUserNonceService = async (payload: GetUserNoncePayload) => {
  try {
    const data = {
      walletAddress: payload?.walletAddress,
      chainType: payload?.chainType,
    };
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}auth/nonce`,
      data,
      headers: {
        Authorization: `Bearer ${payload?.authToken}`,
      },
    });
    return response?.data;
  } catch (err: any) {
    console.error('file: userService.ts ~ getUserNonceService ~ err:', err);
    return err?.response?.data;
  }
};

export const getUserUsedSpaceService = async (authToken: string) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_URL}file/user/space`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    return response?.data;
  } catch (err: any) {
    console.error('file: userService.ts ~ getUserUsedSpaceService ~ err:', err);
    return err?.response?.data;
  }
};

export const emailVerificationService = async (
  payload: EmailVerificationPayload
) => {
  try {
    if (!payload?.id || !payload?.token) {
      return { data: {}, success: false, message: '' };
    }
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_URL}auth/link/verify/${payload?.id}/${payload?.token}`,
    });
    return response?.data;
  } catch (err: any) {
    console.error(' file: userService.ts ~ err:', err);
    return err?.response?.data;
  }
};

export const signUpInvitedUserService = async (
  payload: SignUpInvitedUserInterface
) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}auth/sign-up-employee`,
      data: payload,
    });
    return response?.data;
  } catch (err: any) {
    console.error(' file: userService.ts ~ err:', err);
    return err?.response?.data;
  }
};

export const userOrganizationsService = async (payload: {
  authToken: string;
  page: number;
}) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_URL}auth/user-organizations`,
      params: {
        page: payload.page,
        limit: LIMIT,
      },
      headers: {
        Authorization: `Bearer ${payload?.authToken}`,
      },
    });
    return response.data;
  } catch (err: any) {
    console.error(
      'file: userService.ts ~ userOrganizationsService ~ err:',
      err
    );
    return err?.response?.data;
  }
};

export const organizationUserService = async (payload: {
  authToken: string;
  page: number;
}) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_URL}auth/organization-users`,
      params: {
        page: payload.page,
        limit: LIMIT,
      },
      headers: {
        Authorization: `Bearer ${payload.authToken}`,
      },
    });
    return response.data;
  } catch (err: any) {
    console.error(
      'file: userService.ts ~ userOrganizationsService ~ err:',
      err
    );
    return err?.response?.data;
  }
};

export const removeOrganizationUserService = async (payload: {
  authToken: string;
  userId: string;
}) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}auth/remove-from-organization/${payload.userId}`,
      headers: {
        Authorization: `Bearer ${payload.authToken}`,
      },
    });
    return response.data;
  } catch (err: any) {
    console.error(
      'file: userService.ts ~ userOrganizationsService ~ err:',
      err
    );
    return err?.response?.data;
  }
};

export const addUserToOrganizationService = async (payload: {
  authToken: string;
  email: string;
  userType: string;
}) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}auth/create-employee`,
      data: {
        email: payload.email,
        userType: payload?.userType,
      },
      headers: {
        Authorization: `Bearer ${payload.authToken}`,
      },
    });
    return response.data;
  } catch (err: any) {
    console.error(
      'file: userService.ts ~ userOrganizationsService ~ err:',
      err
    );
    return err?.response?.data;
  }
};

export const editUserService = async (payload: EditUserPayload) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}auth/editUser`,
      data: payload,
      headers: {
        Authorization: `Bearer ${payload?.authToken}`,
      },
    });
    return response?.data;
  } catch (err: any) {
    return err?.response?.data;
  }
};

export const updatePasswordService = async (payload: UpdatePasswordPayload) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}auth/update-password`,
      data: payload,
      headers: {
        Authorization: `Bearer ${payload?.authToken}`,
      },
    });
    return response?.data;
  } catch (err: any) {
    return err?.response?.data;
  }
};

export const getUserDetailsForAdminService = async (
  userId: string,
  authToken: string
) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_URL}auth/admin/user-details/${userId}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    return response?.data;
  } catch (err: any) {
    return err?.response?.data;
  }
};

export const getUsersListForAdminService = async (
  queryParams: UsersListQueryParamsInterface
) => {
  try {
    const { authToken, ...params } = queryParams;
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_URL}auth/admin/user-list`,
      params,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    return response?.data;
  } catch (err) {
    console.error('err:', err);
    throw err;
  }
};

export const userUpdateDetailsByAdmin = async (
  payload: UserUpdateDetailsByAdminInterface
) => {
  try {
    const { authToken, ...body } = payload;
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}auth/admin/update-user`,
      data: body,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    return response?.data;
  } catch (err) {
    console.error('userUpdateDetailsByAdmin ~ err:', err);
    return err;
  }
};
