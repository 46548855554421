/* eslint-disable prettier/prettier */
import { PackageInterface } from '../interfaces';
import SVG from '../assets/images/Images';
// import { getCurrentPriceForWSTORCoinOnlyValue } from './helpers/helper';

export const FILE_CHUNK_SIZE = 1024 * 1024 * 2; // * 20;
export const LIMIT = 50;

export const TOTAL_DEFAULT_SPACE = 161061273600;

export const generatePackages = async (
  coinPrice: number
): Promise<PackageInterface> => {
  try {
    const basicPlanCharges = 5 / (coinPrice || 0);
    console.log('basicPlanCharges:', basicPlanCharges);

    const standardPackageeCharges = 8 / (coinPrice || 0);
    console.log('standardPackageeCharges:', standardPackageeCharges);

    const extendedPackageCharges = 0.0045 / (coinPrice || 0);
    console.log('extendedPackageCharges:', extendedPackageCharges);

    return {
      USD: [
        {
          packageID: process.env.REACT_APP_2_TB_PLAN || '',
          packagePrice: 5.0,
          allowedSpace: 3,
          allowedSpaceIn: 'TB',
          planTitle: '3TB Plan',
          planCharges: '$5.00/month',
          billingType: 'Billed annually',
          metadata: [
            {
              checked: true,
              description: '$5.00 (up to 3TB)',
            },
            {
              checked: true,
              description: '$0.0045 per GB (Over 3TB)',
            },
          ],
        },
        {
          packageID: process.env.REACT_APP_5_TB_PLAN || '',
          packagePrice: 8.0,
          allowedSpace: 5,
          allowedSpaceIn: 'TB',
          planTitle: '5TB Plan',
          planCharges: '$8.00/month',
          billingType: 'Billed annually',
          metadata: [
            {
              checked: true,
              description: '$8.00 (up to 5TB)',
            },
            {
              checked: true,
              description: '$0.0045 per GB (Over 5TB)',
            },
          ],
        },
      ],
      STOR: [
        {
          packageID: 'StorageChain',
          packagePrice: Number((basicPlanCharges * 12)?.toFixed(2)),
          allowedSpace: 3,
          allowedSpaceIn: 'TB',
          planTitle: '3TB Plan',
          planCharges: `STOR ${Number(basicPlanCharges?.toFixed(2))}/month`,
          billingType: 'Billed annually',
          metadata: [
            {
              checked: true,
              description: `STOR ${Number(
                basicPlanCharges?.toFixed(2)
              )} (up to 3TB)`,
            },

            {
              checked: true,
              description: `STOR ${Number(
                extendedPackageCharges?.toFixed(2)
              )} per GB (Over 3TB)`,
            },
          ],
        },
        {
          packageID: 'StorageChain',
          packagePrice: Number((standardPackageeCharges * 12)?.toFixed(2)),
          allowedSpace: 5,
          allowedSpaceIn: 'TB',
          planTitle: '5TB Plan',
          planCharges: `STOR ${Number(
            standardPackageeCharges?.toFixed(2)
          )}/month`,
          billingType: 'Billed annually',
          metadata: [
            {
              checked: true,
              description: `STOR ${Number(
                standardPackageeCharges?.toFixed(2)
              )} (up to 5TB)`,
            },
            {
              checked: true,
              description: `STOR ${Number(
                extendedPackageCharges?.toFixed(2)
              )} per GB (Over 5TB)`,
            },
          ],
        },
      ],
    };
  } catch (err) {
    console.error('file: constant.ts ~ geneeratePackages ~ err:', err);
    return {
      USD: [
        {
          packageID: process.env.REACT_APP_2_TB_PLAN || '',
          packagePrice: 5.0,
          allowedSpace: 3,
          allowedSpaceIn: 'TB',
          planTitle: '3TB Plan',
          planCharges: '$5.00/month',
          billingType: 'Billed annually',
          metadata: [
            {
              checked: true,
              description: '$5.00 (up to 3TB)',
            },
            {
              checked: true,
              description: '$0.0045 per GB (Over 3TB)',
            },
          ],
        },
        {
          packageID: process.env.REACT_APP_5_TB_PLAN || '',
          packagePrice: 8.0,
          allowedSpace: 5,
          allowedSpaceIn: 'TB',
          planTitle: '5TB Plan',
          planCharges: '$8.00/month',
          billingType: 'Billed annually',
          metadata: [
            {
              checked: true,
              description: '$8.00 (up to 5TB)',
            },
            {
              checked: true,
              description: '$0.0045 per GB (Over 5TB)',
            },
          ],
        },
      ],
      STOR: [
        {
          packageID: 'StorageChain',
          packagePrice: 5,
          allowedSpace: 3,
          allowedSpaceIn: 'TB',
          planTitle: '3TB Plan',
          planCharges: 'STOR 5.00/month',
          billingType: 'Billed annually',
          metadata: [
            {
              checked: true,
              description: '150GB free storage',
            },
            {
              checked: true,
              description: 'STOR 2.50 (up to 3TB)',
            },
            {
              checked: false,
              description: 'STOR 3.75 (up to 5TB)',
            },
            {
              checked: true,
              description: 'STOR 0.0045 per GB (up to 50TB)',
            },
          ],
        },
        {
          packageID: 'StorageChain',
          packagePrice: 8,
          allowedSpace: 5,
          allowedSpaceIn: 'TB',
          planTitle: '5TB Plan',
          planCharges: 'STOR 8.00/month',
          billingType: 'Billed annually',
          metadata: [
            {
              checked: true,
              description: '150GB free storage',
            },
            {
              checked: true,
              description: 'STOR 2.50 (up to 2TB)',
            },
            {
              checked: true,
              description: 'STOR 3.75 (up to 5TB)',
            },
            {
              checked: true,
              description: 'STOR 0.0045 per GB (up to 50TB)',
            },
          ],
        },
      ],
    };
  }
};

export const SUPPORTED_MINT_FILE_TYPE = {
  'image/png': 'Image',
  'image/jpeg': 'Image',
  'image/gif': 'Image',
  'audio/webm': 'Audio',
  'video/webm': 'Video',
  'video/mp4': 'Image',
  'audio/mpeg': 'Audio',
  'audio/wav': 'Audio',
};
export const SERVER_ICONS = {
  offline: SVG.Server0,
  success: SVG.Server2,
  processing: SVG.Server1,
  lowUpTime: SVG.LowUpTime,
};

export const COLLETRAL_PRICE_PER_GB = parseInt(
  process.env.REACT_APP_COLLETRAL_PRICE_PER_GB || '50'
);

export const VALUES_PER_UNITS = {
  GB: 1,
  TB: 1000,
  PB: 1000000,
};
export const REWARD_STATUS_STRING = {
  unpaid: 'Pending',
  paid: 'Paid',
  rejected: 'Unpaid, Noncompliant',
  upcoming: 'Accrued',
};
export const INCORRECT_WALLET_CONNECTION_ERROR =
  'Incorrect Wallet Detected! We have detected that you are currently using an incorrect wallet. Please switch back to {wallet_address} to continue using this account.';

export const WALLET_CONNECTION_ERROR_MESSAGE =
  'Wallet not connected! Please connect your wallet to continue.';

export const MASTER_NODE_PEER_UI_URL = `http://46.101.133.110:8080/ipfs/${process.env.REACT_APP_IPFS_WEB_UI_HASH}/#/peers`;

export const SPECIAL_SPLITTER = '(--||$||--)';

export default {};
