import axios from 'axios';
import { toast } from 'react-toastify';
import {
  DeleteNodeInterface,
  EditNodeMetadataInterface,
  ErrorSuccessResponseInterface,
  ListOfNodesForAdminInterface,
  MonthlyNodesRewardsParamsInterface,
  NodeGraphQueryParamsInterface,
  RemoveNodeWithCollateralReturnInterface,
  UserNodeRewardsInterface,
} from '../interfaces';

export const getUserNodesService = async (
  authToken: string
): Promise<ErrorSuccessResponseInterface> => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_URL}node/user-nodes`,

      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    const actualResponse = response.data;
    return actualResponse;
  } catch (err: any) {
    console.error('file: fileService.ts ~ err:', err);
    return err?.response?.data?.message || err.message;
  }
};
export const getUserNodeStatus = async (
  ipAddress: string,
  authToken: string,
  timeout?: undefined | number
): Promise<any> => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_URL}file/node/status`,
      params: {
        ipAddress,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      timeout,
    });
    const actualResponse = response.data;
    return actualResponse;
  } catch (err: any) {
    console.error('file: fileService.ts ~ err:', err);
    return { isClusterOnline: false };
  }
};

export const getUserNodeDetailsService = async (
  authToken: string
): Promise<ErrorSuccessResponseInterface> => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_URL}node/user-nodes`,

      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    const actualResponse = response.data;
    return actualResponse;
  } catch (err: any) {
    console.error('file: nodeService.ts ~ err:', err);

    return err?.response?.data?.message || err.message;
  }
};

export const deleteNodeOnErrorService = async (
  deleteNodeInformation: DeleteNodeInterface
) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}node/delete-node-on-error`,
      data: {
        nodeId: deleteNodeInformation?.nodeId,
      },
      headers: {
        Authorization: `Bearer ${deleteNodeInformation?.authToken}`,
      },
    });
    const actualResponse = response.data;
    return actualResponse;
  } catch (err: any) {
    console.error('file: nodeService.ts ~ deleteNodeOnError ~ err:', err);
    return err?.response?.data?.message || err.message;
  }
};

export const removeNodeWithCollateralReturnService = async (
  removeNodeWithCollateralReturnInformation: RemoveNodeWithCollateralReturnInterface
) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}node/remove-node`,
      data: {
        nodeId: removeNodeWithCollateralReturnInformation?.nodeId,
        transactionHashCollateralReturn:
          removeNodeWithCollateralReturnInformation?.transactionHashCollateralReturn,
      },
      headers: {
        Authorization: `Bearer ${removeNodeWithCollateralReturnInformation?.authToken}`,
      },
    });
    const actualResponse = response.data;
    return actualResponse;
  } catch (err: any) {
    console.error('file: nodeService.ts ~ deleteNodeOnError ~ err:', err);
    return err?.response?.data?.message || err.message;
  }
};

export const getUserNodeRewardsService = async (
  payload: UserNodeRewardsInterface
) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_URL}node/get-user-rewards/${payload?.nodeId}`,
      headers: {
        Authorization: `Bearer ${payload?.authToken}`,
      },
    });
    const actualResponse = response?.data;
    return actualResponse;
  } catch (err: any) {
    console.error(
      'file: nodeService.ts:70 ~ getUserPendingNodeRewards ~ err:',
      err
    );
    return err?.response?.data?.message || err.message;
  }
};

export const getNetworkTotalStorage = async () => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_URL}node/network-storage-stats`,
      // headers: {
      //   Authorization: `Bearer ${payload?.authToken}`,
      // },
    });
    const actualResponse = response?.data;
    return actualResponse;
  } catch (err: any) {
    console.error('file: nodeService.ts ~ getNetworkTotalStorage ~ err:', err);
    return err?.response?.data?.message || err.message;
  }
};

export const getListOfNodesForAdmin = async (
  payload: ListOfNodesForAdminInterface
) => {
  try {
    const { authToken, ...params } = payload;
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_URL}node/admin/user-nodes`,
      params,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    return response?.data?.data;
  } catch (err: any) {
    console.error('err:', err);
    return err?.response?.data?.message || err.message;
  }
};

export const getListOfNodeIpsForMapAdminService = async (authToken: string) => {
  try {
    // const { authToken, ...params } = payload;
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_URL}node/admin/nodes-ip`,
      // params,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    return response?.data?.data;
  } catch (err: any) {
    console.error('err:', err);
    throw new Error(err?.message);
  }
};

export const fetchNodeGraphDataForAdmin = async (
  authToken: string,
  params?: NodeGraphQueryParamsInterface
) => {
  try {
    // const { authToken, ...params } = payload;
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_URL}node/admin/node-graph`,
      params,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    return response?.data?.data;
  } catch (err: any) {
    console.error('err:', err);
    throw new Error(err?.message);
  }
};

export const getMonthlyNodeRewardsList = async (
  authToken: string,
  params?: MonthlyNodesRewardsParamsInterface
) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_URL}node/admin/montly-rewards`,
      params,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    return response?.data?.data;
  } catch (err: any) {
    console.error('err:', err);
    throw new Error(err?.message);
  }
};
export const editNodeDetailsService = async (
  editNodeMetadata: EditNodeMetadataInterface
) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}node/edit`,
      data: {
        nodeId: editNodeMetadata.nodeId,
        name: editNodeMetadata?.name,
        ipAddress: editNodeMetadata?.ipAddress,
      },
      headers: {
        Authorization: `Bearer ${editNodeMetadata?.authToken}`,
      },
    });
    const actualResponse = response.data;
    return actualResponse;
  } catch (err: any) {
    console.error('file: nodeService.ts ~ deleteNodeOnError ~ err:', err);
    toast.error(err?.response?.data?.message || err.message);
    return err?.response?.data?.message || err.message;
  }
};
