/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
import React, { useEffect, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { ProgressBar, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { logoutAction } from '../../actions/authenticationActions';
import SVG from '../../assets/images/Svg';
import styles from './styles.module.scss';
import { getUserUsedSpace } from '../../actions/userActions';
import useLocalStorage from '../../hooks/useLocalStorage';
import { User } from '../../interfaces';
import {
  // convertBytesToGB,
  formatBytes,
  getUsedStoragePercentage,
} from '../../utils/helpers/helper';
import { getPurchasedSpaceAction } from '../../actions/paymentActions';
import { TOTAL_DEFAULT_SPACE } from '../../utils/constant';

type MenuItem = {
  id: number;
  label: string;
  to: string;
  icon: JSX.Element;
  subMenu?: MenuItem[];
  show?: boolean;
};

type SidebarProps = {
  menuItems: MenuItem[];
  setShowMenu: Function;
};

function CalculatedSize({ text, value }: any) {
  const renderTooltip = (props: any) => (
    <Tooltip id='first-tooltip' {...props}>
      {text}
    </Tooltip>
  );

  return (
    <OverlayTrigger placement='top' overlay={renderTooltip}>
      <div>{value}</div>
    </OverlayTrigger>
  );
}

function Sidebar({ menuItems, setShowMenu }: SidebarProps) {
  // TODO: Will make it dynamic when we work on payment module

  const [totalSpace, setTotalSpace] = useState<number>(TOTAL_DEFAULT_SPACE);
  const [showSubMenu, setShowSubMenu] = useState<number | null>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [authToken] = useLocalStorage('accessToken', '');
  const userDetail: User = useSelector(
    (state: any) => state.authReducer?.response?.data
  );
  const userUsedSpace: number = useSelector(
    (state: any) => state.userReducer.userUsedSpace
  );
  const userPurchasedSpace = useSelector(
    (state: any) => state?.paymentReducer?.purchaseSpaceResponse
  );

  const handleSubMenuClick = (id: number) => {
    setShowSubMenu(showSubMenu === id ? null : id);
  };

  useEffect(() => {
    if (userDetail?._id) {
      dispatch(getUserUsedSpace({ authToken }));
      dispatch(getPurchasedSpaceAction(authToken));
    }
  }, [userDetail]);

  const handleLogoutUser = () => {
    window.localStorage.clear();
    dispatch(logoutAction());
    navigate('/sign-in');
  };

  useEffect(() => {
    if (userPurchasedSpace?.success && userPurchasedSpace.data.spaceAllowed) {
      setTotalSpace(userPurchasedSpace.data.spaceAllowed + TOTAL_DEFAULT_SPACE);
    }
    if (!userPurchasedSpace?.success) {
      setTotalSpace(TOTAL_DEFAULT_SPACE);
    }
  }, [userPurchasedSpace]);

  return (
    <div className={styles.sidebar}>
      <SVG.Cross className={styles.cross} onClick={() => setShowMenu(false)} />
      <div className={styles.logo}>
        <Link to='/' className='d-flex align-items-end'>
          <SVG.Logo />
          <span className='beta fs-8 ff-poppins text-white fw-semibold mb-1'>
            BETA
          </span>
        </Link>
      </div>
      <div
        className={`h-100 d-flex flex-column justify-content-between ${styles.menu}`}
      >
        <div>
          {menuItems?.map((menuItem) => (
            <React.Fragment key={menuItem.id}>
              {menuItem.subMenu ? (
                <button
                  className={`${styles.menuItem} ${
                    menuItem.id === showSubMenu && styles.active
                  }`}
                  onClick={() => {
                    handleSubMenuClick(menuItem.id);
                    navigate(menuItem.to);
                  }}
                >
                  <span className='d-flex align-items-center justify-content-between w-100'>
                    <span>
                      {menuItem.icon}
                      {menuItem.label}
                    </span>
                    {showSubMenu === menuItem.id ? (
                      <SVG.ChevDown className='me-10' />
                    ) : (
                      <SVG.Chev className='me-10' />
                    )}
                  </span>
                </button>
              ) : (
                menuItem?.show && (
                  <NavLink
                    to={menuItem.to}
                    onClick={() => handleSubMenuClick(menuItem.id)}
                    className={({ isActive }) =>
                      isActive ? styles.active : 'inactive'
                    }
                  >
                    <span className='d-flex align-items-center'>
                      {menuItem.icon}
                      <span>{menuItem.label}</span>
                    </span>
                  </NavLink>
                )
              )}
              {menuItem.subMenu && showSubMenu === menuItem.id && (
                <div className={styles.subMenu}>
                  {menuItem.subMenu.map((subMenuItem) => (
                    <NavLink
                      key={subMenuItem.id}
                      to={subMenuItem.to}
                      className={({ isActive }) =>
                        isActive
                          ? `${styles.active} ${styles.submenu} fs-14`
                          : `inactive ${styles.submenu} fs-14`
                      }
                    >
                      <SVG.Dot className='me-10' />
                      <span>{subMenuItem.label}</span>
                    </NavLink>
                  ))}
                </div>
              )}
            </React.Fragment>
          ))}
          <div
            className={`${styles.progressBar} ${
              userPurchasedSpace?.success && styles.premium
            }`}
          >
            <div className='text-light ff-poppins'>Available Storage</div>
            <ProgressBar
              className='my-10'
              now={getUsedStoragePercentage(userUsedSpace, totalSpace)}
            />
            <div className='d-flex justify-content-between fw-bold text-light space'>
              <CalculatedSize
                text={`Used Space: ${formatBytes(userUsedSpace)}.`}
                value={formatBytes(userUsedSpace)}
              />
              <CalculatedSize
                text={`${
                  totalSpace <= TOTAL_DEFAULT_SPACE
                    ? `Total Space: ${formatBytes(totalSpace, 2, 1024)}`
                    : `150GB + ${formatBytes(
                        totalSpace - TOTAL_DEFAULT_SPACE,
                        2,
                        1024
                      )}`
                }`}
                value={formatBytes(totalSpace, 2, 1024)}
              />
              {/* <div>{convertBytesToGB(totalSpace, 0)}GB</div> */}
            </div>
          </div>
        </div>
        <div>
          <NavLink className='mb-0' to='/sign-in' onClick={handleLogoutUser}>
            <SVG.Shutdown className='me-12' /> Log Out
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
