import { ReactComponent as Password } from './eye.svg';
import { ReactComponent as Email } from './email.svg';
import { ReactComponent as Text } from './write.svg';
import { ReactComponent as Bucket } from './bucket.svg';
import { ReactComponent as ImportData } from './import-data.svg';
import { ReactComponent as Upload } from './upload.svg';
import { ReactComponent as Users } from './users.svg';
import { ReactComponent as Faq } from './faq.svg';
import { ReactComponent as Storage } from './storage.svg';
import { ReactComponent as Files } from './files-svgrepo-com1.svg';
import { ReactComponent as Shutdown } from './shutdown.svg';
import { ReactComponent as DBIcon } from './Category.svg';
import { ReactComponent as Search } from './search.svg';
import { ReactComponent as Notification } from './notification.svg';
import { ReactComponent as Wallet } from './wallet.svg';
import { ReactComponent as User } from './user.svg';
import { ReactComponent as Upgrade } from './upgrade.svg';
import { ReactComponent as Host } from './host.svg';
import { ReactComponent as Shutdown1 } from './shutdown1.svg';
import { ReactComponent as ArrowUp } from './arrowUp.svg';
import { ReactComponent as ArrowDown } from './arrowDown.svg';

import { ReactComponent as Faqs } from './faqs.svg';
import { ReactComponent as Globe } from './reg-globe.svg';
import { ReactComponent as Business } from './business.svg';
import { ReactComponent as UploadFile } from './uploadfile.svg';
import { ReactComponent as File } from './file.svg';
import { ReactComponent as Cross } from './cross.svg';
import { ReactComponent as Card } from './card.svg';
import { ReactComponent as Encrypt } from './encrypt.svg';
import { ReactComponent as Key } from './key.svg';
import { ReactComponent as Thumb } from './thumb.svg';
import { ReactComponent as Passcode } from './passcode.svg';
import { ReactComponent as Lock } from './lock.svg';
import { ReactComponent as BucketPng } from './bucketpng.svg';
import { ReactComponent as MenuDot } from './menudot.svg';
import { ReactComponent as Menu } from './menu.svg';
import { ReactComponent as Delete } from './delete.svg';
import { ReactComponent as BucketMenu } from './bucketmenu.svg';
import { ReactComponent as Copy } from './copy.svg';
import { ReactComponent as Facebook } from './facebook.svg';
import { ReactComponent as Twitter } from './twitter.svg';
import { ReactComponent as Linkedin } from './linkedin.svg';
import { ReactComponent as Reddit } from './redit.svg';
import { ReactComponent as Whatsapp } from './whatsapp.svg';
import { ReactComponent as Telegram } from './telegram.svg';
import { ReactComponent as Yahoo } from './yahoo.svg';
import { ReactComponent as EmailIcon } from './emailicon.svg';
import { ReactComponent as Bin } from './bin.svg';
import { ReactComponent as Band } from './band.svg';
import { ReactComponent as Repair } from './repair.svg';
import { ReactComponent as Disk } from './disk.svg';
import { ReactComponent as Audit } from './audit.svg';
import { ReactComponent as GreenTick } from './greentick.svg';
import { ReactComponent as RedCross } from './redcross.svg';
import { ReactComponent as Download } from './download.svg';
import { ReactComponent as Share } from './share.svg';
import { ReactComponent as Check } from './check.svg';
import { ReactComponent as Share2 } from './share2.svg';
import { ReactComponent as File2 } from './file2.svg';
import { ReactComponent as Download2 } from './download2.svg';
import { ReactComponent as MC } from './mc.svg';
import { ReactComponent as S3 } from './S3.svg';
import { ReactComponent as APISet } from './apiset.svg';
import { ReactComponent as Access } from './access.svg';
import { ReactComponent as Folder } from './folder.svg';
import { ReactComponent as DeleteFolder } from './del-folder.svg';
import { ReactComponent as Doc } from './doc.svg';
import { ReactComponent as Pdf } from './pdf.svg';
import { ReactComponent as Dot } from './dot.svg';
import { ReactComponent as Chev } from './chev2.svg';
import { ReactComponent as ChevDown } from './chevdown.svg';
import { ReactComponent as ArrowRight } from './arrowright.svg';
import { ReactComponent as Wallet2 } from './wallet2.svg';
import { ReactComponent as MyFile } from './myfile.svg';
import { ReactComponent as Logo } from './StorageChain-Logo.svg';
import { ReactComponent as BackArrow } from './backarrow.svg';
import { ReactComponent as UploadFilePro } from './uploadFilePro.svg';
import { ReactComponent as BucketModal } from './bucketModal.svg';
import { ReactComponent as Projects } from './projects.svg';
import { ReactComponent as YellowCheck } from './yellowcheck.svg';
import { ReactComponent as PasswordHide } from './hide.svg';
import { ReactComponent as UploadBucket } from './upload-bucket.svg';
import { ReactComponent as SmallCross } from './small-cross.svg';
import { ReactComponent as TeamIcon } from './team-icon.svg';
import { ReactComponent as ShareIcon } from './share-icon.svg';
import { ReactComponent as SignIn } from './sign-in.svg';
import { ReactComponent as EmailSubmit } from './email-submit.svg';
import { ReactComponent as FB } from './fb.svg';
import { ReactComponent as Insta } from './insta.svg';
import { ReactComponent as Discord } from './discord.svg';
import { ReactComponent as Reddit2 } from './raddit.svg';
import { ReactComponent as Twitter2 } from './twitter24.svg';
import { ReactComponent as LinkedIn2 } from './linkedin2.svg';
import { ReactComponent as Github } from './github.svg';
import { ReactComponent as WhitePaper } from './whitePaper.svg';
import { ReactComponent as Map } from './map.svg';
import { ReactComponent as DataStore } from './data-store.svg';
import { ReactComponent as StoreJ } from './storej.svg';
import { ReactComponent as FileCoin } from './filecoin.svg';
import { ReactComponent as Dropbox } from './dropbox.svg';
import { ReactComponent as ICloud } from './icloud.svg';
import { ReactComponent as Elipse } from './elipse.svg';
import { ReactComponent as Elipse2 } from './elipse2.svg';
import { ReactComponent as StorageProvider } from './storage-provider.svg';
import { ReactComponent as IPFS } from './icon_IPFS.svg';
import { ReactComponent as ShareM } from './shareM.svg';
import { ReactComponent as WalletM } from './walletM.svg';
import { ReactComponent as IconNFT } from './icon_NFT.svg';
import { ReactComponent as UploadSecurely } from './UploadSecurely.svg';
import { ReactComponent as BlockchainStorage } from './Gfx_BlockchainStorage.svg';
import { ReactComponent as ImgEncryption } from './img_encryption.svg';
import { ReactComponent as PCImg } from './pc-img.svg';
import { ReactComponent as StorCoinWallet } from './Gfx_StorCoinWallet.svg';
import { ReactComponent as NFTM } from './nftM.svg';
import { ReactComponent as STORCoins } from './STOR_Coins.svg';
import { ReactComponent as MenuLeft } from './menu-left.svg';
import { ReactComponent as UserAccount } from './user-account.svg';
import { ReactComponent as LinkedinIcon } from './linkedin-icon.svg';
import { ReactComponent as Coin } from './coin.svg';
import { ReactComponent as BucketIcon } from './icon_Bucket.svg';
import { ReactComponent as NodesIcon } from './icon_SC_Nodes.svg';
import { ReactComponent as SearchArrow } from './search-icon.svg';
import { ReactComponent as GfxBucket } from './Gfx_Bucket.svg';
import { ReactComponent as IconAI } from './icon_AI.svg';
import { ReactComponent as IconDOC } from './icon_DOC.svg';
import { ReactComponent as IconEPS } from './icon_EPS.svg';
import { ReactComponent as IconHTML } from './icon_HTML.svg';
import { ReactComponent as IconPDF } from './icon_PDF.svg';
import { ReactComponent as IconPSD } from './icon_PSD.svg';
import { ReactComponent as IconFolder } from './folder2.svg';
import { ReactComponent as IconInDesign } from './Deign.svg';
import { ReactComponent as IconAfterEffect } from './aftereffect.svg';
import { ReactComponent as IconPNG } from './pngicon.svg';
import { ReactComponent as IconJPG } from './jpg.svg';
import { ReactComponent as IconJPEG } from './jpeg.svg';
import { ReactComponent as IconGIF } from './gificon.svg';
import { ReactComponent as IconPPT } from './ppt.svg';
import { ReactComponent as IconEXCEL } from './excel.svg';
import { ReactComponent as IconPages } from './pages.svg';
import { ReactComponent as IconKeys } from './keys.svg';
import { ReactComponent as IconSketch } from './sketch.svg';
import { ReactComponent as IconZipFile } from './zipfile.svg';
import { ReactComponent as IconTextFile } from './textfile.svg';
import { ReactComponent as IconNumber } from './numbers.svg';
import { ReactComponent as CheckIcon } from './Checkicon.svg';
import { ReactComponent as UnCheckIcon } from './uncheckicon.svg';
import { ReactComponent as DBlogo } from './db-logo.svg';
import { ReactComponent as Icon25GB } from './25gb.svg';
import { ReactComponent as Icon4x } from './4x.svg';
import { ReactComponent as StepOne } from './step1.svg';
import { ReactComponent as StepTwo } from './Step2.svg';
import { ReactComponent as Drive } from './drive.svg';
import { ReactComponent as MapArrow } from './map-arrow.svg';
import { ReactComponent as PlusIcon } from './plusicon.svg';
import { ReactComponent as ArrowIcon } from './arrowIcon.svg';
import { ReactComponent as CrossIcon } from './crossicon.svg';
import { ReactComponent as IconWebp } from './WebpIcon.svg';
import { ReactComponent as IconAVIF } from './IconAVIF.svg';
import { ReactComponent as IconTIFF } from './IconTIFF.svg';
import { ReactComponent as IconX } from './x-icon.svg';
import { ReactComponent as IconJP2 } from './IconJP2.svg';
import { ReactComponent as IconJPX } from './IconJPX.svg';
import { ReactComponent as IconHEIC } from './IconHEIC.svg';
import { ReactComponent as IconHEIF } from './IconHEIF.svg';
import { ReactComponent as IconBMP } from './IconBMP.svg';
import { ReactComponent as IconSVG } from './IconSVG.svg';
import { ReactComponent as IconMove } from './move.svg';
import { ReactComponent as IconPaste } from './paste.svg';

import { ReactComponent as IconMP4 } from './icon_MP4.svg';
import { ReactComponent as IconMPG } from './mpg.svg';
import { ReactComponent as IconMPEG } from './IconMPEG.svg';
import { ReactComponent as IconAVI } from './IconAVI.svg';
import { ReactComponent as IconMOV } from './IconMOV.svg';
import { ReactComponent as IconMWV } from './IconMWV.svg';
import { ReactComponent as IconMKV } from './IconMKV.svg';
import { ReactComponent as IconFLV } from './IconFLV.svg';
import { ReactComponent as IconWEBM } from './IconWEBM.svg';
import { ReactComponent as IconOGV } from './IconOGV.svg';
import { ReactComponent as Icon3GP } from './Icon3GP.svg';
import { ReactComponent as Icon3G2 } from './Icon3G2.svg';
import { ReactComponent as IconTS } from './IconTS.svg';
import { ReactComponent as IconM4V } from './IconM4V.svg';

import { ReactComponent as IconMP3 } from './icon_MP3.svg';
import { ReactComponent as IconOGG } from './IconOGG.svg';
import { ReactComponent as IconWAV } from './IconWAV.svg';
import { ReactComponent as IconAIFF } from './IconAIFF.svg';
import { ReactComponent as IconAAC } from './IconAAC.svg';
import { ReactComponent as IconFLAC } from './IconFLAC.svg';
import { ReactComponent as IconMIDI } from './IconMIDI.svg';
import { ReactComponent as IconWEBA } from './IconWEBA.svg';
import { ReactComponent as IconRA } from './IconRA.svg';
import { ReactComponent as IconWMA } from './IconWMA.svg';
import { ReactComponent as IconMKA } from './IconMKA.svg';
import { ReactComponent as IconTTA } from './IconTTA.svg';
import { ReactComponent as IconM4A } from './IconM4A.svg';
import { ReactComponent as IconOPUS } from './IconOPUS.svg';
import { ReactComponent as IconTada } from './tadaIcon.svg';
import { ReactComponent as Xicon } from './xicon.svg';
import { ReactComponent as Eagle } from './eagle.svg';
import { ReactComponent as Tele } from './tele.svg';
import { ReactComponent as Youtube } from './youtube.svg';
import { ReactComponent as BackBtn } from './backbtn.svg';
import { ReactComponent as CoinGecko } from './coinmarketcap.svg';
import { ReactComponent as MEXC } from './mexc.svg';

const svg = {
  MEXC,
  IconOPUS,
  IconOGG,
  IconWAV,
  IconAIFF,
  IconAAC,
  IconFLAC,
  IconMIDI,
  IconWEBA,
  IconRA,
  IconWMA,
  IconMKA,
  IconTTA,
  IconM4A,
  IconMKV,
  IconTada,
  IconWEBM,
  IconOGV,
  Icon3GP,
  Icon3G2,
  IconTS,
  IconM4V,
  IconFLV,
  IconMWV,
  IconMOV,
  IconAVI,
  IconMPEG,
  IconMPG,
  IconWebp,
  IconTIFF,
  IconJP2,
  IconHEIC,
  IconHEIF,
  IconJPX,
  IconSVG,
  IconBMP,
  IconX,
  IconAVIF,
  Password,
  Email,
  Text,
  DBIcon,
  Bucket,
  Files,
  ImportData,
  Upload,
  Users,
  Faq,
  Storage,
  Shutdown,
  Search,
  Notification,
  Wallet,
  User,
  Upgrade,
  Host,
  Shutdown1,
  ArrowUp,
  ArrowDown,
  Faqs,
  Globe,
  Business,
  UploadFile,
  File,
  Cross,
  Card,
  Encrypt,
  Key,
  Thumb,
  Passcode,
  Lock,
  BucketPng,
  MenuDot,
  Menu,
  Delete,
  BucketMenu,
  Copy,
  Facebook,
  Twitter,
  Linkedin,
  Reddit,
  Whatsapp,
  Telegram,
  Yahoo,
  EmailIcon,
  Bin,
  Band,
  Repair,
  Disk,
  Audit,
  GreenTick,
  RedCross,
  Download,
  Share,
  Check,
  Share2,
  File2,
  Download2,
  MC,
  S3,
  APISet,
  Access,
  Folder,
  DeleteFolder,
  Doc,
  Pdf,
  Dot,
  Chev,
  ChevDown,
  ArrowRight,
  Wallet2,
  MyFile,
  Logo,
  BackArrow,
  UploadFilePro,
  BucketModal,
  Projects,
  YellowCheck,
  PasswordHide,
  UploadBucket,
  SmallCross,
  TeamIcon,
  ShareIcon,
  SignIn,
  EmailSubmit,
  FB,
  Insta,
  Discord,
  Reddit2,
  Twitter2,
  LinkedIn2,
  Github,
  WhitePaper,
  Map,
  DataStore,
  StoreJ,
  FileCoin,
  Dropbox,
  ICloud,
  Elipse,
  Elipse2,
  StorageProvider,
  IPFS,
  ShareM,
  WalletM,
  IconNFT,
  BlockchainStorage,
  ImgEncryption,
  PCImg,
  StorCoinWallet,
  NFTM,
  UploadSecurely,
  STORCoins,
  MenuLeft,
  UserAccount,
  LinkedinIcon,
  Coin,
  BucketIcon,
  NodesIcon,
  SearchArrow,
  GfxBucket,
  IconAI,
  IconDOC,
  IconEPS,
  IconHTML,
  IconMP3,
  IconMP4,
  IconPDF,
  IconPSD,
  IconFolder,
  IconInDesign,
  IconAfterEffect,
  IconPNG,
  IconJPG,
  IconJPEG,
  IconGIF,
  IconPPT,
  IconEXCEL,
  IconPages,
  IconKeys,
  IconSketch,
  IconZipFile,
  IconTextFile,
  IconNumber,
  CheckIcon,
  UnCheckIcon,
  DBlogo,
  Icon25GB,
  Icon4x,
  StepOne,
  StepTwo,
  Drive,
  MapArrow,
  PlusIcon,
  ArrowIcon,
  CrossIcon,
  Xicon,
  Eagle,
  Tele,
  IconMove,
  IconPaste,
  Youtube,
  BackBtn,
  CoinGecko,
};

export default svg;
