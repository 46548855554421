import { put, call } from 'redux-saga/effects';

import { toast } from 'react-toastify';

import * as types from '../actions';
import {
  CreatePaymentInformationSagaInterface,
  ErrorSuccessResponseInterface,
} from '../interfaces';

import {
  cancelCheckoutSessionService,
  cancelSubscriptionService,
  createPaymentService,
  getPurchasedSpaceService,
} from '../services/paymentService';
import {
  CancelCheckoutSagaInterface,
  CancelSubscriptionInterfaceSaga,
} from '../interfaces/paymentInterface';

function* createPaymentSaga(
  createPaymentInformation: CreatePaymentInformationSagaInterface
) {
  try {
    yield put({ type: types.PAYMENT_LOADER, response: true });

    const response: ErrorSuccessResponseInterface = yield call(
      createPaymentService,
      createPaymentInformation.payload
    );

    if (response?.success) {
      createPaymentInformation?.payload?.stripe?.redirectToCheckout({
        sessionId: response?.data?.id,
      });
      toast.success(response?.message);
    }
    if (!response?.success) {
      toast.error(response?.message);
    }

    yield put({ type: types.CREATE_PAYMENT_RESPONSE, response });
  } catch (err) {
    console.error('file: paymentSaga.ts ~ err:', err);
    yield put({ type: types.CREATE_PAYMENT_RESPONSE, err });
  } finally {
    yield put({ type: types.PAYMENT_LOADER, response: false });
  }
}

function* purchasedSpaceSaga(payload: any) {
  try {
    const response: ErrorSuccessResponseInterface = yield call(
      getPurchasedSpaceService,
      payload.payload
    );
    yield put({ type: types.PURCHASED_SPACE_RESPONSE, response });
  } catch (err) {
    console.error(
      'file: paymentSaga.ts ~ function*purchasedSpaceSaga ~ err:',
      err
    );
    yield put({ type: types.PURCHASED_SPACE_RESPONSE, err });
  }
}

function* cancelSubscriptionSaga(payload: CancelSubscriptionInterfaceSaga) {
  try {
    yield put({ type: types.PAYMENT_LOADER, response: true });
    const response: ErrorSuccessResponseInterface = yield call(
      cancelSubscriptionService,
      payload.payload
    );

    if (response?.success) {
      toast.success(response?.message);
      yield call(purchasedSpaceSaga, { payload: payload?.payload?.authToken });
    }
    if (!response?.success) {
      toast.error(response?.message);
    }

    yield put({ type: types.CANCEL_SUBSCRIPTION_RESPONSE, response });
  } catch (err) {
    console.error('file: paymentSaga.ts ~ err:', err);
    yield put({ type: types.CANCEL_SUBSCRIPTION_RESPONSE, err });
  } finally {
    yield put({ type: types.PAYMENT_LOADER, response: false });
  }
}

function* cancelCheckoutSessionSaga(payload: CancelCheckoutSagaInterface) {
  try {
    const response: ErrorSuccessResponseInterface = yield call(
      cancelCheckoutSessionService,
      payload.payload
    );

    if (response?.message) {
      payload.payload.setLoading(false);
    }

    yield put({ type: types.CANCEL_CHECKOUT_SESSION_RESPONSE, response });
  } catch (err) {
    console.error('file: paymentSaga.ts ~ err:', err);
    yield put({ type: types.CANCEL_CHECKOUT_SESSION_RESPONSE, err });
  }
}

export {
  createPaymentSaga,
  cancelSubscriptionSaga,
  purchasedSpaceSaga,
  cancelCheckoutSessionSaga,
};
