/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable react/function-component-definition */
import React, { useState } from 'react';
import { Container, Row, Col, Form, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Images from '../../../assets/images/Images';
import SVG from '../../../assets/images/Svg';
import styles from './styles.module.scss';

interface ModalProps {
  show: boolean;
  onHide: () => void;
}

const ThankYouModal: React.FC<ModalProps> = ({ show, onHide }) => (
  <Modal
    show={show}
    onHide={onHide}
    size='lg'
    className='thanks-modal'
    aria-labelledby='contained-modal-title-vcenter'
    centered
  >
    <Modal.Header closeButton></Modal.Header>
    <Modal.Body className='p-30 d-flex align-items-center flex-column'>
      <img loading='lazy' src={Images.checkIcon} alt='check' width='150px' />
      <h4 className='ff-cg-bold text-center mt-3'>
        Thank you for submitting your email
      </h4>
      <p className='ff-cg-bold text-center my-3'>
        We will be in touch with updates soon
      </p>
    </Modal.Body>
  </Modal>
);

function Footer() {
  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values: any,
    resetForm: any
  ): Promise<void> => {
    setLoading(true);
    if (!values.email) return;
    const formData = new FormData();
    formData.append('email', values.email);
    const url =
      'https://script.google.com/macros/s/AKfycbzoD6JbPtA2cZAf6FqRkcM5uHs0I-VnmerXJGo9bGYQItja1J1Ho2on81jCtFgaEoZc/exec';
    const response = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'text/plain;charset=utf-8',
      },
    });
    if (response.status === 200) {
      setLoading(false);
      resetForm();
      // eslint-disable-next-line no-alert
      setModalShow(true);
      setTimeout(function () {
        setModalShow(false);
      }, 10000);
    }
  };
  return (
    <>
      <div className={styles.footer}>
        <div className={styles.innerSection}>
          <Row>
            <Col lg={9}>
              <Row>
                <Col lg={4}>
                  <h6 className={styles.footerHeading}>How it Works</h6>
                  <div className={styles.footerlinks}>
                    <Link to='/how-it-works#upload'>Upload + Share</Link>
                    <Link to='/how-it-works#host-a-node'>Host a Node</Link>
                    <Link to='/how-it-works#nft-minting'>NFT Minting</Link>
                    <Link to='/how-it-works#pricing'>Pricing</Link>
                  </div>
                </Col>
                <Col lg={4}>
                  <h6 className={styles.footerHeading}>Discover</h6>
                  <div className={styles.footerlinks}>
                    <Link to='/why-store-here'>Why Store Here?</Link>
                    <Link to='/host-and-earn'>Host + Earn</Link>
                    <Link to='/press'>Press</Link>
                    <Link to='/sign-up'>Get Storage</Link>
                  </div>
                </Col>
                <Col lg={4}>
                  <h6 className={styles.footerHeading}>Company</h6>
                  <div className={styles.footerlinks}>
                    <Link to='/about-us'>About</Link>
                    <Link to='/about-us#contact'>Contact</Link>
                    <Link to='/privacy-policy'>Privacy Policy</Link>
                    <Link to='/terms-of-services'>Terms of Services</Link>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={3}>
              <h6 className={styles.footerHeading}>Stay Connected</h6>
              <Formik
                initialValues={{
                  email: '',
                }}
                validationSchema={Yup.object({
                  email: Yup.string()
                    .email('Invalid email address.')
                    .required('Email is required.')
                    .matches(
                      // /^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,6}$/,
                      /^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]+)?$/,
                      'Invalid email address.'
                    ),
                })}
                onSubmit={(values, { resetForm }) => {
                  handleSubmit(values, resetForm);
                }}
              >
                {(formik) => (
                  <form
                    className={styles.emailWrapper}
                    onSubmit={formik.handleSubmit}
                  >
                    <Form.Control
                      className={styles.email}
                      type='email'
                      id='email'
                      name='email'
                      placeholder='Your Email'
                      autoComplete='off'
                      onChange={(e: any) => {
                        formik.setFieldValue('email', e.target.value);
                      }}
                      value={formik.values.email ?? ''}
                      required={true}
                    />
                    <button className={styles.emailBtn} type='submit'>
                      <SVG.EmailSubmit />
                    </button>
                  </form>
                )}
              </Formik>
              <div className='d-flex align-items-center mt-60 flex-wrap'>
                <a
                  href='https://discord.gg/ZFZzaxpq7g'
                  target='blank'
                  className='me-15 mb-15'
                >
                  <SVG.Discord />
                </a>

                <a
                  href='https://twitter.com/StorageChain'
                  target='blank'
                  className='me-15 mb-15'
                >
                  <SVG.Xicon />
                </a>
                <a
                  href='https://www.linkedin.com/company/storagechain'
                  target='blank'
                  className='me-15 mb-15'
                >
                  <SVG.LinkedIn2 />
                </a>
                <a
                  href='https://github.com/StorageChain-LLC'
                  target='blank'
                  className='me-15 mb-15'
                >
                  <SVG.Github />
                </a>
                <a
                  href='https://t.me/s/storagechain'
                  target='blank'
                  className='me-15 mb-15'
                >
                  <SVG.Tele />
                </a>
                <a
                  href='https://www.youtube.com/@StorageChain'
                  target='blank'
                  className='me-15 mb-15'
                >
                  <SVG.Youtube />
                </a>
                <a
                  href='https://coinmarketcap.com/currencies/storagechain/'
                  target='blank'
                  className='me-15 mb-15'
                >
                  <SVG.CoinGecko />
                </a>
              </div>
            </Col>
          </Row>
          <div className='lh-base fs-12 text-light ff-poppins mt-30'>
            Copyright © 2023 StorageChain All Rights Reserved
          </div>
        </div>
      </div>
      <ThankYouModal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
}

export default Footer;
