import * as types from '../actions';
import { ActionInterface } from '../interfaces';

// eslint-disable-next-line default-param-last
const authReducer = (state = [], action: ActionInterface) => {
  const { response } = action;

  switch (action.type) {
    case types.LOGOUT_USER:
      return { response: undefined };
    case types.USER_DETAILS_SUCCESS:
      return { ...state, response };
    case types.USER_DETAILS_ERROR:
      return { ...state, response };
    default:
      return state;
  }
};
export default authReducer;
