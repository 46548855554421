import React from 'react';
import BeatLoader from 'react-spinners/BeatLoader';

function PageLoader() {
  return (
    <div
      className='min-vh-100 d-flex align-items-center justify-content-center'
      style={{ backgroundColor: '#123f57' }}
    >
      <BeatLoader color='#088dcd' />
    </div>
  );
}

export default PageLoader;
