/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-confusing-arrow */
import * as types from '../actions';
import {
  ActionInterface,
  ClusterFileUploadResponse,
  ErrorSuccessResponseInterface,
  FileFolderUploadState,
  GrantAccess,
} from '../interfaces';

interface IObjectKeys {
  [key: string]:
    | string
    | number
    | ClusterFileUploadResponse
    | Array<ClusterFileUploadResponse>;
}

interface FileUploadingProgress extends IObjectKeys {
  name: string;
  size: string;
  bytesUploaded: number;
  totalChunks: number;
  totalChunksUploaded:
    | Array<ClusterFileUploadResponse>
    | ClusterFileUploadResponse;
}

const defaultResponseValue = {
  data: {},
  success: false,
  message: '',
};

interface fileReducerState {
  filesUploadingProgress: FileUploadingProgress | Object;
  userFiles: any;
  userFilesLoader: boolean;
  fileAccess: GrantAccess | Object;
  fileShareAccess: GrantAccess | Object;
  userAccess: Array<Object>;
  showFileViewModal: boolean;
  userAccesses: Array<GrantAccess>;
  deleteFileLoading: boolean;
  mintFileLoading: boolean;
  mintFileResponse: ErrorSuccessResponseInterface;
  mintedFileResponse: ErrorSuccessResponseInterface;
  networkUsedStorageStatsResponse: ErrorSuccessResponseInterface;
  networkUsedStorageStatsLoader: boolean;
  updateFileFolderNameUpdateResponse: ErrorSuccessResponseInterface;
  fileFolderUpload: FileFolderUploadState;
  sharedFolderFiles: ErrorSuccessResponseInterface;
  moveFileOrFolderResponse: ErrorSuccessResponseInterface;
}

const initialState: fileReducerState = {
  filesUploadingProgress: {},
  userFiles: {},
  userFilesLoader: false,
  fileAccess: {},
  fileShareAccess: {},
  userAccess: [],
  showFileViewModal: false,
  userAccesses: [],
  deleteFileLoading: false,
  mintFileLoading: false,
  mintFileResponse: defaultResponseValue,
  mintedFileResponse: defaultResponseValue,
  networkUsedStorageStatsResponse: defaultResponseValue,
  networkUsedStorageStatsLoader: false,
  updateFileFolderNameUpdateResponse: defaultResponseValue,
  fileFolderUpload: { files: [], folders: {} },
  sharedFolderFiles: defaultResponseValue,
  moveFileOrFolderResponse: defaultResponseValue,
};

// eslint-disable-next-line default-param-last
const fileReducer = (state = initialState, action: ActionInterface) => {
  const { response } = action;

  switch (action.type) {
    case types.USER_FILES_LOADER:
      return { ...state, userFilesLoader: response };
    case types.UPDATE_FILE_UPLOAD_PROGRESS:
      return {
        ...state,
        filesUploadingProgress: {
          ...state.filesUploadingProgress,
          [response.name]: {
            // @ts-ignore
            ...state.filesUploadingProgress[response.name],
            ...response,
            // @ts-ignore
            bytesUploaded: state.filesUploadingProgress[response.name]
              ? response.bytesUploaded +
                // @ts-ignore
                state.filesUploadingProgress[response.name].bytesUploaded
              : response.bytesUploaded,
          },
        },
      };
    case types.UPDATE_FILE_CHUNKS_RESPONSE:
      return { ...state, filesUploadingProgress: response };
    case types.UPDATE_USER_FILES:
      return { ...state, userFiles: response };
    case types.UPDATE_USER_ACCESS:
      return { ...state, fileAccess: response };
    case types.SAVE_SHARE_FILE_ACCESS:
      return { ...state, fileShareAccess: response };
    case types.SAVE_USER_ACCESS:
      return { ...state, userAccess: response };
    case types.SHOW_FILE_VIEW_MODAL:
      return { ...state, showFileViewModal: response };
    case types.SAVE_USER_INVITE_ACCESS:
      return { ...state, userAccesses: response };
    case types.SET_FILE_DELETE_LOADING:
      return { ...state, deleteFileLoading: action.payload };

    case types.MINT_FILE_LOADING:
      return { ...state, mintFileLoading: response };

    case types.MINT_FILE:
      return { ...state, response };

    case types.MINT_FILE_RESPONSE:
      return { ...state, mintFileResponse: response };

    case types.MINTED_FILES:
      return { ...state, response };

    case types.MINTED_FILES_RESPONSE:
      return { ...state, mintedFileResponse: response };

    case types.NETWORK_USED_STORAGE_SPACE:
      return { ...state, response };

    case types.NETWORK_USED_STORAGE_SPACE_RESPONSE:
      return { ...state, networkUsedStorageStatsResponse: response };

    case types.NETWORK_USED_STORAGE_SPACE_LOADER:
      return { ...state, networkUsedStorageStatsLoader: response };

    case types.FILE_FOLDER_NAME_UPDATE:
      return { ...state, response };

    case types.FILE_FOLDER_NAME_UPDATE_RESPONSE:
      return { ...state, updateFileFolderNameUpdateResponse: response };

    case types.RESET_FILE_FOLDER_NAME_UPDATE:
      return {
        ...state,
        updateFileFolderNameUpdateResponse: defaultResponseValue,
      };
    case types.FILE_FOLDER_UPLOAD_STATE:
      return { ...state, fileFolderUpload: action?.payload };

    case types.SHARED_FOLDER_FILES:
      return { ...state, response };
    case types.SHARED_FOLDER_FILES_RESPONSE:
      return { ...state, sharedFolderFiles: response };
    case types.MOVE_FILES_AND_FOLDERS:
      return { ...state, response };
    case types.MOVE_FILES_AND_FOLDERS_RESPONSE:
      return { ...state, moveFileOrFolderResponse: response };

    default:
      return state;
  }
};
export default fileReducer;
