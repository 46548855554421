// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../assets/images/closebtn.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_encryptionModal__jRGHH .btn-close {
  background: transparent url(${___CSS_LOADER_URL_REPLACEMENT_0___}) center/1em auto no-repeat;
  opacity: 1;
  background-size: 100%; }

.styles_encryptionModal__jRGHH .modal-content {
  background: linear-gradient(127.96deg, #237aa9 1.77%, #237aa9 100%);
  border: 1.00211px solid rgba(0, 0, 0, 0.31);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  -webkit-backdrop-filter: blur(10.0211px);
          backdrop-filter: blur(10.0211px);
  border-radius: 20px; }
  .styles_encryptionModal__jRGHH .modal-content svg {
    max-width: 170px; }
  .styles_encryptionModal__jRGHH .modal-content p {
    line-height: 25px;
    max-width: 360px; }
  .styles_encryptionModal__jRGHH .modal-content .text-box {
    background: rgba(8, 141, 205, 0.3);
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    padding: 12px; }
`, "",{"version":3,"sources":["webpack://./src/components/common/UpgradeModal/UpgradeWarningPopup/styles.module.scss"],"names":[],"mappings":"AAAA;EAGM,yFAC2B;EAC3B,UAAU;EACV,qBAAqB,EAAA;;AAN3B;EASM,mEAAmE;EACnE,2CAA2C;EAC3C,2CAA2C;EAC3C,wCAAgC;UAAhC,gCAAgC;EAChC,mBAAmB,EAAA;EAbzB;IAeQ,gBAAgB,EAAA;EAfxB;IAkBQ,iBAAiB;IACjB,gBAAgB,EAAA;EAnBxB;IAsBQ,kCAAkC;IAClC,4CAA4C;IAC5C,kBAAkB;IAClB,aAAa,EAAA","sourcesContent":[".encryptionModal {\n  :global {\n    .btn-close {\n      background: transparent url('../../../../assets/images/closebtn.svg')\n        center/1em auto no-repeat;\n      opacity: 1;\n      background-size: 100%;\n    }\n    .modal-content {\n      background: linear-gradient(127.96deg, #237aa9 1.77%, #237aa9 100%);\n      border: 1.00211px solid rgba(0, 0, 0, 0.31);\n      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);\n      backdrop-filter: blur(10.0211px);\n      border-radius: 20px;\n      svg {\n        max-width: 170px;\n      }\n      p {\n        line-height: 25px;\n        max-width: 360px;\n      }\n      .text-box {\n        background: rgba(8, 141, 205, 0.3);\n        box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);\n        border-radius: 6px;\n        padding: 12px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"encryptionModal": `styles_encryptionModal__jRGHH`
};
export default ___CSS_LOADER_EXPORT___;
