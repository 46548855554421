import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/styles/global.scss';
import { ToastContainer } from 'react-toastify';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/index';

import HandleError from './_errors/error';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ErrorHandler from './ErrorHandler';
const helmetContext = {};
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <ErrorHandler>
    <HelmetProvider context={helmetContext}>
      <Helmet>
        <title>StorageChain</title>
        <meta
          name='description'
          content='StorageChain - Decentralized Encrypted Storage Bucket'
        />
      </Helmet>
      <Provider store={store}>
        <ToastContainer />
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </HelmetProvider>
    <HandleError />
  </ErrorHandler>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
