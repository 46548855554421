/* eslint-disable object-curly-spacing */
/* eslint-disable consistent-return */
import React from 'react';
import dayjs from 'dayjs';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import BeatLoader from 'react-spinners/BeatLoader';
import Layout from '../components/layout';
import useLocalStorage from '../hooks/useLocalStorage';
import {
  getUserDetailsAction,
  logoutAction,
} from '../actions/authenticationActions';
import { getUserUsedSpace } from '../actions/userActions';
import { getPurchasedSpaceAction } from '../actions/paymentActions';

export default function Auth({ element: Component, isProtected, isLayout }) {
  const navigate = useNavigate();
  const userDetails = useSelector(
    (state) => state.loginReducer.response?.data?.userDetails
  );
  const authReducer = useSelector((state) => state.authReducer.response);

  const dispatch = useDispatch();

  const { path } = useParams();

  const [user] = useLocalStorage('user', {});

  const [token] = useLocalStorage('accessToken', '');

  const [lastLoginTime] = useLocalStorage('lastLoginTime', 0);

  const [rememberMe] = useLocalStorage('rememberMe', false);

  const isTimeOut = dayjs().unix() - parseInt(lastLoginTime);
  // eslint-disable-next-line  @typescript-eslint/no-unused-vars
  const [_, setUser] = useLocalStorage('user', {});

  const handleGetUserDetails = () => {
    if ((isProtected && user && isTimeOut > 86400 && !rememberMe) || !token) {
      window.localStorage.clear();
      dispatch(logoutAction());
      return navigate('/sign-in');
    }
    if ((!userDetails?._id && user && isProtected) || token) {
      dispatch(getUserDetailsAction({ authToken: token }));
      dispatch(getUserUsedSpace({ authToken: token }));
      dispatch(getPurchasedSpaceAction(token));
    }
  };
  React.useEffect(() => {
    if (isProtected) {
      handleGetUserDetails();
    }
  }, []);

  const handleUserDetails = () => {
    if (authReducer?.success) {
      setUser(authReducer?.data);
    }
    if (!authReducer?.success) {
      console.error(authReducer?.message);
    }
  };

  React.useEffect(() => {
    if (authReducer?.success) {
      handleUserDetails();
    }
  }, [authReducer]);

  if (isLayout) {
    return (
      <Layout>
        <React.Suspense
          fallback={
            <div
              className='min-vh-100 d-flex align-items-center justify-content-center'
              style={{ backgroundColor: '#123f57' }}
            >
              <BeatLoader color='#088dcd' />
            </div>
          }
        >
          <Component path={path} />
        </React.Suspense>
      </Layout>
    );
  }

  return <Component path={path} />;
}
