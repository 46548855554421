const initialState = {
  queue: [],
  isProcessing: false,
};

// eslint-disable-next-line default-param-last
const queueReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case 'ADD_FILE':
      return {
        ...state,
        queue: [...state.queue, action?.payload],
      };
    case 'REMOVE_FILE':
      return {
        ...state,
        queue: state.queue.slice(1),
      };
    case 'PROCESS_NEXT':
      return {
        ...state,
        isProcessing: true,
      };
    case 'PROCESS_COMPLETE':
      return {
        ...state,
        isProcessing: false,
      };

    default:
      return state;
  }
};
export default queueReducer;
