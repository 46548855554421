import Logo from './StorageChain-Logo.svg';
import WhiteLogo from './StorageChain_W_Horiz_Logo.svg';
import LargeLogo from './largelogo.png';
import BucketImg from './bucket_image.png';
import Profile from './sign-in.svg';
import icon from './icon.png';
import NFT from './icon_NFT_Minted.svg';
import checkIcon from './image.png';
import NFTGrey from './icon_NFT_NM.svg';
import HostNode from './hostnode.png';
import AddStorage from './addstorage.png';
import NodeBlue from './nodeblue.png';
import NodeYellow from './nodeyellow.png';
import File from './fileimg.png';
import User from './avatar.webp';
import Server1 from './serverImg.svg';
import Server2 from './serverGreen.svg';
import Server0 from './serverRed.svg';
import LowUpTime from './lowUpTime.svg';
import TestImage from './testimage.jpeg';
import Chris from './chris.png';
import Step4 from './Step4.png';
import Step5 from './git.gif';
import Step6 from './6f8e52bdd8e4c7a372391e5e7911de12.gif';
import StepMac from './mac-gif.gif';
import DynamicNFTPreviewFile from './dynamic_image_file.svg';
import Allocate from './gfx_step1_local.png';
import Allocate2 from './gfx_step1_virtual.png';
import Jose from './jose.png';
import Contabo from './contabo.png';
import UniSwap from './uniswap.png';
import Linux6 from './step6-linux.gif';
import Linuximg from './linuximg.gif';
import zain from './zain.png';
import asad from './asad.png';
import forbs from './forbs.svg';
import cnbc from './cnbc.svg';
import bloom from './bloom.svg';
import sm1 from './sm1.svg';
import sm2 from './sm2.svg';
import sm3 from './sm3.svg';
import sm4 from './sm4.svg';
import sm5 from './sm5.svg';
import blg1 from './1blg.png';
import blg2 from './2blg.jpg';
import blg3 from './3blg.png';
import blg4 from './4blg.png';
import blg5 from './5blg.png';
import blg6 from './6blg.png';
import coingecko from './coingecko.png';
import mexc2 from './mexc2.png';
import mexc from './mexc.png';
import CoinCap from './SC_Jan26a.png';
import News1 from './new1.png';
import SCJan25b from './SCJan25b.png';
import Wrap from './wrap.png';
import CoinMarket from './coinmarketcap2.svg';
import NodeShow from './node-show.jpg';
import GreenDot from './GreenDotIcon.png';
import RedDot from './RedDotIcon.png';
import DarkGreenCircle from './DarkGreenCircle.jpeg';
import LightGreenCircle from './lightGreenCircle.png';
import MediumGreenCircle from './MediumGreenCircle.png';
import Web3Suite from './Webpage_Cover_Web3Suite.jpg';
import DC1 from './dcpng.png';
import DCJPG from './dc1.jpg';
import DC2 from './DC_Partnership_Cover_V7.png';
import BitMobile from './image2.jpg';
import Travis from './travis.jpg';
import Imgblg from './image5.png';
import Imgblg2 from './imageblog.png';
import ImgSCBP from './scbp.jpg';
import honeycomb from './honeycomb.webp';
import arbitrum from './arbitrum.png';
import velocity from './velocity.webp';
import maxres from './maxresdefault.jpg';
import nerdnode from './nerdnode.webp';
import tweet from './tweet.png';
import bonus from './bonus2.png';
import NerdPricing from './nerdnodeprice.png';
import PressAug from './press-aug.png';

const images = {
  PressAug,
  NerdPricing,
  maxres,
  nerdnode,
  velocity,
  arbitrum,
  honeycomb,
  ImgSCBP,
  Imgblg,
  Imgblg2,
  Travis,
  BitMobile,
  DCJPG,
  DC1,
  DC2,
  Web3Suite,
  GreenDot,
  RedDot,
  DarkGreenCircle,
  LightGreenCircle,
  MediumGreenCircle,
  NodeShow,
  Wrap,
  SCJan25b,
  News1,
  CoinCap,
  mexc,
  mexc2,
  coingecko,
  blg1,
  blg2,
  blg3,
  blg4,
  blg5,
  blg6,
  forbs,
  cnbc,
  bloom,
  zain,
  asad,
  Linuximg,
  Linux6,
  Logo,
  WhiteLogo,
  BucketImg,
  icon,
  Profile,
  NFT,
  checkIcon,
  NFTGrey,
  HostNode,
  AddStorage,
  NodeBlue,
  NodeYellow,
  LargeLogo,
  File,
  User,
  Server0,
  Server1,
  Server2,
  TestImage,
  Chris,
  Step4,
  Step5,
  Step6,
  StepMac,
  DynamicNFTPreviewFile,
  Allocate,
  Allocate2,
  LowUpTime,
  UniSwap,
  Jose,
  Contabo,
  sm1,
  sm2,
  sm3,
  sm4,
  sm5,
  CoinMarket,
  tweet,
  bonus,
};

export default images;
