/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import {
  Dropdown,
  // Badge,
  Tooltip,
  OverlayTrigger,
  Spinner,
} from 'react-bootstrap';
import { BiNetworkChart } from 'react-icons/bi';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import Images from '../../assets/images/Images';
import SVG from '../../assets/images/Svg';
import styles from './styles.module.scss';
import Sidebar from './Sidebar';
import Footer from '../common/DashboardFooter';
import UpgradeModal from '../common/UpgradeModal';
import { logoutAction } from '../../actions/authenticationActions';
import useLocalStorage from '../../hooks/useLocalStorage';
import {
  getChainId,
  getConnectedAccount,
  getUserNonceForMetaMask,
  loginWithMetaMask,
} from '../../web3/web3';
import {
  getUserNonceAction,
  resetUserNonceAction,
  userSignTheNoneAction,
} from '../../actions/userActions';
import { getPurchasedSpaceAction } from '../../actions/paymentActions';
import { INCORRECT_WALLET_CONNECTION_ERROR } from '../../utils/constant';
import { verifyUserDomain } from '../../utils/helpers/helper';

interface Props {
  children: React.ReactNode;
}
interface NotifiProps {
  setShow: Function;
}

function ConnectedButton({ walletAddress }: any) {
  const renderTooltip = (props: any) => (
    <Tooltip id='copy-tooltip' {...props}>
      {walletAddress}
    </Tooltip>
  );

  return (
    <OverlayTrigger placement='bottom' overlay={renderTooltip}>
      <button className='text-light d-flex overflow-hidden'>Connected</button>
    </OverlayTrigger>
  );
}

function NotificationDropdown({ setShow }: NotifiProps) {
  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={`${styles.notifiDropdown}`} ref={ref}>
      <div className='notification-header'>Notifications</div>
      <div className='notification-body'>
        <div className='h-100 d-flex align-items-center justify-content-center'>
          <p className='text-center fs-16 text-dark2'>No notification found</p>
        </div>

        {/* <div className='notification'>
          <div className='heading'>Notification</div>
          <div className='time'>Now</div>
        </div>
        <div className='notification'>
          <div className='heading'>Notification</div>
          <div className='time'>Now</div>
        </div>
        <div className='notification'>
          <div className='heading'>Notification</div>
          <div className='time'>1h ago</div>
        </div>
        <div className='notification'>
          <div className='heading'>Notification</div>
          <div className='time'>1h ago</div>
        </div>
        <div className='notification'>
          <div className='heading'>Notification</div>
          <div className='time'>1h ago</div>
        </div>
        <div className='notification'>
          <div className='heading'>Notification</div>
          <div className='time'>1h ago</div>
        </div>
        <div className='notification'>
          <div className='heading'>Notification</div>
          <div className='time'>1h ago</div>
        </div> */}
      </div>
    </div>
  );
}

function Layout(props: Props) {
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [searchedValue, setSearchedValue] = useState<string>('');

  const userPurchasedSpace = useSelector(
    (state: any) => state?.paymentReducer?.purchaseSpaceResponse
  );
  const planText =
    userPurchasedSpace?.data?.packageName?.split(' ')?.[0] || '0TB';
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userBuckets = useSelector(
    (state: any) => state.getBucketsByUserIdReducer.response
  );

  const [userDetails] = useLocalStorage('user', {});
  const userNonceResponse = useSelector(
    (state: any) => state.userReducer?.getUserNonceResponse
  );
  const menuItems = [
    {
      show: true,
      id: 4,
      label: 'Dashboard',
      to: '/dashboard',
      icon: <SVG.DBIcon className='me-10' />,
    },
    {
      show: true,
      id: 5,
      label: 'Buckets',
      to: '/buckets',
      icon: <SVG.BucketIcon className='me-10' />,
    },
    {
      show: true,
      id: 6,
      to: '/data-access',
      label: 'Data Access',
      icon: <SVG.ImportData className='me-10' />,
    },
    {
      show: true,
      id: 7,
      to: '/shared-access',
      label: 'Shared Files',
      icon: <SVG.ShareIcon className='me-10' />,
    },
    {
      show: true,
      id: 8,
      to: '/upload',
      label: 'Upload',
      icon: <SVG.Upload className='me-10' />,
    },
    {
      show: true,
      id: 9,
      to: userDetails?.userType === 'business' ? '/users' : '/organizations',
      label: userDetails?.userType === 'business' ? 'Users' : 'Organizations',
      icon: <SVG.Users className='me-10' />,
    },

    {
      show: true,
      id: 10,
      to: '/how-it-works',
      label: 'How It Works',
      icon: <SVG.Faq className='me-10' />,
    },
    {
      show: true,
      id: 11,
      to: '/my-storage-node',
      label: 'My Storage Nodes',
      icon: <SVG.NodesIcon className='me-10' />,
      subMenu: [
        {
          id: 11,
          to: '/setup-node',
          label: 'Host a Storage Node',
          icon: <SVG.Dot className='me-10 ms-3' />,
        },
      ],
    },
    {
      show: !!verifyUserDomain(userDetails?.email?.split('@')[1]),
      id: 12,
      to: '/admin-pannel?tab=networkDetails',
      label: 'Admin Panel',
      icon: <BiNetworkChart className='me-10 fs-24' />,
    },
  ];

  const [showMenu, setShowMenu] = useState(false);

  const [connectWalletLoader, setConnectWalletLoader] =
    useState<boolean>(false);
  const [image, setImage] = useState<string | null>(null);
  const [showSearch, setShowSearch] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [accessToken] = useLocalStorage('accessToken', '');
  const [walletAddress] = useLocalStorage('walletAddress', '');
  const [_walletAddress, setWalletAddress] = useLocalStorage(
    'walletAddress',
    ''
  );
  const [metaSearch, setMetaSearch] = useState<boolean>(false);

  useEffect(() => {
    const connectNewUser = async (accounts: any = [], chainId: any = 0) => {
      let [connectedAccount] = accounts;
      if (!connectedAccount) {
        const metaMaskConnectedAccounts = await getConnectedAccount();
        [connectedAccount] = metaMaskConnectedAccounts;
      }

      if (
        userDetails?.walletAddress?.toLowerCase() !==
          connectedAccount?.toLowerCase() ||
        chainId !== '0x2216'
      ) {
        setWalletAddress('');
        return false;
      }
      return true;
    };

    const subscribeToMetamaskEvents = () => {
      (window as any)?.ethereum?.on(
        'accountsChanged',
        async (accounts: any) => {
          if (!accounts?.length) {
            dispatch(userSignTheNoneAction('NOT_SIGNED'));
            return setWalletAddress('');
          }
          await connectNewUser(accounts);
          return true;
        }
      );

      (window as any)?.ethereum?.on('chainChanged', async (chainId: any) => {
        await connectNewUser('', chainId);
      });
    };
    subscribeToMetamaskEvents();
  }, []);

  const handleLogoutUser = () => {
    window.localStorage.clear();
    dispatch(resetUserNonceAction({}));
    dispatch(logoutAction());
    navigate('/sign-in');
  };

  const handleMetaMaskWalletClick = async (selectedBlockChainType: string) => {
    try {
      setConnectWalletLoader(true);
      const connectedAcccouts = await getConnectedAccount();

      if (
        connectedAcccouts?.length > 0 &&
        userDetails?.walletAddress &&
        connectedAcccouts[0]?.toLowerCase() !==
          userDetails?.walletAddress?.toLowerCase()
      ) {
        throw new Error(
          INCORRECT_WALLET_CONNECTION_ERROR.replace(
            '{wallet_address}',
            userDetails?.walletAddress
          )
        );
      }

      const loggedInConnectedWallet = await loginWithMetaMask();

      if (loggedInConnectedWallet) {
        dispatch(
          getUserNonceAction({
            authToken: accessToken,
            walletAddress: loggedInConnectedWallet,
            chainType: selectedBlockChainType,
          })
        );
      }
    } catch (err: any) {
      toast.error(err.message);
      setConnectWalletLoader(false);
      setWalletAddress('');
    }
  };

  const handleMyAccountClick = () => {
    dispatch(getPurchasedSpaceAction(accessToken));
  };

  const handleUpgradeClick = () => {
    dispatch(getPurchasedSpaceAction(accessToken));
    setShowUpgradeModal(true);
  };

  useEffect(() => {
    const verifyAccountIsConnected = async () => {
      try {
        const connectedAccounts = await getConnectedAccount();
        const chainId = await getChainId();

        if (
          connectedAccounts[0]?.toLowerCase() ===
            userDetails?.walletAddress?.toLowerCase() &&
          chainId ===
            parseInt(process.env.REACT_APP_STORAGECHAIN_CHAINID || '0')
        ) {
          return true;
        }
        return setWalletAddress('');
      } catch (err) {
        console.error(
          'file: index.tsx:329 ~ verifyAccountIsConnected ~ err:',
          err
        );
        return setWalletAddress('');
      }
    };
    verifyAccountIsConnected();
  }, [userDetails]);

  useEffect(() => {
    if (userBuckets?.success && !userBuckets?.data?.data?.length) {
      navigate('/create-bucket');
    }
  }, [userBuckets]);

  useEffect(() => {
    const handleNonceSign = async () => {
      try {
        dispatch(userSignTheNoneAction('PENDING'));
        const nonceValue = userNonceResponse?.data?.nonce;
        const nonceString = `I am signing my one-time nonce for StorageChain: ${nonceValue}`;
        const nonceResponse: any = await getUserNonceForMetaMask(
          userNonceResponse?.data?.walletAddress,
          nonceString,
          nonceValue
        );
        if (!nonceResponse?.success) {
          throw new Error(nonceResponse?.message);
        }

        setConnectWalletLoader(false);
        setWalletAddress(userNonceResponse?.data?.walletAddress);
        dispatch(userSignTheNoneAction('SIGNED'));
        return toast.success('Wallet connected successfully.');
      } catch (err: any) {
        console.error('file: index.tsx:365 ~ handleNonceSign ~ err:', err);
        if (err.message.includes('User denied message signature')) {
          toast.error('User denied message signature.');
        }
        setConnectWalletLoader(false);
        dispatch(userSignTheNoneAction('NOT_SIGNED'));
        return setWalletAddress('');
      }
    };
    if (userNonceResponse?.success) {
      handleNonceSign();
    }
    if (!userNonceResponse?.success) {
      setConnectWalletLoader(false);
    }
  }, [userNonceResponse]);
  useEffect(() => {
    setImage(
      `${
        userDetails?.avatar
          ? process.env.REACT_APP_IPFS_NODE_URL_TO_FETCH_FILE
          : ''
      }${userDetails?.avatar ? userDetails?.avatar : Images.Profile}`
    );
  }, [userDetails]);

  const handleSearchTextChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchedValue(event?.target?.value);
  };

  const handleSearchClick = () => {
    if (!searchedValue) {
      return null;
    }

    if (metaSearch) {
      return navigate(`/search?value=${searchedValue?.trim()}&metaSearch=true`);
    }

    return navigate(`/search?value=${searchedValue?.trim()}`);
  };

  const handleCrossClick = () => {
    setSearchedValue('');
    navigate('/dashboard');
  };

  const handleToggle = (val: any) => {
    setMetaSearch(val === 1);
  };

  return (
    <>
      <div
        className={`${showMenu && styles.open} ${
          showSearch && styles.openSearch
        } ${styles.dashboardLayout}`}
      >
        <Sidebar menuItems={menuItems} setShowMenu={setShowMenu} />
        <div className={styles.content}>
          <div>
            <div className={styles.header}>
              <div
                className='d-block d-lg-none'
                onClick={() => setShowMenu(true)}
              >
                <SVG.Menu />
              </div>
              <div
                className={styles.overlay}
                onClick={() => {
                  setShowSearch(false);
                  setShowMenu(false);
                }}
              />
              <div className={`d-none d-lg-flex ${styles.search}`}>
                <div className='position-relative text-light'>
                  <SVG.Search className='vertical-center end-0 me-10' />
                  <Form.Control
                    type='input'
                    value={searchedValue}
                    placeholder={
                      metaSearch ? 'Search by meta fields' : 'Search by files'
                    }
                    onChange={handleSearchTextChange}
                    onKeyDown={(event: React.KeyboardEvent) => {
                      if (event.key === 'Enter') {
                        handleSearchClick();
                      }
                    }}
                  />
                  {searchedValue && (
                    <SVG.Cross className='cross' onClick={handleCrossClick} />
                  )}
                  {/* <SVG.SearchArrow
                    className='search-arrow vertical-center mx-15'
                    onClick={handleSearchClick}
                  /> */}
                  <ToggleButtonGroup
                    type='radio'
                    name='options'
                    className='toggle'
                    value={metaSearch ? 1 : 2}
                    onChange={handleToggle}
                  >
                    <ToggleButton id='meta' value={1}>
                      Meta Search
                    </ToggleButton>
                    <ToggleButton id='file' value={2}>
                      File Search
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>{' '}
                {/* <Form.Check
                  type='switch'
                  id='custom-switch'
                  label={metaSearch ? 'Meta Search' : 'File Search'}
                  className='toggle'
                  value={metaSearch ? 1 : 0}
                  onChange={() => setMetaSearch(!metaSearch)}
                /> */}
              </div>
              <div className={styles.right}>
                <SVG.Search
                  className={`d-block d-lg-none ${styles.searchIcon}`}
                  onClick={() => setShowSearch(true)}
                />
                {userPurchasedSpace?.success && (
                  <span
                    className='bg-secondary p-10 circle pointer'
                    onClick={() => {
                      navigate('/my-account');
                    }}
                  >
                    {planText}
                    <br /> Pro
                  </span>
                )}
                {!!walletAddress && (
                  <ConnectedButton walletAddress={walletAddress} />
                )}
                {!walletAddress && (
                  <button
                    className='text-light d-flex'
                    onClick={() => {
                      handleMetaMaskWalletClick('storageChain');
                    }}
                    disabled={connectWalletLoader}
                  >
                    {connectWalletLoader ? (
                      <Spinner size='sm' animation='border' color='white' />
                    ) : (
                      <>
                        <SVG.Wallet /> <span>Wallet</span>
                      </>
                    )}
                  </button>
                )}
                <div className='position-relative'>
                  <button
                    className={`${styles.notifiBtn}`}
                    onClick={() => setShowNotification(true)}
                  >
                    {/* <Badge pill text='light' className='fs-10'>
                      2
                    </Badge> */}
                    <SVG.Notification />
                  </button>
                  {showNotification && (
                    <NotificationDropdown setShow={setShowNotification} />
                  )}
                </div>

                <Dropdown className={styles.dropdown}>
                  <Dropdown.Toggle variant='success' id='dropdown-basic'>
                    <img
                      loading='lazy'
                      src={image !== null ? image : Images.Profile}
                      alt='profile'
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item className='p-0'>
                      <NavLink
                        to='/my-account'
                        className={({ isActive }) =>
                          isActive ? styles.active : 'inactive'
                        }
                        onClick={handleMyAccountClick}
                      >
                        <SVG.User /> My Account
                      </NavLink>
                    </Dropdown.Item>
                    <Dropdown.Item className='p-0'>
                      <NavLink
                        to='/setup-node'
                        className={({ isActive }) =>
                          isActive ? styles.active : 'inactive'
                        }
                      >
                        <SVG.Host /> Host a Node
                      </NavLink>
                    </Dropdown.Item>
                    <Dropdown.Item className='p-0'>
                      <span onClick={() => handleUpgradeClick()}>
                        <SVG.Upgrade /> Upgrade
                      </span>
                    </Dropdown.Item>
                    <Dropdown.Item className='p-0'>
                      <NavLink
                        to='/sign-in'
                        className={({ isActive }) =>
                          isActive ? styles.active : 'inactive logout'
                        }
                        onClick={handleLogoutUser}
                      >
                        <SVG.Shutdown1 /> Log out
                      </NavLink>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className={styles.pageContent}>{props.children}</div>
          </div>
          <Footer />
        </div>
      </div>
      <UpgradeModal show={showUpgradeModal} setShow={setShowUpgradeModal} />
    </>
  );
}

export default Layout;
