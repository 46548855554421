import * as types from '../../actions';
import { ActionInterface } from '../../interfaces';

// eslint-disable-next-line default-param-last
const getBucketsByUserIdReducer = (state = [], action: ActionInterface) => {
  const { response } = action;

  switch (action.type) {
    case types.GET_BUCKETS_BY_USER_LOADER:
      return { ...state, loader: response };
    case types.GET_BUCKETS_BY_USER_SUCCESS:
      return { ...state, response };
    case types.GET_BUCKETS_BY_USER_ERROR:
      return { ...state, response };
    default:
      return state;
  }
};
export default getBucketsByUserIdReducer;
