/* eslint-disable function-paren-newline */
/* eslint-disable guard-for-in */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-exponentiation-operator */
/* eslint-disable no-restricted-properties */
/* eslint-disable arrow-body-style */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable consistent-return */
/* eslint-disable no-plusplus */
/* eslint-disable no-tabs */
/* eslint-disable import/prefer-default-export */

import React from 'react';
import CryptoJS from 'crypto-js';
import moment from 'moment';
import dayjs from 'dayjs';
import axios from 'axios';
import { TiCancel } from 'react-icons/ti';
import { AiOutlineFile } from 'react-icons/ai';
import { create } from 'ipfs-http-client';
import SVG from '../../assets/images/Svg';
import { getConnectedAccount } from '../../web3/web3';
import {
  COLLETRAL_PRICE_PER_GB,
  SPECIAL_SPLITTER,
  VALUES_PER_UNITS,
} from '../constant';
import { getFolderDetails } from '../../services/folderService';
// eslint-disable-next-line import/no-unresolved
const client = create(new URL('http://46.101.133.110:5001'));

export const gerFormatedDate = (date) => moment(date).format('MM/DD/YYYY');

export const getFileTypeSvg = (type) => type;

const generateRandomSalt = (length) => {
  try {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  } catch (e) {
    console.error(e?.message);
    return '';
  }
};
const generateUserKey = (salt, passphrase) => {
  try {
    const userKey = CryptoJS.HmacSHA256(
      `${salt}${passphrase}`,
      process.env.REACT_APP_PASS_PHRASE_ENCRYPTION_PRIVATE_KEY
    );

    return userKey.toString();
  } catch (e) {
    console.error(e?.message);
    return '';
  }
};
const readFileIpfs = async (ipfsHash) => {
  const fileStream = client.cat(ipfsHash, {
    length: 10000000,
  });
  let fileData = '';
  for await (const chunk of fileStream) {
    // chunks of data are returned as a Buffer, convert it back to a string
    fileData += chunk;
  }

  return fileData;
};

const toHexString = (bytes) =>
  bytes.reduce((str, byte) => str + byte.toString(16).padStart(2, '0'), '');

const fromHexString = (hexString) =>
  Uint8Array.from(hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16)));

// const FILE_ICONS = {
//   'image/png': <SVG.File />,
//   'application/pdf': <SVG.Doc />,
//   'image/jpg': <SVG.File />,
//   'application/zip': <SVG.zip />,
// };

const extensionToMimeTypeMap = {
  // Folder
  folder: 'application/x.folder',

  // DMG & DS_Store
  dmg: '',
  DS_Store: '',

  // PDF
  pdf: 'application/pdf',
  // PostScript
  ps: 'application/postscript',
  eps: 'application/postscript',
  // Adobe Photoshop
  psd: 'image/vnd.adobe.photoshop',
  // Adobe Illustrator
  ai: 'application/ai',
  // Adobe InDesign
  ind: 'application/x-indesign',
  // Adobe After Effects
  aep: 'application/x-after-effects',
  // Sketch
  sketch: 'application/x-sketch',
  // HTML
  html: 'text/html',
  // Image Formats
  jpg: 'image/jpg',
  jpeg: 'image/jpeg',
  png: 'image/png',
  gif: 'image/gif',
  webp: 'image/webp',
  avif: 'image/avif',
  tiff: 'image/tiff',
  ico: 'image/x-icon',
  jp2: 'image/jp2',
  jpx: 'image/jpx',
  heic: 'image/heic',
  heif: 'image/heif',
  bmp: 'image/bmp',
  svg: 'image/svg+xml',
  // Zip
  zip: 'application/zip',
  '7z': 'application/zip',
  rar: 'application/zip',
  gz: 'application/zip',
  tar: 'application/zip',
  bz2: 'application/zip',
  lzma: 'application/zip',
  tgz: 'application/zip',
  tbz2: 'application/zip',
  Z: 'application/zip',
  zipx: 'application/zip',
  pkg: 'application/zip',
  war: 'application/zip',
  xpi: 'application/zip',
  jar: 'application/zip',
  // Word
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  dotx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
  docm: 'application/vnd.ms-word.document.macroEnabled.12',
  dotm: 'application/vnd.ms-word.template.macroEnabled.12',
  // Excel
  xlt: 'application/vnd.ms-excel',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  xltx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
  xlsm: 'application/vnd.ms-excel.sheet.macroenabled.12',
  xltm: 'application/vnd.ms-excel.template.macroenabled.12',
  xlam: 'application/vnd.ms-excel.addin.macroenabled.12',
  xlsb: 'application/vnd.ms-excel.sheet.binary.macroenabled.12',
  // PowerPoint
  ppt: 'application/vnd.ms-powerpoint',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  potx: 'application/vnd.openxmlformats-officedocument.presentationml.template',
  ppsx: 'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  ppam: 'application/vnd.ms-powerpoint.addin.macroenabled.12',
  pptm: 'application/vnd.ms-powerpoint.presentation.macroenabled.12',
  potm: 'application/vnd.ms-powerpoint.template.macroenabled.12',
  ppsm: 'application/vnd.ms-powerpoint.slideshow.macroenabled.12',
  // Text
  txt: 'text/plain',
  // Video
  mpg: 'video/mpeg',
  mpeg: 'video/mpeg2',
  mp4: 'video/mp4',
  avi: 'video/x-msvideo',
  mov: 'video/quicktime',
  wmv: 'video/x-ms-wmv',
  mkv: 'video/x-matroska',
  flv: 'video/x-flv',
  webm: 'video/webm',
  ogv: 'video/ogg',
  '3gp': 'video/3gpp',
  '3g2': 'video/3gpp2',
  ts: 'video/mp2t',
  m4v: 'video/x-m4v',
  // Audio
  mp3: 'audio/mpeg',
  ogg: 'audio/ogg',
  wav: 'audio/wav',
  aiff: 'audio/x-aiff',
  aac: 'audio/aac',
  flac: 'audio/flac',
  midi: 'audio/midi',
  weba: 'audio/webm',
  ra: 'audio/x-pn-realaudio',
  wma: 'audio/x-ms-wma',
  mka: 'audio/x-matroska',
  tta: 'audio/tta',
  m4a: 'audio/mp4',
  opus: 'audio/opus',
  // Keynote
  key: 'application/x-iwork-keynote-sffkey',

  // default
  default: 'application/octet-stream',
};

const mimeTypeToIconMap = {
  folder: <SVG.IconFolder />,
  'application/x.folder': <SVG.IconFolder />,
  'application/pdf': <SVG.IconPDF />,
  'application/postscript': <SVG.IconEPS />,
  'image/vnd.adobe.photoshop': <SVG.IconPSD />,

  'application/ai': <SVG.IconAI />,
  'application/x-indesign': <SVG.IconInDesign />,
  'application/x-after-effects': <SVG.IconAfterEffect />,
  'application/x-sketch': <SVG.IconSketch />,
  'text/html': <SVG.IconHTML />,
  // Image Formats
  'image/jpg': <SVG.IconJPG />,
  'image/jpeg': <SVG.IconJPEG />,
  'image/png': <SVG.IconPNG />,
  'image/gif': <SVG.IconGIF />,

  'image/webp': <SVG.IconWebp />,
  'image/avif': <SVG.IconAVIF />,
  'image/tiff': <SVG.IconTIFF />,
  'image/x-icon': <SVG.IconX />,
  'image/jp2': <SVG.IconJP2 />,
  'image/jpx': <SVG.IconJPX />,
  'image/heic': <SVG.IconHEIC />,
  'image/heif': <SVG.IconHEIF />,
  'image/bmp': <SVG.IconBMP />,
  'image/svg+xml': <SVG.IconSVG />,
  // ---
  'application/zip': <SVG.IconZipFile />,
  'application/gzip': <SVG.IconZipFile />,
  'application/x-tar': <SVG.IconZipFile />,
  'application/x-7z-compressed': <SVG.IconZipFile />,
  'application/x-rar-compressed': <SVG.IconZipFile />,
  'application/x-bzip2': <SVG.IconZipFile />,
  'application/x-lzma': <SVG.IconZipFile />,
  'application/x-gzip-compressed-tar': <SVG.IconZipFile />,
  'application/x-bzip-compressed-tar': <SVG.IconZipFile />,
  'application/x-compress': <SVG.IconZipFile />,
  'application/x-zip-compressed': <SVG.IconZipFile />,

  'application/msword': <SVG.IconDOC />,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': (
    <SVG.IconDOC />
  ),
  'application/vnd.openxmlformats-officedocument.wordprocessingml.template': (
    <SVG.IconDOC />
  ),
  'application/vnd.ms-word.document.macroEnabled.12': <SVG.IconDOC />,
  'application/vnd.ms-word.template.macroEnabled.12': <SVG.IconDOC />,
  // excel
  'application/vnd.ms-excel': <SVG.IconEXCEL />,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': (
    <SVG.IconEXCEL />
  ),
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template': (
    <SVG.IconEXCEL />
  ),
  'application/vnd.ms-excel.sheet.macroenabled.12': <SVG.IconEXCEL />,
  'application/vnd.ms-excel.template.macroenabled.12': <SVG.IconEXCEL />,
  'application/vnd.ms-excel.addin.macroenabled.12': <SVG.IconEXCEL />,
  'application/vnd.ms-excel.sheet.binary.macroenabled.12': <SVG.IconEXCEL />,

  // PPT
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': (
    <SVG.IconPPT />
  ),
  'application/vnd.ms-powerpoint': <SVG.IconPPT />,
  'application/vnd.openxmlformats-officedocument.presentationml.template': (
    <SVG.IconPPT />
  ),
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow': (
    <SVG.IconPPT />
  ),
  'application/vnd.ms-powerpoint.addin.macroenabled.12': <SVG.IconPPT />,
  'application/vnd.ms-powerpoint.presentation.macroenabled.12': <SVG.IconPPT />,
  'application/vnd.ms-powerpoint.template.macroenabled.12': <SVG.IconPPT />,
  'application/vnd.ms-powerpoint.slideshow.macroenabled.12': <SVG.IconPPT />,

  'text/plain': <SVG.IconTextFile />,
  // Video formats
  'video/mpeg': <SVG.IconMPG />,
  'video/mpeg2': <SVG.IconMPEG />,
  'video/mp4': <SVG.IconMP4 />,
  'video/x-msvideo': <SVG.IconAVI />,
  'video/quicktime': <SVG.IconMOV />,
  'video/x-ms-wmv': <SVG.IconMWV />,
  'video/x-matroska': <SVG.IconMKV />,
  'video/x-flv': <SVG.IconFLV />,
  'video/webm': <SVG.IconWEBM />,
  'video/ogg': <SVG.IconOGV />,
  'video/3gpp': <SVG.Icon3GP />,
  'video/3gpp2': <SVG.Icon3G2 />,
  'video/mp2t': <SVG.IconTS />,
  'video/x-m4v': <SVG.IconM4V />,
  // Audio
  'audio/mpeg': <SVG.IconMP3 />,
  'audio/ogg': <SVG.IconOGG />,
  'audio/wav': <SVG.IconWAV />,
  'audio/x-aiff': <SVG.IconAIFF />,
  'audio/aac': <SVG.IconAAC />,
  'audio/flac': <SVG.IconFLAC />,
  'audio/midi': <SVG.IconMIDI />,
  'audio/webm': <SVG.IconWEBA />,
  'audio/x-pn-realaudio': <SVG.IconRA />,
  'audio/x-ms-wma': <SVG.IconWMA />,
  'audio/x-matroska': <SVG.IconMKA />,
  'audio/tta': <SVG.IconTTA />,
  'audio/mp4': <SVG.IconM4A />,
  'audio/opus': <SVG.IconOPUS />,

  'application/x-iwork-keynote-sffkey': <SVG.IconKeys />,
};
const defaultIcon = <AiOutlineFile className='fs-30 text-warning' />;

const previewAvailableTypes = [
  'image',
  'powerpoint',
  'excel',
  'presentationml',
  'spreadsheetml',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'pdf',
  'video',
  'audio',
  'msword',
];

function getFileIcon(fileName) {
  const extension = fileName?.split('.')?.pop()?.toLowerCase();
  const mimeType = extensionToMimeTypeMap[extension];
  const icon = mimeTypeToIconMap[mimeType] || defaultIcon;
  return icon;
}

function getS3FileIcon(fileName) {
  const extension = fileName?.split('.')?.pop()?.toLowerCase();
  const mimeType = extensionToMimeTypeMap[extension];

  const icon = mimeTypeToIconMap[mimeType] || defaultIcon;
  return icon;
}

const fileIcons = (type) => {
  const icon = mimeTypeToIconMap[type] || defaultIcon;
  return icon;
};

const emailSuccessFailedIcons = (type) => {
  switch (type) {
    case true:
      return <SVG.YellowCheck />;
    case false:
      return <TiCancel size={100} />;
    default:
      return <SVG.YellowCheck />;
  }
};

function shortenString(str, length) {
  const maxLength = length * 2 + 3; // length of the beginning, end, and ellipsis
  if (str.length <= maxLength) {
    return str;
  }
  if (typeof str === 'string') {
    const beginning = str?.substring(0, length);
    const end = str?.substring(str.length - length);
    return `${beginning}..${end}`;
  }
}

function formatBytes(bytes, decimals = 2, k = 1024) {
  if (!+bytes) return '0 Bytes';

  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

function formatBytesWithSeprateUnit(bytes, decimals = 2, k = 1024) {
  if (!+bytes) return { unit: 'Bytes', value: 0 };

  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return {
    unit: sizes[i],
    value: parseFloat(parseFloat(bytes / k ** i)?.toFixed(dm)),
  };
}

const generateRandomString = (
  length,
  characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
) => {
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const getUsedStoragePercentage = (usedSpace, totalSpace) =>
  (usedSpace / totalSpace) * 100;

const convertBytesToGB = (bytes, toFixedValue) =>
  (bytes / (1024 * 1024 * 1024)).toFixed(toFixedValue);

const verifyWalletAddress = async (walletAddress) => {
  try {
    const connectedAccounts = await getConnectedAccount();

    if (
      !connectedAccounts?.length ||
      connectedAccounts[0]?.toLowerCase() !== walletAddress?.toLowerCase()
    ) {
      return false;
    }
    return true;
  } catch (err) {
    return false;
  }
};

const getConvertedDecimalPrice = (price, zeroChecks = 2) => {
  if (!price) return price;

  const conversionPrice = price.toString();
  if (conversionPrice.includes('e')) return conversionPrice;

  let count = 0;

  if (conversionPrice.includes('.')) {
    const [integerPart, decimalPart] = conversionPrice.split('.');

    for (const number of decimalPart) {
      if (parseInt(number) === 0) {
        count++;
      }

      if (parseInt(integerPart) > 0 && count >= zeroChecks) {
        return integerPart;
      }

      if (parseInt(number) > 0) {
        const decimalPlaces = count <= 1 ? 2 : count + 1;
        return price.toFixed(decimalPlaces);
      }
    }
  }

  return price;
};

const getCurrentPriceForWSTORCoin = async (setCurrentCoinPrice) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_URL}payment/coin-price`,
    });

    const actualResponse = response?.data;

    setCurrentCoinPrice(parseFloat(actualResponse?.data[0]?.priceUsd));
  } catch (err) {
    console.error('file: index.tsx ~ getCurrentPriceForWSTORCoin ~ err:', err);
  }
};

const getCurrentPriceForWSTORCoinOnlyValue = async () => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_URL}payment/coin-price`,
    });

    const actualResponse = response?.data;

    return parseFloat(actualResponse?.data[0]?.priceUsd);
  } catch (err) {
    console.error('file: index.tsx ~ getCurrentPriceForWSTORCoin ~ err:', err);
  }
};

const getCurrentIpAddress = async () => {
  const response = await axios({
    method: 'get',
    url: 'https://api.ipify.org/?format=json',
  });
  return response?.data?.ip;
};

const calculateTotal = (amount, unit) =>
  amount * VALUES_PER_UNITS[unit] * COLLETRAL_PRICE_PER_GB;

const formatRewardDate = (startDate) => {
  const midOfMonth = moment(startDate).clone().add(15, 'days');
  const currentMonth = midOfMonth.format('MMM YYYY');
  console.log(
    'file: helper.js:484 ~ formatRewardDate ~ currentMonth:',
    currentMonth,
    midOfMonth.toDate()
  );

  return currentMonth;
};

const formateCalculations = (amount) => parseFloat(amount).toLocaleString();
const verifyUserDomain = (currentUserDomain) => {
  const whileListedDomains =
    process.env.REACT_APP_WHITELISTED_DOMAINS?.split(',');

  const domainVerified = whileListedDomains?.find(
    (domain) => domain === currentUserDomain?.toLowerCase()
  );

  return domainVerified;
};

function formatNumber(value) {
  const suffixes = {
    0: '',
    1: 'K',
    2: 'M',
    3: 'B',
    4: 'T',
    // Add more suffixes as needed
  };

  const num = parseFloat(value);

  if (isNaN(num)) {
    return 'Invalid number';
  }

  const sign = Math.sign(num);
  const absNum = Math.abs(num);

  if (absNum < 1000) {
    return num.toFixed(0);
  }

  const exp = Math.floor(Math.log10(absNum) / 3);
  const formattedNum = (absNum / Math.pow(10, exp * 3)).toFixed(0);
  return sign * formattedNum + suffixes[exp];
}

const currencyOptions = {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

function generateNestedObjectFromArray(arrays) {
  const result = { folders: {}, files: [] };

  function addToObject(obj, path, content, count) {
    // const specialIdentifier = `|$|${count}|$|`;
    if (path.length === 0) {
      if (content !== undefined) {
        obj.files.push(content);
      }
    } else {
      const [_key, ...rest] = path;
      const key = _key === 'files' ? '_files' : _key;
      obj.folders[key] = obj.folders[key] || { folders: {}, files: [] };
      addToObject(obj.folders[key], rest, content, ++count);
    }
  }

  let count = 0;
  for (const fileArray of arrays) {
    const [content, ...path] = fileArray;
    addToObject(result, path?.reverse(), content, ++count);
  }

  return result;
}

function removeKeyIfFoldersEmpty(obj, keyname) {
  if (!obj || Array.isArray(obj) || Object.keys(obj || {}).length === 0) {
    return obj; // Handle edge case: obj is falsy
  }
  if (obj?.folders && obj?.folders[keyname]) {
    if (
      Object.keys(obj?.folders[keyname]?.folders)?.length === 0 &&
      obj?.folders[keyname]?.files?.length === 0
    ) {
      delete obj?.folders[keyname];
    }
  }

  // Recursively process all keys in the object
  for (const key in obj) {
    if (typeof obj[key] === 'object') {
      obj[key] = removeKeyIfFoldersEmpty(obj[key], keyname);
    }
  }

  return obj;
}

function insertKeysToObjects(obj, keyname, updatedKeyName, value) {
  if (!obj) {
    return obj; // Handle edge case: obj is falsy
  }

  if (obj[keyname] && !obj[keyname][updatedKeyName]?._id) {
    obj[keyname][updatedKeyName] = value; // Update or insert the "_id" key
    return obj[keyname][updatedKeyName];
  }

  // Recursively process all keys in the object
  for (const key in obj) {
    if (typeof obj[key] === 'object') {
      insertKeysToObjects(obj[key], keyname, updatedKeyName, value);
    }
  }

  return obj;
}

function getFolderWhichIsNotCreated(obj, key = null) {
  if (typeof obj === 'object' && !Array.isArray(obj)) {
    if (
      obj?.hasOwnProperty('files') &&
      obj?.hasOwnProperty('folders') &&
      !obj?.hasOwnProperty('folderMetadata')
    ) {
      return { [key]: obj }; // Return the object that contains 'folderMetadata'
    }
    for (const key in obj) {
      const foundObject = getFolderWhichIsNotCreated(obj[key], key);
      if (foundObject) {
        return foundObject;
      }
    }
  }
  return { files: [], folders: {}, folderMetadata: {} };
}

function getParentFolder(input, keyname, parent = null) {
  if (input && typeof input === 'object') {
    if (keyname in input) {
      return parent;
    }
    for (const prop in input) {
      const result = getParentFolder(input[prop], keyname, input);
      if (result) {
        return result;
      }
    }
  }
  return null;
}

function findEmptyFolders(input, parentKey = '') {
  // Initialize an array to store the empty folder names
  const emptyFolders = [];

  // Check if the input is an object
  if (typeof input === 'object') {
    // Check if the input has "folders" and "files" properties
    if (
      input?.hasOwnProperty('folders') &&
      input?.hasOwnProperty('files') &&
      Object.keys(input.folders).length === 0 &&
      input.files.length === 0
    ) {
      // If both "folders" and "files" are empty, add the current key to the array
      emptyFolders.push(parentKey);
    }

    // Recursively process the sub-objects
    for (const key in input) {
      if (input.hasOwnProperty(key)) {
        // Concatenate the parent key with the current key
        const currentKey = parentKey ? `${parentKey}.${key}` : key;
        // const currentKey = parentKey ? parentKey : key;
        emptyFolders.push(...findEmptyFolders(input[key], currentKey));
      }
    }
  }

  return emptyFolders;
}

function createQueryStringFromObject(queryObject) {
  const queryString = Object.entries(queryObject)
    .map(([key, value]) => {
      if (!value) {
        return;
      }
      if (typeof value === 'object') {
        // eslint-disable-next-line no-param-reassign
        value = JSON.stringify(value);
      }
      return `${key}=${encodeURIComponent(value)}`;
    })
    .join('&');

  return `?${queryString}`;
}

async function selectDirectoryForDownload() {
  try {
    // Request a directory handle from the user
    const directoryHandle = await window?.showDirectoryPicker();

    // Create a file handle in the selected directory
    return directoryHandle;
  } catch (error) {
    console.error('Error downloading file:', error);
    throw error;
  }
}

async function downloadFile(directoryHandle, fileName) {
  try {
    const fileHandle = await directoryHandle?.getFileHandle(fileName, {
      create: true,
    });
    return fileHandle;
  } catch (error) {
    console.error('Error downloading file:', error);
    throw error;
  }
}

async function createFolder(directoryHandle, folderName) {
  try {
    const folderHandle = await directoryHandle?.getDirectoryHandle(
      folderName?.trim(),
      {
        create: true,
      }
    );
    return folderHandle;
  } catch (error) {
    console.error('Error downloading file:', error);
    throw error;
  }
}

function findUnprocessedFiles(obj, path = []) {
  if (obj && typeof obj === 'object') {
    if (obj.objectType === 'file' && !obj.processed) {
      return {
        file: obj,
        path,
      };
    }

    if (obj.files && Array.isArray(obj.files)) {
      for (const [index, file] of obj.files.entries()) {
        const result = findUnprocessedFiles(file, [...path, obj.name]);
        if (result) {
          // obj.files[index].processed = true;
          return result;
        }
      }
    }
  }

  return null;
}

function updateObjectById(inputObject, idToUpdate, newData) {
  // Helper function to recursively search and update the object
  function updateObjectRecursive(obj) {
    if (obj._id === idToUpdate) {
      // Update the object with new data
      Object.assign(obj, newData);
      return true; // Stop searching, object found and updated
    }

    if (obj.files && obj.files.length > 0) {
      // If the object has files, recursively search and update them
      for (const file of obj.files) {
        if (updateObjectRecursive(file)) {
          return true; // Stop searching, file found and updated
        }
      }
    }

    return false; // Object or file not found
  }

  // Start the recursive search from the root of the inputObject
  updateObjectRecursive(inputObject);

  return inputObject;
}

function getTotalFileCount(obj) {
  if (!obj || typeof obj !== 'object') {
    throw new Error('Invalid input. Please provide a valid object.');
  }

  function getTotalFileCountForObject(object) {
    if (object && typeof object === 'object') {
      const currentFileCount =
        object.files && Array.isArray(object.files)
          ? object.files?.filter((file) => file?.objectType === 'file')?.length
          : 0;

      if (object.files && Array.isArray(object.files)) {
        const nestedFileCounts = object.files.map((file) => {
          return getTotalFileCountForObject(file);
        });
        return (
          currentFileCount +
          nestedFileCounts.reduce((acc, count) => acc + count, 0)
        );
      }
      return currentFileCount;
    }
    return 0;
  }

  return getTotalFileCountForObject(obj);
}

function deleteProcessed(obj) {
  for (const key in obj) {
    if (key === 'files' && Array.isArray(obj[key])) {
      obj[key] = obj[key].filter((file) => !file.processed);
      obj[key].forEach(deleteProcessed);
    } else if (typeof obj[key] === 'object') {
      obj[key] = deleteProcessed(obj[key]);
    }
  }
  return obj;
}

function appendUniqueStringToFolderNames(input) {
  function updateFolderNames(folders) {
    for (const folderKey in folders) {
      const uniqueString = generateRandomString(folderKey?.length);
      const newFolderKey = `${folderKey} ${SPECIAL_SPLITTER} ${uniqueString}`;
      folders[newFolderKey] = folders[folderKey];

      // Remove the old folder key if it has been modified
      if (newFolderKey !== folderKey) {
        delete folders[folderKey];
      }

      if (folders[newFolderKey].folders) {
        updateFolderNames(folders[newFolderKey].folders);
      }
    }
  }

  const updatedInput = { ...input }; // Create a shallow copy to avoid modifying the original object
  updateFolderNames(updatedInput.folders);

  return updatedInput;
}

export async function folderWhichAreAlreadyExist(
  folders,
  bucketId,
  folderId,
  authToken
) {
  const mainFolders = Object.keys(folders);
  const alreadyExistsFolders = {};
  const allowedFolders = folders;
  // for (const name of mainFolders) {
  for (let i = 0; i < mainFolders?.length; i++) {
    const name = mainFolders[i];
    const folderName = name?.split(SPECIAL_SPLITTER)[0].trim();
    try {
      // eslint-disable-next-line no-await-in-loop
      const response = await getFolderDetails({
        bucketId,
        folderId,
        folderName,
        authToken,
      });

      if (response?.success) {
        if (!alreadyExistsFolders[name]) {
          alreadyExistsFolders[name] = {};
        }
        alreadyExistsFolders[name] = allowedFolders[name];
        delete allowedFolders[name];
      }
    } catch (err) {
      console.error('file: helper.js:875 ~ err:', err);
      alreadyExistsFolders[name] = {};
    }
  }

  return { alreadyExistsFolders, allowedFolders };
}

function removeSpecialCharacters(input) {
  // Define the regular expression pattern to match non-alphanumeric characters
  const regex = /[^a-zA-Z0-9]+/g;

  // Replace all non-alphanumeric characters with an empty string
  const result = input.replace(regex, '');

  return result;
}

export {
  extensionToMimeTypeMap,
  currencyOptions,
  formatNumber,
  generateRandomSalt,
  generateUserKey,
  toHexString,
  fromHexString,
  readFileIpfs,
  fileIcons,
  formatBytes,
  shortenString,
  emailSuccessFailedIcons,
  getUsedStoragePercentage,
  convertBytesToGB,
  verifyWalletAddress,
  generateRandomString,
  getFileIcon,
  getConvertedDecimalPrice,
  getCurrentPriceForWSTORCoin,
  getCurrentIpAddress,
  calculateTotal,
  formatRewardDate,
  formateCalculations,
  verifyUserDomain,
  formatBytesWithSeprateUnit,
  getCurrentPriceForWSTORCoinOnlyValue,
  previewAvailableTypes,
  generateNestedObjectFromArray,
  removeKeyIfFoldersEmpty,
  insertKeysToObjects,
  getFolderWhichIsNotCreated,
  getParentFolder,
  findEmptyFolders,
  createQueryStringFromObject,
  selectDirectoryForDownload,
  downloadFile,
  createFolder,
  findUnprocessedFiles,
  updateObjectById,
  getTotalFileCount,
  deleteProcessed,
  appendUniqueStringToFolderNames,
  getS3FileIcon,
  removeSpecialCharacters,
};
