import * as types from '../actions';
import { ActionInterface } from '../interfaces';

// eslint-disable-next-line default-param-last
const forgotPasswordReducer = (state = [], action: ActionInterface) => {
  const { response } = action;

  switch (action.type) {
    case types.FORGET_PASSWORD_EMAIL_SUCCESS:
      return { ...state, response };
    case types.FORGET_PASSWORD_EMAIL_ERROR:
      return { ...state, response };
    default:
      return state;
  }
};
export default forgotPasswordReducer;
