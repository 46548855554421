import { put, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import {
  registerUserService,
  loginUserService,
  userDetailsService,
  forgetPasswordService,
  resetPasswordService,
  emailVerifyService,
} from '../services/authenticationService';

import * as types from '../actions';

import {
  EmailVerificationInterface,
  ErrorSuccessResponseInterface,
  ForgetPasswordInterface,
  UpdatePasswordInterface,
  UserDetailsInterface,
  UserLoginDetailsAndHandlerSagaInterface,
  UserSignUpInterface,
} from '../interfaces';
import { getBucketsByUserIdSaga } from './bucketSaga';

export function* registerSaga(payload: UserSignUpInterface) {
  try {
    const response: ErrorSuccessResponseInterface = yield call(
      registerUserService,
      payload
    );
    if (response?.success && response?.message) {
      toast.success(response?.message);
    }

    yield put({ type: types.REGISTER_USER_SUCCESS, response });
  } catch (error: any) {
    // toast.error(error?.message);
    yield put({ type: types.REGISTER_USER_ERROR, error });
  }
}

export function* userDetailsSaga(payload: UserDetailsInterface) {
  try {
    const response: ErrorSuccessResponseInterface = yield call(
      userDetailsService,
      payload
    );
    yield put({ type: types.USER_DETAILS_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.USER_DETAILS_ERROR, error });
  }
}

export function* handleLogoutSaga() {
  yield put({ type: types.CLEAR_REDUX_STATE });
}

export function* loginSaga({
  payload,
}: UserLoginDetailsAndHandlerSagaInterface) {
  try {
    const response: ErrorSuccessResponseInterface = yield call(
      loginUserService,
      {
        email: payload.userDetails.email,
        password: payload.userDetails.password,
      }
    );
    yield put({ type: types.LOGIN_USER_SUCCESS, response });

    if (response?.success) {
      toast.success(response?.message);
      const lastLoginTime = dayjs().unix();
      payload.responseHandlers.setAccessToken(response.data?.token);

      payload.responseHandlers.setUser(response.data?.userDetails);
      payload.responseHandlers.setLastLoginTime(lastLoginTime);

      yield call(userDetailsSaga, {
        user: { authToken: response.data?.token },
      });
      yield call(getBucketsByUserIdSaga, {
        authToken: response?.data?.token,
        page: 0,
      });
      return payload.responseHandlers.navigate('/dashboard');
    }

    if (!response.success && response.data?.isActive === false) {
      payload.responseHandlers.setAliasEmail(response.data?.email);

      return payload.responseHandlers.navigate('/verify-email');
    }

    if (!response.success) {
      return toast.error(response?.message);
    }

    return payload.responseHandlers.navigate('/sign-in');
  } catch (error) {
    yield put({ type: types.LOGIN_USER_ERROR, error });
    return payload.responseHandlers.navigate('/sign-in');
  } finally {
    payload.responseHandlers.setLoading(false);
  }
}

export function* forgetPasswordEmail(payload: ForgetPasswordInterface) {
  try {
    const response: ErrorSuccessResponseInterface = yield call(
      forgetPasswordService,
      payload
    );
    if (response?.success && response?.message) {
      toast.success(response?.message);
    }
    if (!response?.success && response?.message) {
      toast.error(response?.message);
    }
    yield put({ type: types.FORGET_PASSWORD_EMAIL_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.FORGET_PASSWORD_EMAIL_ERROR, error });
  }
}

export function* resetPasswordSaga(payload: UpdatePasswordInterface) {
  try {
    const response: ErrorSuccessResponseInterface = yield call(
      resetPasswordService,
      payload
    );
    if (response?.success) {
      toast.success(response?.message);
    }
    if (!response?.success) {
      toast.error(response?.message);
    }
    yield put({ type: types.RESET_PASSWORD_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.RESET_PASSWORD_ERROR, error });
  }
}

export function* verifyEmailSaga(payload: EmailVerificationInterface) {
  try {
    const response: ErrorSuccessResponseInterface = yield call(
      emailVerifyService,
      payload
    );
    if (response?.success) {
      toast.success(response?.message);
    }
    if (!response?.success) {
      toast.error(response?.message);
    }
    yield put({ type: types.VERIFY_EMAIL_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.VERIFY_EMAIL_ERROR, error });
  }
}
