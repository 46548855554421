// ALL USER ACTIONS HERE
export const SIGN_UP_INVITED_USER = 'SIGN_UP_INVITED_USER';
export const SIGN_UP_INVITED_USER_RESPONSE = 'SIGN_UP_INVITED_USER_RESPONSE';
export const EMAIL_VERIFICATION = 'EMAIL_VERIFICATION';
export const EMAIL_VERIFICATION_RESPONSE = 'EMAIL_VERIFICATION_RESPONSE';
export const VERIFY_PASS_PHRASE = 'VERIFY_PASS_PHRASE';
export const VERIFY_PASS_PHRASE_RESPONSE = 'VERIFY_PASS_PHRASE_RESPONSE';
export const CREATE_USER_KEY = 'CREATE_USER_KEY';
export const CREATE_USER_KEY_RESPONSE = 'CREATE_USER_KEY_RESPONSE';
export const USER_ORGANIZATIONS = 'USER_ORGANIZATIONS';
export const USER_ORGANIZATIONS_RESPONSE = 'USER_ORGANIZATIONS_RESPONSE';
export const ORGANIZATION_USER = 'ORGANIZATION_USER';
export const ORGANIZATION_USER_RESPONSE = 'ORGANIZATION_USER_RESPONSE';
export const REMOVE_ORGANIZATION_USER = 'REMOVE_ORGANIZATION_USER';
export const REMOVE_ORGANIZATION_USER_RESPONSE =
  'REMOVE_ORGANIZATION_USER_RESPONSE';

export const ADD_USER_TO_ORGANIZATION = 'ADD_USER_TO_ORGANIZATION';
export const ADD_USER_TO_ORGANIZATION_RESPONSE =
  'ADD_USER_TO_ORGANIZATION_RESPONSE';

export const CLEAR_REDUX_STATE = 'CLEAR_REDUX_STATE';
export const LOGOUT_USER = 'LOGOUT_USER';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';

export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';

export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS';
export const USER_DETAILS_ERROR = 'USER_DETAILS_ERROR';

export const FORGET_PASSWORD_EMAIL = 'FORGET_PASSWORD_EMAIL';
export const FORGET_PASSWORD_EMAIL_SUCCESS = 'FORGET_PASSWORD_EMAIL_SUCCESS';
export const FORGET_PASSWORD_EMAIL_ERROR = 'FORGET_PASSWORD_EMAIL_ERROR';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

export const VERIFY_EMAIL = 'VERIFY_EMAIL';
export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS';
export const VERIFY_EMAIL_ERROR = 'VERIFY_EMAIL_ERROR';
export const RESET_VERIFY_EMAIL = 'RESET_VERIFY_EMAIL';

export const GET_USER_NONCE = 'GET_USER_NONCE';
export const GET_USER_NONCE_RESPONSE = 'GET_USER_NONCE_RESPONSE';
export const RESET_USER_NONCE_RESPONSE = 'RESET_USER_NONCE_RESPONSE';

export const USER_SIGN_THE_NONCE = 'USER_SIGN_THE_NONCE';

export const GET_USER_USED_SPACE = 'GET_USER_USED_SPACE';
export const UPDATE_USER_USED_SPACE = 'UPDATE_USER_USED_SPACE';
export const GER_USERS_LIST_EMAIL_BY_USER = 'GER_USERS_LIST_EMAIL_BY_USER';

export const EDIT_USER = 'EDIT_USER';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_ERROR = 'EDIT_USER_ERROR';

export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_ERROR = 'UPDATE_PASSWORD_ERROR';

export const SET_PROFILE_UPDATE_LOADING = 'SET_PROFILE_UPDATE_LOADING';

// ALL BUCKET ACTIONS HERE
export const CREATE_BUCKET = 'CREATE_BUCKET';
export const CREATE_BUCKET_SUCCESS = 'CREATE_BUCKET_SUCCESS';
export const CREATE_BUCKET_ERROR = 'CREATE_BUCKET_ERROR';

export const GET_BUCKETS_BY_USER = 'GET_BUCKETS_BY_USER';
export const GET_BUCKETS_BY_USER_SUCCESS = 'GET_BUCKETS_BY_USER_SUCCESS';
export const GET_BUCKETS_BY_USER_ERROR = 'GET_BUCKETS_BY_USER_ERROR';
export const GET_BUCKETS_BY_USER_LOADER = 'GET_BUCKETS_BY_USER_LOADER';

export const DELETE_BUCKET = 'DELETE_BUCKET';
export const DELETE_BUCKET_SUCCESS = 'DELETE_BUCKET_SUCCESS';
export const DELETE_BUCKET_ERROR = 'DELETE_BUCKET_ERROR';

export const SET_BUCKET_DELETE_LOADING = 'SET_BUCKET_DELETE_LOADING';

// ALL FILE TYPES HERE
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const CANCEL_UPLOAD_FILE = 'CANCEL_UPLOAD_FILE';
export const UPDATE_FILE_UPLOAD_PROGRESS = 'UPDATE_FILE_UPLOAD_PROGRESS';
export const USER_FILES_LOADER = 'USER_FILES_LOADER';
export const UPDATE_FILE_CHUNKS_RESPONSE = 'UPDATE_FILE_CHUNKS_RESPONSE';
export const UPDATE_USER_FILES = 'UPDATE_USER_FILES';
export const FETCH_USER_FILES = 'FETCH_USER_FILES';
export const GRANT_FILE_ACCESS = 'GRANT_FILE_ACCESS';
export const UPDATE_USER_ACCESS = 'UPDATE_USER_ACCESS';
export const GET_FILE_ACCESS = 'GET_FILE_ACCESS';
export const SAVE_SHARE_FILE_ACCESS = 'SAVE_SHARE_FILE_ACCESS';
export const GET_USER_ACCESS = 'GET_USER_ACCESS';
export const SAVE_USER_ACCESS = 'SAVE_USER_ACCESS';
export const REVOKE_ACCESS = 'REVOKE_ACCESS';
export const DOWNLOAD_OR_VIEW_FILE_ACCESS = 'DOWNLOAD_OR_VIEW_FILE_ACCESS';
export const SHOW_FILE_VIEW_MODAL = 'SHOW_FILE_VIEW_MODAL';
export const SET_INVITE_ACCESS = 'SET_INVITE_ACCESS';
export const GET_USER_INVITE_ACCESS = 'GET_USER_INVITE_ACCESS';
export const SAVE_USER_INVITE_ACCESS = 'SAVE_USER_INVITE_ACCESS';
export const SET_FILE_DELETE_LOADING = 'SET_FILE_DELETE_LOADING';
// MINT FILE
export const MINT_FILE_LOADING = 'MINT_FILE_LOADING';
export const MINT_FILE = 'MINT_FILE';
export const MINT_FILE_RESPONSE = 'MINT_FILE_RESPONSE';
export const MINTED_FILES = 'MINTED_FILES';
export const MINTED_FILES_RESPONSE = 'MINTED_FILES_RESPONSE';
export const NETWORK_USED_STORAGE_SPACE = 'NETWORK_USED_STORAGE_SPACE';
export const NETWORK_USED_STORAGE_SPACE_RESPONSE =
  'NETWORK_USED_STORAGE_SPACE_RESPONSE';
export const NETWORK_USED_STORAGE_SPACE_LOADER =
  'NETWORK_USED_STORAGE_SPACE_LOADER';
export const FILE_FOLDER_NAME_UPDATE = 'FILE_FOLDER_NAME_UPDATE';
export const FILE_FOLDER_NAME_UPDATE_RESPONSE =
  'FILE_FOLDER_NAME_UPDATE_RESPONSE';
export const RESET_FILE_FOLDER_NAME_UPDATE = 'RESET_FILE_FOLDER_NAME_UPDATE';
export const FILE_FOLDER_UPLOAD_STATE = 'FILE_FOLDER_UPLOAD_STATE';

export const SHARED_FOLDER_FILES_RESPONSE = 'SHARED_FOLDER_FILES_RESPONSE';
export const SHARED_FOLDER_FILES = 'SHARED_FOLDER_FILES';

export const MOVE_FILES_AND_FOLDERS = 'MOVE_FILES_AND_FOLDERS';
export const MOVE_FILES_AND_FOLDERS_RESPONSE =
  'MOVE_FILES_AND_FOLDERS_RESPONSE';

// ALL TOAST TYPES HERE
export const SUCCESS_MESSAGE = 'SUCCESS_MESSAGE';
export const ERROR_MESSAGE = 'ERROR_MESSAGE';

// ALL FOLDER TYPES HERE
export const CREATE_FOLDER = 'CREATE_FOLDER';
export const CREATE_FOLDER_RESPONSE = 'CREATE_FOLDER_RESPONSE';
export const DELETE_FOLDER = 'DELETE_FOLDER';
export const DELETE_FOLDER_RESPONSE = 'DELETE_FOLDER_RESPONSE';
export const SET_FOLDER_LOADING = 'SET_FOLDER_LOADING';
export const DOWNLOAD_FOLDER = 'DOWNLOAD_FOLDER';

// ALL RESET STATE TYPES HERE
export const RESET_UPDATE_PASSWORD_STATE = 'RESET_UPDATE_PASSWORD_STATE';
// ALL PAYMENT TYPES HERE
export const PAYMENT_LOADER = 'PAYMENT_LOADER';
export const CREATE_PAYMENT = 'CREATE_PAYMENT';
export const CREATE_PAYMENT_RESPONSE = 'CREATE_PAYMENT_RESPONSE';
export const UPDATE_PAYMENT_STATUS = 'UPDATE_PAYMENT_STATUS';
export const UPDATE_PAYMENT_STATUS_RESPONSE = 'UPDATE_PAYMENT_STATUS_RESPONSE';
export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION';
export const CANCEL_SUBSCRIPTION_RESPONSE = 'CANCEL_SUBSCRIPTION_RESPONSE';
export const PURCHASED_SPACE = 'PURCHASED_SPACE';
export const PURCHASED_SPACE_RESPONSE = 'PURCHASED_SPACE_RESPONSE';
export const CANCEL_CHECKOUT_SESSION = 'CANCEL_CHECKOUT_SESSION';
export const CANCEL_CHECKOUT_SESSION_RESPONSE =
  'CANCEL_CHECKOUT_SESSION_RESPONSE';

// ALL NODES TYPES HERE
export const USER_NODES = 'USER_NODES';
export const USER_NODES_RESPONSE = 'USER_NODES_RESPONSE';
export const DELETE_NODE_ON_ERROR = 'DELETE_NODE_ON_ERROR';
export const DELETE_NODE_ON_ERROR_RESPONSE = 'DELETE_NODE_ON_ERROR_RESPONSE';
export const REMOVE_NODE_WITH_COLLATERAL_RETURN =
  'REMOVE_NODE_WITH_COLLATERAL_RETURN';
export const REMOVE_NODE_WITH_COLLATERAL_RETURN_RESPONSE =
  'REMOVE_NODE_WITH_COLLATERAL_RETURN_RESPONSE';

export const USER_NODE_REWARDS_RESPONSE = 'USER_NODE_REWARDS_RESPONSE';
export const USER_REWARDS_LOADER = 'USER_REWARDS_LOADER';
export const NETWORK_STORAGE_STATS_LOADER = 'NETWORK_STORAGE_STATS_LOADER';
export const USER_NODE_REWARDS = 'USER_NODE_REWARDS';
export const USER_NODES_LOADER = 'USER_NODES_LOADER';
export const NETWORK_STORAGE_STATS = 'NETWORK_STORAGE_STATS';
export const NETWORK_STORAGE_STATS_RESPONSE = 'NETWORK_STORAGE_STATS_RESPONSE';
export const ADMIN_MAP_NODES = 'ADMIN_MAP_NODES';
export const EDIT_NODE_DETAILS = 'EDIT_NODE_DETAILS';
export const EDIT_NODE_DETAILS_RESPONSE = 'EDIT_NODE_DETAILS_RESPONSE';
export const EDIT_NODE_DETAILS_LOADER = 'EDIT_NODE_DETAILS_LOADER';
