import { takeEvery, takeLatest } from 'redux-saga/effects';
import { TakeableChannel } from 'redux-saga';
import {
  registerSaga,
  loginSaga,
  userDetailsSaga,
  forgetPasswordEmail,
  resetPasswordSaga,
  verifyEmailSaga,
  handleLogoutSaga,
} from './authenticatioSaga';
import {
  fetchUserFilesSaga,
  getFileAccessSaga,
  getMintedFilesSaga,
  getNetworkUsedStorageSpaceSaga,
  getSharedFolderFilesSaga,
  getUserAccessesSaga,
  getUserAccessSaga,
  grantAccessSaga,
  mintFileSaga,
  moveFileAndFolderSaga,
  revokeAccessSaga,
  setInvitesAccessSaga,
  updateFileFolderNameSaga,
  uploadFile,
} from './fileSaga';

import * as types from '../actions';
import {
  createBucketSaga,
  deleteBucketSaga,
  getBucketsByUserIdSaga,
} from './bucketSaga';
import {
  createUserKeySaga,
  getUserNonceSaga,
  emailVerificationSaga,
  verifyUserPassPhraseSaga,
  getUserUsedSpaceSaga,
  signUpInvitedUserSaga,
  userOrganizationsSaga,
  organizationUserSaga,
  removeOrganizationUserSaga,
  addUserToOrganizationSaga,
  editUserSaga,
  updatePasswordSaga,
  handleUpdatePasswordResetState,
  resetEmailVerificationSaga,
  resetUserNonceSaga,
} from './userSaga';
import {
  createFolderSaga,
  removeFolderSaga,
  resetFolderSagaState,
} from './folderSaga';
import {
  cancelCheckoutSessionSaga,
  cancelSubscriptionSaga,
  createPaymentSaga,
  purchasedSpaceSaga,
} from './paymentSaga';
import {
  deleteNodeOnErrorSaga,
  getUserNodesSaga,
  getPendingNodeRewardsSaga,
  removeNodeWithCollateralReturnSaga,
  getNetworkTotalStorageSaga,
  editNodeDetailsSaga,
} from './nodeSaga';

export function* watchUserAuthentication() {
  yield takeLatest(
    types.REGISTER_USER as unknown as TakeableChannel<unknown>,
    registerSaga
  );

  yield takeLatest(
    types.LOGIN_USER as unknown as TakeableChannel<unknown>,
    loginSaga
  );

  yield takeLatest(
    types.GET_USER_DETAILS as unknown as TakeableChannel<unknown>,
    userDetailsSaga
  );

  yield takeLatest(
    types.FORGET_PASSWORD_EMAIL as unknown as TakeableChannel<unknown>,
    forgetPasswordEmail
  );

  yield takeLatest(
    types.RESET_PASSWORD as unknown as TakeableChannel<unknown>,
    resetPasswordSaga
  );

  yield takeLatest(
    types.RESET_USER_NONCE_RESPONSE as unknown as TakeableChannel<unknown>,
    resetUserNonceSaga
  );

  yield takeLatest(
    types.VERIFY_EMAIL as unknown as TakeableChannel<unknown>,
    verifyEmailSaga
  );

  // ALL BUCKET SAGA HERE
  yield takeLatest(
    types.CREATE_BUCKET as unknown as TakeableChannel<unknown>,
    createBucketSaga
  );
  yield takeLatest(
    types.DELETE_BUCKET as unknown as TakeableChannel<unknown>,
    deleteBucketSaga
  );
  yield takeLatest(
    types.GET_BUCKETS_BY_USER as unknown as TakeableChannel<unknown>,
    getBucketsByUserIdSaga
  );
  yield takeLatest(types.LOGOUT_USER, handleLogoutSaga);
}

export function* watchFileSaga() {
  yield takeEvery(
    types.UPLOAD_FILE as unknown as TakeableChannel<unknown>,
    uploadFile
  );
  yield takeLatest(
    types.FETCH_USER_FILES as unknown as TakeableChannel<unknown>,
    fetchUserFilesSaga
  );
  yield takeLatest(
    types.GRANT_FILE_ACCESS as unknown as TakeableChannel<unknown>,
    grantAccessSaga
  );
  yield takeLatest(
    types.GET_FILE_ACCESS as unknown as TakeableChannel<unknown>,
    getFileAccessSaga
  );
  yield takeLatest(
    types.GET_USER_ACCESS as unknown as TakeableChannel<unknown>,
    getUserAccessSaga
  );
  yield takeLatest(
    types.REVOKE_ACCESS as unknown as TakeableChannel<unknown>,
    revokeAccessSaga
  );
  yield takeLatest(
    types.SET_INVITE_ACCESS as unknown as TakeableChannel<unknown>,
    setInvitesAccessSaga
  );
  yield takeLatest(
    types.GET_USER_INVITE_ACCESS as unknown as TakeableChannel<unknown>,
    getUserAccessesSaga
  );

  yield takeLatest(
    types.MINT_FILE as unknown as TakeableChannel<unknown>,
    mintFileSaga
  );

  yield takeLatest(
    types.MINTED_FILES as unknown as TakeableChannel<unknown>,
    getMintedFilesSaga
  );

  yield takeLatest(
    types.FILE_FOLDER_NAME_UPDATE as unknown as TakeableChannel<unknown>,
    updateFileFolderNameSaga
  );

  yield takeLatest(
    types.NETWORK_USED_STORAGE_SPACE as unknown as TakeableChannel<unknown>,
    getNetworkUsedStorageSpaceSaga
  );

  yield takeLatest(
    types.SHARED_FOLDER_FILES as unknown as TakeableChannel<unknown>,
    getSharedFolderFilesSaga
  );
  yield takeLatest(
    types.MOVE_FILES_AND_FOLDERS as unknown as TakeableChannel<unknown>,
    moveFileAndFolderSaga
  );
}

export function* watchUserSaga() {
  yield takeLatest(
    types.VERIFY_PASS_PHRASE as unknown as TakeableChannel<unknown>,
    verifyUserPassPhraseSaga
  );
  yield takeLatest(
    types.CREATE_USER_KEY as unknown as TakeableChannel<unknown>,
    createUserKeySaga
  );

  yield takeLatest(
    types.EDIT_USER as unknown as TakeableChannel<unknown>,
    editUserSaga
  );

  yield takeLatest(
    types.UPDATE_PASSWORD as unknown as TakeableChannel<unknown>,
    updatePasswordSaga
  );

  yield takeLatest(
    types.RESET_UPDATE_PASSWORD_STATE as unknown as TakeableChannel<unknown>,
    handleUpdatePasswordResetState
  );

  yield takeLatest(
    types.GET_USER_NONCE as unknown as TakeableChannel<unknown>,
    getUserNonceSaga
  );
  yield takeLatest(
    types.GET_USER_USED_SPACE as unknown as TakeableChannel<unknown>,
    getUserUsedSpaceSaga
  );
  yield takeLatest(
    types.EMAIL_VERIFICATION as unknown as TakeableChannel<unknown>,
    emailVerificationSaga
  );
  yield takeLatest(
    types.RESET_VERIFY_EMAIL as unknown as TakeableChannel<unknown>,
    resetEmailVerificationSaga
  );
  yield takeLatest(
    types.SIGN_UP_INVITED_USER as unknown as TakeableChannel<unknown>,
    signUpInvitedUserSaga
  );

  yield takeLatest(
    types.USER_ORGANIZATIONS as unknown as TakeableChannel<unknown>,
    userOrganizationsSaga
  );
  yield takeLatest(
    types.ORGANIZATION_USER as unknown as TakeableChannel<unknown>,
    organizationUserSaga
  );

  yield takeLatest(
    types.REMOVE_ORGANIZATION_USER as unknown as TakeableChannel<unknown>,
    removeOrganizationUserSaga
  );

  yield takeLatest(
    types.ADD_USER_TO_ORGANIZATION as unknown as TakeableChannel<unknown>,
    addUserToOrganizationSaga
  );
}

export function* watchFolderSaga() {
  yield takeLatest(
    types.CREATE_FOLDER as unknown as TakeableChannel<unknown>,
    createFolderSaga
  );
  yield takeLatest(
    types.DELETE_FOLDER as unknown as TakeableChannel<unknown>,
    removeFolderSaga
  );
  yield takeLatest(
    types.CREATE_FOLDER as unknown as TakeableChannel<unknown>,
    resetFolderSagaState
  );
}

export function* watchPaymentSaga() {
  yield takeLatest(
    types.CREATE_PAYMENT as unknown as TakeableChannel<unknown>,
    createPaymentSaga
  );

  yield takeLatest(
    types.CANCEL_SUBSCRIPTION as unknown as TakeableChannel<unknown>,
    cancelSubscriptionSaga
  );

  yield takeLatest(
    types.PURCHASED_SPACE as unknown as TakeableChannel<unknown>,
    purchasedSpaceSaga
  );

  yield takeLatest(
    types.CANCEL_CHECKOUT_SESSION as unknown as TakeableChannel<unknown>,
    cancelCheckoutSessionSaga
  );
}

export function* watchNodeSaga() {
  yield takeLatest(
    types.USER_NODES as unknown as TakeableChannel<unknown>,
    getUserNodesSaga
  );

  yield takeLatest(
    types.DELETE_NODE_ON_ERROR as unknown as TakeableChannel<unknown>,
    deleteNodeOnErrorSaga
  );

  yield takeLatest(
    types.USER_NODE_REWARDS as unknown as TakeableChannel<unknown>,
    getPendingNodeRewardsSaga
  );

  yield takeLatest(
    types.REMOVE_NODE_WITH_COLLATERAL_RETURN as unknown as TakeableChannel<unknown>,
    removeNodeWithCollateralReturnSaga
  );

  yield takeLatest(
    types.NETWORK_STORAGE_STATS as unknown as TakeableChannel<unknown>,
    getNetworkTotalStorageSaga
  );

  yield takeLatest(
    types.EDIT_NODE_DETAILS as unknown as TakeableChannel<unknown>,
    editNodeDetailsSaga
  );
}
