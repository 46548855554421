/* eslint-disable import/prefer-default-export */

import { put, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import * as types from '../actions';

import {
  createUserKeyService,
  getUserNonceService,
  emailVerificationService,
  verifyUserPassPhraseService,
  getUserUsedSpaceService,
  signUpInvitedUserService,
  userOrganizationsService,
  organizationUserService,
  removeOrganizationUserService,
  addUserToOrganizationService,
  editUserService,
  updatePasswordService,
} from '../services/userService';
import {
  CreateUserKeySagaInterface,
  EditUserSagaInterface,
  EmailVerificationSagaInterface,
  ErrorSuccessResponseInterface,
  GetUserSagaInterface,
  GetUserUsedSpaceSagaInterface,
  SignUpInvitedUserSagaInterface,
  UpdatePasswordSagaInterface,
  VerifyUserPassPhraseSagaInterface,
} from '../interfaces';
import { logoutAction } from '../actions/authenticationActions';
import { updateProfileLoadingAction } from '../actions/userActions';

const resetStateValue = {
  data: {},
  success: false,
  message: '',
};
export function* verifyUserPassPhraseSaga(
  payload: VerifyUserPassPhraseSagaInterface
) {
  try {
    const response: ErrorSuccessResponseInterface = yield call(
      verifyUserPassPhraseService,
      payload.payload
    );
    if (response?.success) {
      toast.success(response?.message);
    }
    if (!response?.success) {
      toast.error(response?.message);
    }
    yield put({ type: types.VERIFY_PASS_PHRASE_RESPONSE, response });
  } catch (err) {
    yield put({ type: types.VERIFY_PASS_PHRASE_RESPONSE, err });
  }
}

export function* createUserKeySaga(payload: CreateUserKeySagaInterface) {
  try {
    const response: ErrorSuccessResponseInterface = yield call(
      createUserKeyService,
      payload.payload
    );
    if (response?.success) {
      toast.success(response?.message);
    }
    if (!response?.success) {
      toast.error(response?.message);
    }
    yield put({ type: types.CREATE_USER_KEY_RESPONSE, response });
  } catch (err) {
    yield put({ type: types.CREATE_USER_KEY_RESPONSE, err });
  }
}

export function* getUserNonceSaga(payload: GetUserSagaInterface) {
  try {
    const response: ErrorSuccessResponseInterface = yield call(
      getUserNonceService,
      payload.payload
    );

    if (!response?.success) {
      toast.error(response?.message);
    }

    yield put({ type: types.GET_USER_NONCE_RESPONSE, response });
  } catch (err) {
    yield put({ type: types.GET_USER_NONCE_RESPONSE, err });
  }
}

export function* emailVerificationSaga(
  payload: EmailVerificationSagaInterface
) {
  try {
    const response: ErrorSuccessResponseInterface = yield call(
      emailVerificationService,
      payload.payload
    );

    yield put({ type: types.EMAIL_VERIFICATION_RESPONSE, response });
  } catch (err) {
    yield put({ type: types.EMAIL_VERIFICATION_RESPONSE, err });
  }
}

export function* getUserUsedSpaceSaga(payload: GetUserUsedSpaceSagaInterface) {
  try {
    const response: ErrorSuccessResponseInterface = yield call(
      getUserUsedSpaceService,
      payload.payload.authToken
    );
    yield put({
      type: types.UPDATE_USER_USED_SPACE,
      response: response.data[0].totalUsedSpace,
    });
  } catch (err) {
    yield put({ type: types.UPDATE_USER_USED_SPACE, err });
  }
}

export function* signUpInvitedUserSaga(
  payload: SignUpInvitedUserSagaInterface
) {
  try {
    const response: ErrorSuccessResponseInterface = yield call(
      signUpInvitedUserService,
      payload.payload
    );

    if (response?.message && response?.success) {
      toast.success(response?.message);
    }

    if (response?.message && !response?.success) {
      toast.success(response?.message);
    }

    yield put({ type: types.SIGN_UP_INVITED_USER_RESPONSE, response });
  } catch (err) {
    yield put({ type: types.SIGN_UP_INVITED_USER_RESPONSE, err });
  }
}

export function* userOrganizationsSaga(payload: {
  payload: { authToken: string; page: number };
}) {
  try {
    const response: ErrorSuccessResponseInterface = yield call(
      userOrganizationsService,
      payload.payload
    );

    yield put({ type: types.USER_ORGANIZATIONS_RESPONSE, response });
  } catch (err) {
    yield put({ type: types.USER_ORGANIZATIONS_RESPONSE, err });
  }
}

export function* organizationUserSaga(payload: {
  payload: { authToken: string; page: number };
}) {
  try {
    const response: ErrorSuccessResponseInterface = yield call(
      organizationUserService,
      payload.payload
    );

    yield put({ type: types.ORGANIZATION_USER_RESPONSE, response });
  } catch (err) {
    console.error('file: userSaga.ts ~ err:', err);

    yield put({ type: types.ORGANIZATION_USER_RESPONSE, err });
  }
}

export function* removeOrganizationUserSaga(payload: {
  payload: {
    authToken: string;
    userId: string;
  };
}) {
  try {
    const response: ErrorSuccessResponseInterface = yield call(
      removeOrganizationUserService,
      payload.payload
    );
    if (response?.message && response?.success) {
      toast.success(response?.message);
    }

    if (response?.message && !response?.success) {
      toast.error(response?.message);
    }
    yield put({ type: types.REMOVE_ORGANIZATION_USER_RESPONSE, response });
  } catch (err) {
    console.error(
      'file: userSaga.ts ~ function*removeOrganizationUserSaga ~ err:',
      err
    );

    yield put({ type: types.REMOVE_ORGANIZATION_USER_RESPONSE, err });
  }
}

export function* addUserToOrganizationSaga(payload: {
  payload: {
    authToken: string;
    email: string;
    userType: string;
  };
}) {
  try {
    const response: ErrorSuccessResponseInterface = yield call(
      addUserToOrganizationService,
      payload.payload
    );

    if (response?.message && response?.success) {
      toast.success(response?.message);
    }

    if (response?.message && !response?.success) {
      toast.error(response?.message);
    }

    yield put({ type: types.ADD_USER_TO_ORGANIZATION_RESPONSE, response });
  } catch (err) {
    console.error(
      'file: userSaga.ts ~ function*removeOrganizationUserSaga ~ err:',
      err
    );

    yield put({ type: types.ADD_USER_TO_ORGANIZATION_RESPONSE, err });
  }
}

export function* editUserSaga(payload: EditUserSagaInterface) {
  try {
    yield put(updateProfileLoadingAction(true));
    const { setUserDetails } = payload?.payload ?? {};

    const response: ErrorSuccessResponseInterface = yield call(
      editUserService,
      payload.payload
    );

    if (response?.success) {
      setUserDetails(response?.data);
      toast.success(response?.message);
    }
    if (!response?.success) {
      toast.error(response?.message);
    }
    yield put({ type: types.EDIT_USER_SUCCESS, response });
    yield put(updateProfileLoadingAction(false));
  } catch (err) {
    yield put(updateProfileLoadingAction(false));
    yield put({ type: types.EDIT_USER_ERROR, err });
  }
}

export function* updatePasswordSaga(payload: UpdatePasswordSagaInterface) {
  try {
    const { navigate } = payload?.payload ?? {};
    const response: ErrorSuccessResponseInterface = yield call(
      updatePasswordService,
      payload?.payload
    );
    if (response?.success) {
      yield put({ type: types.UPDATE_PASSWORD_SUCCESS, response });
      toast.success(response?.message);
      window.localStorage.clear();
      yield put(logoutAction());
      yield put({ type: types.UPDATE_PASSWORD_SUCCESS, resetStateValue });
      navigate('/sign-in');
    }
    if (!response?.success) {
      toast.error(response?.message);
      yield put({ type: types.UPDATE_PASSWORD_SUCCESS, response });
    }
  } catch (err) {
    yield put({ type: types.UPDATE_PASSWORD_ERROR, err });
  }
}

export function* handleUpdatePasswordResetState() {
  yield put({ type: types.UPDATE_PASSWORD_SUCCESS, resetStateValue });
}

export function* resetEmailVerificationSaga(payload: any) {
  try {
    yield put({ type: types.EMAIL_VERIFICATION_RESPONSE, payload });
  } catch (err) {
    yield put({ type: types.EMAIL_VERIFICATION_RESPONSE, err });
  }
}

export function* resetUserNonceSaga(payload: any) {
  try {
    yield put({ type: types.GET_USER_NONCE_RESPONSE, payload });
  } catch (err) {
    yield put({ type: types.GET_USER_NONCE_RESPONSE, err });
  }
}
