import {
  EmailVerificationInterface,
  ErrorSuccessResponseInterface,
  ForgetPasswordInterface,
  UpdatePasswordInterface,
  UserDetailsInterface,
  UserLoginDetailsInterface,
  UserSignUpInterface,
} from '../interfaces';
import apiRequestHandler from '../utils/axiosHelper';

export const registerUserService = async (
  payload: UserSignUpInterface
): Promise<ErrorSuccessResponseInterface> => {
  try {
    if (!payload?.user?.fullname) {
      return false as unknown as ErrorSuccessResponseInterface;
    }

    const response = await apiRequestHandler({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}auth/sign-up`,
      data: payload.user,
    });

    return response;
  } catch (err: any) {
    return err?.response?.data;
  }
};

export const loginUserService = async (
  payload: UserLoginDetailsInterface
): Promise<ErrorSuccessResponseInterface> => {
  try {
    if (payload?.email === '' && payload?.password === '') {
      return {
        data: {},
        success: false,
        message: 'please provide user email and password',
      };
    }

    const response = await apiRequestHandler({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}auth/login`,
      data: {
        email: payload.email,
        password: payload.password,
      },
    });

    return response;
  } catch (err: any) {
    return err?.response?.data || err.message;
  }
};

export const userDetailsService = async (
  payload: UserDetailsInterface
): Promise<ErrorSuccessResponseInterface> => {
  try {
    if (!payload.user.authToken) {
      return { data: '', success: false, message: '' };
    }
    const response = await apiRequestHandler(
      {
        method: 'get',
        url: `${process.env.REACT_APP_API_BASE_URL}auth/userDetails`,
      },
      payload?.user?.authToken
    );

    return response;
  } catch (err: any) {
    return err?.response?.data || err.message;
  }
};

export const forgetPasswordService = async (
  payload: ForgetPasswordInterface
): Promise<ErrorSuccessResponseInterface> => {
  try {
    const emailAddress = payload?.user?.emailAddress;

    const response = await apiRequestHandler({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}auth/forget-password/${emailAddress}`,
    });
    return response;
  } catch (err: any) {
    return err?.response?.data || err.message;
  }
};

export const resetPasswordService = async (
  payload: UpdatePasswordInterface
): Promise<ErrorSuccessResponseInterface> => {
  try {
    const { userId, resetHash, password } = payload.user;

    const response = await apiRequestHandler({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}auth/forget-password/${userId}/${resetHash}`,
      data: { password },
    });

    return response;
  } catch (err: any) {
    return err?.response?.data || err.message;
  }
};

export const emailVerifyService = async (
  payload: EmailVerificationInterface
): Promise<ErrorSuccessResponseInterface> => {
  try {
    const { email } = payload.user;
    const response = await apiRequestHandler({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}auth/authenticate-email`,
      data: { email },
    });
    return response;
  } catch (err: any) {
    return err?.response?.data || err.message;
  }
};
