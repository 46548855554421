// eslint-disable-next-line import/prefer-default-export
export const networks = {
  storageChain: {
    mainnet: {
      chainId: '0x2216',
      chainName: 'StorageChain Mainnet',
      nativeCurrency: {
        name: 'STOR',
        symbol: 'STOR',
        decimals: 18,
      },
      rpcUrls: [process.env.REACT_APP_RPC_URL],
      blockExplorerUrls: [process.env.REACT_APP_EXPLORER_LINK],
    },
    testnet: {
      chainId: '0x2217',
      chainName: 'StorageChain Testnet',
      nativeCurrency: {
        name: 'STOR',
        symbol: 'STOR',
        decimals: 18,
      },
      rpcUrls: [process.env.REACT_APP_RPC_URL],
      blockExplorerUrls: [process.env.REACT_APP_EXPLORER_LINK],
    },
  },
};
