/* eslint-disable import/prefer-default-export */
import { put, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import * as types from '../actions';

import {
  CreateBucketInterface,
  DeleteBucketInterface,
  GetBucketsByUserIdInterface,
} from '../interfaces/bucketInterface';
import { ErrorSuccessResponseInterface } from '../interfaces/responseInterface';
import {
  createBucketService,
  deleteBucketService,
  getBucketsByUserIdService,
} from '../services/bucketService';
import { deleteBucketLoadingAction } from '../actions/bucketActions';

export function* createBucketSaga(payload: CreateBucketInterface) {
  try {
    const response: ErrorSuccessResponseInterface = yield call(
      createBucketService,
      payload
    );
    if (response?.success) {
      toast.success(response?.message);
    }
    if (!response?.success) {
      toast.error(response?.message);
    }
    yield put({ type: types.CREATE_BUCKET_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.CREATE_BUCKET_ERROR, error });
  }
}

export function* getBucketsByUserIdSaga(payload: GetBucketsByUserIdInterface) {
  try {
    yield put({ type: types.GET_BUCKETS_BY_USER_LOADER, response: true });
    const response: ErrorSuccessResponseInterface = yield call(
      getBucketsByUserIdService,
      payload
    );
    yield put({ type: types.GET_BUCKETS_BY_USER_SUCCESS, response });
  } catch (err) {
    yield put({ type: types.GET_BUCKETS_BY_USER_ERROR, err });
  } finally {
    yield put({ type: types.GET_BUCKETS_BY_USER_LOADER, response: false });
  }
}

export function* deleteBucketSaga(payload: DeleteBucketInterface) {
  try {
    yield put(deleteBucketLoadingAction(true));
    const response: ErrorSuccessResponseInterface = yield call(
      deleteBucketService,
      payload
    );
    if (response?.success) {
      toast.success(response?.message);
    }
    if (!response?.success) {
      toast.error(response?.message);
    }
    yield put(deleteBucketLoadingAction(false));
    yield put({ type: types.DELETE_BUCKET_SUCCESS, response });
  } catch (err) {
    yield put(deleteBucketLoadingAction(false));
    yield put({ type: types.DELETE_BUCKET_ERROR, err });
  }
}
