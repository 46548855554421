import StorageChainContractABI from '../contracts/StorageChainContractABI.json';
import CollateralContractABI from '../contracts/CollateralContractABI.json';
import StorageChainMintingContractABI from '../contracts/StorageChainMintingContractABI.json';
import PolygonMintingContractABI from '../contracts/PolygonMintingContractABI.json';
import SubscriptionContractABI from '../contracts/SubscriptionContractABI.json';

import StorageChainWrapUnwrapContractABI from '../contracts/storagechain-contract-abi.json';
import PolygonWrapUnwrapContractABI from '../contracts/polygon-contract-abi.json';
import EthereumWrapUnwrapContractABI from '../contracts/ethereum-contract-abi.json';

export const STORAGE_CHAIN_CONTRACT_ADDRESS = {
  8726: {
    address: process.env.REACT_APP_STORAGE_CONTRACT, //  Storage Chain Contract Address
    contractABI: StorageChainContractABI,
  },
  8727: {
    address: process.env.REACT_APP_STORAGE_CONTRACT, //  Storage Chain Contract Address
    contractABI: StorageChainContractABI,
  },
};

export const COLLATERAL_CONTRACT_ADDRESS = {
  8726: {
    address: process.env.REACT_APP_COLLATERAL_CONTRACT, // Collateral Contract Address
    contractABI: CollateralContractABI,
  },
  8727: {
    address: process.env.REACT_APP_COLLATERAL_CONTRACT, // Collateral Contract Address
    contractABI: CollateralContractABI,
  },
};

export const EXPLORER_LINK = {
  storageChain: process.env.REACT_APP_EXPLORER_LINK, //  Storage chain explorer Url
};

export const STORAGE_CHAIN_MINTING_CONTRACT_ADDRESS = {
  8726: {
    address: process.env.REACT_APP_STORAGECHAIN_MINTING_CONTRACT_ADDRESS,
    contractABI: StorageChainMintingContractABI,
  },
  8727: {
    address: process.env.REACT_APP_STORAGECHAIN_MINTING_CONTRACT_ADDRESS,
    contractABI: StorageChainMintingContractABI,
  },
  80001: {
    address: process.env.REACT_APP_MINTING_CONTRACT_ADDRESS,
    contractABI: PolygonMintingContractABI,
  },
};

export const STORAGE_CHAIN_WRAP_UNWRAP_CONTRACT_ADDRESS = {
  8726: {
    address: process.env.REACT_APP_STORAGECHAIN_CONTRACT_ADDRESS,
    contractABI: StorageChainWrapUnwrapContractABI,
  },
  8727: {
    address: process.env.REACT_APP_STORAGECHAIN_CONTRACT_ADDRESS,
    contractABI: StorageChainWrapUnwrapContractABI,
  },
  80001: {
    address: process.env.REACT_APP_POLYGON_CONTRACT_ADDRESS,
    contractABI: PolygonWrapUnwrapContractABI,
  },
  137: {
    address: process.env.REACT_APP_POLYGON_CONTRACT_ADDRESS,
    contractABI: PolygonWrapUnwrapContractABI,
  },
  5: {
    address: process.env.REACT_APP_ETH_CONTRACT_ADDRESS,
    contractABI: EthereumWrapUnwrapContractABI,
  },
  1: {
    address: process.env.REACT_APP_ETH_CONTRACT_ADDRESS,
    contractABI: EthereumWrapUnwrapContractABI,
  },
};

export const SUBSCRIPTION_CONTRACT_ADDRESS = {
  8726: {
    address: process.env.REACT_APP_STORAGECHAIN_SUBSCRIPTION_CONTRACT_ADDRESS,
    contractABI: SubscriptionContractABI,
  },
  8727: {
    address: process.env.REACT_APP_STORAGECHAIN_SUBSCRIPTION_CONTRACT_ADDRESS,
    contractABI: SubscriptionContractABI,
  },
};
