/* eslint-disable import/prefer-default-export */
import {
  CreateBucketInterface,
  DeleteBucketInterface,
  GetBucketsByUserIdInterface,
} from '../interfaces/bucketInterface';
import * as types from './index';

export const createBucketAction = (bucket: CreateBucketInterface) => ({
  type: types.CREATE_BUCKET,
  bucket,
});

export const getBucketsByUserIdAction = (
  payload: GetBucketsByUserIdInterface
) => ({ type: types.GET_BUCKETS_BY_USER, payload });

export const deleteBucketAction = (payload: DeleteBucketInterface) => ({
  type: types.DELETE_BUCKET,
  payload,
});

export const deleteBucketLoadingAction = (deleteBucketLoading: boolean) => ({
  type: types.SET_BUCKET_DELETE_LOADING,
  payload: deleteBucketLoading,
});
