/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import axios from 'axios';

function HandleError() {
  const [reqError, setReqError] = useState(null);
  const [resError, setResError] = useState(null);

  axios.interceptors.request.use(
    async (config) => config,
    async (error) => {
      setReqError(JSON.stringify(error, null, 4));
      return Promise.reject(error);
    }
  );
  axios.interceptors.response.use(
    async (config) => config,
    async (error) => {
      setResError(JSON.stringify(error, null, 4));
      return Promise.reject(error);
    }
  );

  return <div></div>;
}

export default HandleError;
