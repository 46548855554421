/* eslint-disable react/jsx-boolean-value */
import React, { Suspense, useEffect, lazy } from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Auth from './hoc/auth';
import PageLoader from './components/common/PageLoader';
import { getPurchasedSpaceAction } from './actions/paymentActions';
import useLocalStorage from './hooks/useLocalStorage';
// import Map from './components/Map';

const CreateBucket = lazy(() => import('./views/CreateBucket'));
const Signin = lazy(() => import('./views/Signin'));
const Signup = lazy(() => import('./views/Signup'));
const Dashboard = lazy(() => import('./views/Dashboard'));
const Faq = lazy(() => import('./views/Faq'));
const Users = lazy(() => import('./views/Users'));
const Upload = lazy(() => import('./views/Upload'));
const SharedFolder = lazy(() => import('./views/SharedFolder'));
const Buckets = lazy(() => import('./views/Buckets'));
const HostNode = lazy(() => import('./views/HostNode'));
const NodeStep = lazy(() => import('./views/NodeSteps'));
const MyStorageNode = lazy(() => import('./views/MyStorageNode'));
const SharedFile = lazy(() => import('./views/SharedFile'));
const BucketFiles = lazy(() => import('./views/BucketFiles'));
const SingleBucket = lazy(() => import('./views/SingleBucket'));
const ForgotPassword = lazy(() => import('./views/ForgotPassword'));
const ResetPassword = lazy(() => import('./views/ResetPassword'));
const DataAccess = lazy(() => import('./views/DataAccess'));
const StorageStats = lazy(() => import('./views/StorageStats'));
const PaymentInfo = lazy(() => import('./views/PaymentInfo'));
const SearchPage = lazy(() => import('./views/SearchPage'));
const EmailVarification = lazy(() => import('./views/VarificationCompleted'));
const VerifyEmail = lazy(() => import('./views/VerifyEmail'));
const AccessDenied = lazy(() => import('./views/AccessDenied'));
const SharedAccess = lazy(() => import('./views/SharedAccess'));
const MyAccount = lazy(() => import('./views/MyAccount'));

const AdminPannel = lazy(() => import('./components/AdminPannel'));
const Home = lazy(() => import('./components/LandingPages/Home'));
const HowItWork = lazy(() => import('./components/LandingPages/HowItWorks'));
const WhyStoreHere = lazy(
  () => import('./components/LandingPages/WhyStoreHere')
);
const HostEarn = lazy(() => import('./components/LandingPages/HostEarn'));
const Aboutus = lazy(() => import('./components/LandingPages/Aboutus'));
const PrivacyPolicy = lazy(
  () => import('./components/LandingPages/PrivacyPolicy')
);
const TermsOfService = lazy(
  () => import('./components/LandingPages/TermsAndService')
);
const SystemRequirement = lazy(
  () => import('./components/LandingPages/SystemRequirement')
);
const PressSep = lazy(() => import('./components/LandingPages/press-sep'));
const PressJan = lazy(() => import('./components/LandingPages/press-jan'));
const PressApril2 = lazy(
  () => import('./components/LandingPages/press-april-2')
);
const PressMay1 = lazy(() => import('./components/LandingPages/press-may-1'));
const PressMay8 = lazy(() => import('./components/LandingPages/press-may-8'));
const PressMay13 = lazy(() => import('./components/LandingPages/press-may-13'));
const PressJune05 = lazy(
  () => import('./components/LandingPages/press-june-05')
);
const PressAug = lazy(() => import('./components/LandingPages/press-aug'));
const PressNerdnode = lazy(
  () => import('./components/LandingPages/press-nerdnode')
);
const PressJuly = lazy(() => import('./components/LandingPages/press-july'));
const Press = lazy(() => import('./components/LandingPages/Press'));

const SignUpInvite = lazy(() => import('./views/SignupInvite'));
const Organizations = lazy(() => import('./views/Organizations'));
const OrganizationBucket = lazy(
  () => import('./components/Organizations/OrganizationBucket')
);
const CancelUserCheckoutSession = lazy(
  () => import('./components/CancelPaymentCheckout')
);
const PurchaseSuccess = lazy(() => import('./components/PurchaseSuccess'));
const GlobalSearch = lazy(() => import('./components/GlobalSearch'));
const NodeDetailsPageWebUi = lazy(
  () => import('./components/NodeDetailsPageWebUi')
);

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [authToken] = useLocalStorage('accessToken', '');
  useEffect(() => {
    if (authToken) {
      dispatch(getPurchasedSpaceAction(authToken));
    }
    const handleScroll = () => {
      const sectionId = window.location?.hash?.substring(1);

      if (sectionId) {
        const section = document.getElementById(sectionId);
        if (section) {
          const headerHeight =
            document.getElementById('header')?.clientHeight || 0;
          const sectionTop = section.offsetTop - headerHeight;
          window.scrollTo({ top: sectionTop, behavior: 'smooth' });
        }
      } else {
        window.scrollTo(0, 0);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    };

    handleScroll();

    window.addEventListener('hashchange', handleScroll);
    return () => {
      window.removeEventListener('hashchange', handleScroll);
    };
  }, [location]);
  return (
    <Suspense fallback={<PageLoader />}>
      <Routes>
        {/* <Route path='/map' element={<Map />} /> */}
        <Route path='/' element={<Home />} />
        <Route path='/home' element={<Navigate to='/' replace />} />
        <Route path='/how-it-works' element={<HowItWork />} />
        <Route path='/why-store-here' element={<WhyStoreHere />} />
        <Route path='/host-and-earn' element={<HostEarn />} />
        <Route path='/about-us' element={<Aboutus />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/terms-of-services' element={<TermsOfService />} />
        <Route path='/system-requirement' element={<SystemRequirement />} />
        <Route path='/press-sep' element={<PressSep />} />
        <Route path='/press-jan' element={<PressJan />} />
        <Route path='/press-april-2' element={<PressApril2 />} />
        <Route path='/press-may-1' element={<PressMay1 />} />
        <Route path='/press-may-9' element={<PressMay8 />} />
        <Route path='/press-may-13' element={<PressMay13 />} />
        <Route path='/press-june-05' element={<PressJune05 />} />
        <Route path='/press-nerdnode' element={<PressNerdnode />} />
        <Route path='/press-july' element={<PressJuly />} />
        <Route path='/press-augest' element={<PressAug />} />
        <Route path='/press' element={<Press />} />
        <Route
          path='/dashboard'
          element={
            <Auth element={Dashboard} isProtected={true} isLayout={true} />
          }
        />
        <Route path='/sign-in' element={<Signin />} />
        <Route path='/sign-up' element={<Signup />} />
        <Route path='/sign-up/:email' element={<SignUpInvite />} />
        <Route
          path='/faq'
          element={<Auth element={Faq} isProtected={true} isLayout={true} />}
        />
        <Route
          path='/users'
          element={<Auth element={Users} isProtected={true} isLayout={true} />}
        />
        <Route
          path='/organizations'
          element={
            <Auth element={Organizations} isProtected={true} isLayout={true} />
          }
        />
        <Route
          path='/organizations/:organizationId'
          element={
            <Auth
              element={OrganizationBucket}
              isProtected={true}
              isLayout={true}
            />
          }
        />
        <Route
          path='/organizations/:organizationId/:bucketId'
          element={
            <Auth element={SingleBucket} isProtected={true} isLayout={true} />
          }
        />
        <Route
          path='/upload'
          element={<Auth element={Upload} isProtected={true} isLayout={true} />}
        />
        <Route
          path='/create-bucket'
          element={
            <Auth element={CreateBucket} isProtected={true} isLayout={false} />
          }
        />
        <Route
          path='/buckets'
          element={
            <Auth element={Buckets} isProtected={true} isLayout={true} />
          }
        />
        <Route
          path='/buckets/:bucketId'
          element={
            <Auth element={SingleBucket} isProtected={true} isLayout={true} />
          }
        />
        <Route
          path='/upload'
          element={<Auth element={Upload} isProtected={true} isLayout={true} />}
        />
        <Route
          path='/shared-folder/:accessKey'
          element={
            <Auth element={SharedFolder} isProtected={false} isLayout={false} />
          }
        />
        <Route
          path='/host-a-node'
          element={
            <Auth element={HostNode} isProtected={true} isLayout={true} />
          }
        />
        <Route
          path='/shared-file/:accessKey'
          element={
            <Auth element={SharedFile} isProtected={false} isLayout={false} />
          }
        />
        <Route
          path='/bucket-files'
          element={
            <Auth element={BucketFiles} isProtected={true} isLayout={true} />
          }
        />
        <Route
          path='/my-storage-node'
          element={
            <Auth element={MyStorageNode} isProtected={true} isLayout={true} />
          }
        />
        <Route
          path='/forgot-password'
          element={
            <Auth
              element={ForgotPassword}
              isProtected={false}
              isLayout={false}
            />
          }
        />
        <Route
          path='/reset-password/:userId/:resetHash'
          element={
            <Auth
              element={ResetPassword}
              isProtected={false}
              isLayout={false}
            />
          }
        />
        <Route
          path='/data-access'
          element={
            <Auth element={DataAccess} isProtected={false} isLayout={true} />
          }
        />
        <Route
          path='/shared-access'
          element={
            <Auth element={SharedAccess} isProtected={false} isLayout={true} />
          }
        />
        <Route
          path='/my-account'
          element={
            <Auth element={MyAccount} isProtected={true} isLayout={true} />
          }
        />
        <Route
          path='/setup-node'
          element={
            <Auth element={NodeStep} isProtected={true} isLayout={true} />
          }
        />

        <Route
          path='/storage-stats'
          element={
            <Auth element={StorageStats} isProtected={false} isLayout={true} />
          }
        />
        <Route
          path='/payment-info'
          element={
            <Auth element={PaymentInfo} isProtected={false} isLayout={true} />
          }
        />
        <Route
          path='/search-result'
          element={
            <Auth element={SearchPage} isProtected={false} isLayout={true} />
          }
        />
        <Route
          path='/verification-completed/:userId/:verificationToken'
          element={
            <Auth
              element={EmailVarification}
              isProtected={false}
              isLayout={false}
            />
          }
        />
        <Route
          path='/verify-email'
          element={
            <Auth element={VerifyEmail} isProtected={false} isLayout={false} />
          }
        />
        <Route
          path='/access-denied'
          element={
            <Auth element={AccessDenied} isProtected={false} isLayout={false} />
          }
        />
        <Route
          path='/cancel-checkout'
          element={
            <Auth
              element={CancelUserCheckoutSession}
              isProtected={false}
              isLayout={false}
            />
          }
        />
        <Route
          path='/purchase-success'
          element={
            <Auth
              element={PurchaseSuccess}
              isProtected={false}
              isLayout={false}
            />
          }
        />
        <Route
          path='/search'
          element={
            <Auth element={GlobalSearch} isProtected={true} isLayout={true} />
          }
        />

        <Route
          path='/my-storage-node/:nodeId'
          element={<Auth element={NodeDetailsPageWebUi} isProtected isLayout />}
        />

        <Route
          path='/admin-pannel'
          element={<Auth element={AdminPannel} isProtected isLayout />}
        />
      </Routes>
    </Suspense>
  );
}

export default App;
