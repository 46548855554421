/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { Cluster } from '@nftstorage/ipfs-cluster';
import axios from 'axios';
import { toast } from 'react-toastify';

import { UPDATE_FILE_UPLOAD_PROGRESS } from '../actions';
import {
  ErrorSuccessResponseInterface,
  FileAddDbInterface,
  FileUploadInterface,
  GrantAccess,
  GrantAccessInformationInterface,
  MintFileInterface,
  MoveFileAndFolderService,
  MoveFileToFolderInterface,
  UpdateInformationInterface,
  SharedFolderFileAccess,
  SharedFolderFilesQueryObject,
  UsedSpaceGraphParamsInterface,
  GrantS3Access,
} from '../interfaces';
import store from '../store';
import { LIMIT } from '../utils/constant';
import { createQueryStringFromObject } from '../utils/helpers/helper';

const cluster = new Cluster(process.env.REACT_APP_CLUSTER_URL);

export const uploadFileService = async (payload: FileUploadInterface) => {
  const { encryptedFile, name, index, size, totalChunks } = payload;
  try {
    const clusterResponst = await cluster.add(encryptedFile, {
      'cid-version': 1,
      replicationFactorMax: 5,
      replicationFactorMin: 1,
      streamChannels: true,
      local: true,

      onUploadProgress: (data: any) => {
        const action = {
          type: UPDATE_FILE_UPLOAD_PROGRESS,
          response: {
            bytesUploaded: data.bytes,
            name,
            size: size > 50000 ? size : data.total, // conditon to fix upload bar % issue on KB file
            totalChunks,
            index,
          },
        };
        store.dispatch(action);
      },
      name: encryptedFile.name,
    });
    console.log('uploadFileService ~ clusterResponst:', clusterResponst);
    return { ...clusterResponst, index };
  } catch (err: any) {
    console.log('uploadFileService ~ err:', err);
    return err?.response?.data;
  }
};

export const addFileInDbService = async (
  payload: FileAddDbInterface,
  authToken: string
): Promise<ErrorSuccessResponseInterface> => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}file/add`,
      data: payload,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    const actualResponse = response.data;
    return actualResponse;
  } catch (err: any) {
    return err?.response?.data || err.message;
  }
};

export const fetchUserFiles = async (
  userId: string,
  bucketId: string,
  authToken: string,
  page: number,
  sortQuery: any,
  folderId: string,
  searchedText: string,
  metaSearch: boolean
): Promise<ErrorSuccessResponseInterface> => {
  try {
    let filter = `filter={"userId": "${userId}"}`;
    if (bucketId) {
      filter = `filter={"userId": "${userId}", "bucketId": "${bucketId}"}`;
    }

    if (folderId) {
      filter = `filter={"userId": "${userId}", "folderId": "${folderId}"}`;
    }

    let url = `${
      process.env.REACT_APP_API_BASE_URL
    }file/all?${filter}&sort=-_id&page=${page}&limit=${LIMIT}&sortQuery=${JSON.stringify(
      sortQuery
    )}`;

    if (searchedText) {
      url = `${
        process.env.REACT_APP_API_BASE_URL
      }file/all?${filter}&sort=-_id&page=${page}&limit=${LIMIT}&sortQuery=${JSON.stringify(
        sortQuery
      )}&searchedText=${searchedText}`;
    }
    if (metaSearch) {
      url += `&metaSearch=${metaSearch}`;
    }

    const response = await axios({
      method: 'get',
      url,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    const actualResponse = response.data;
    return actualResponse;
  } catch (err: any) {
    return err?.response?.data || err.message;
  }
};

export const fetchUserSharedFolderFilesService = async (
  queryObject: SharedFolderFilesQueryObject
): Promise<ErrorSuccessResponseInterface> => {
  try {
    const queryString = createQueryStringFromObject(queryObject);
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_URL}file/shared-folder-files${queryString}`,
    });
    const actualResponse = response.data;
    return actualResponse;
  } catch (err: any) {
    console.error('file: fileService.ts:122 ~ err:', err);
    return err?.response?.data || err.message;
  }
};

export const grantSharedFolderFileAccess = async (
  payload: SharedFolderFileAccess
) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}file/grant-folder-file-access`,
      data: payload,
    });
    const actualResponse = response.data;
    return actualResponse;
  } catch (err: any) {
    console.error('file: fileService.ts:122 ~ err:', err);
    return err?.response?.data || err.message;
  }
};

export const grantAccess = async (
  data: GrantAccess,
  authToken: string
): Promise<ErrorSuccessResponseInterface> => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}file/grant/access`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      data,
    });
    const actualResponse = response.data;
    return actualResponse;
  } catch (err: any) {
    return err?.response?.data || err.message;
  }
};

export const setInvitesAccessService = async (
  data: Array<GrantAccess>,
  authToken: string
): Promise<ErrorSuccessResponseInterface> => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}file/access/invites`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      data,
    });
    const actualResponse = response.data;
    return actualResponse;
  } catch (err: any) {
    return err?.response?.data || err.message;
  }
};

export const getUserAccessesService = async (
  authToken: string,
  page: number
): Promise<ErrorSuccessResponseInterface> => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_URL}file/access/invites/list?sort=-_id&page=${page}&limit=10`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    const actualResponse = response.data;
    return actualResponse;
  } catch (err: any) {
    return err?.response?.data || err.message;
  }
};

export const grantAccessToken = async (
  grantAccessInformation: GrantAccessInformationInterface
): Promise<ErrorSuccessResponseInterface> => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}file/grant/access/token`,
      headers: {
        Authorization: `Bearer ${grantAccessInformation?.authToken}`,
      },
      data: {
        organizationId: grantAccessInformation?.organizationId || null,
        organizationEmail: grantAccessInformation?.organizationEmail || null,
        accessId: grantAccessInformation.accessId,
      },
    });
    const actualResponse = response.data;
    return actualResponse;
  } catch (err: any) {
    return err?.response?.data || err.message;
  }
};

export const moveFileToFolderService = async (
  authToken: string,
  data: MoveFileToFolderInterface
): Promise<ErrorSuccessResponseInterface> => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}file/move`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      data,
    });
    const actualResponse = response.data;
    return actualResponse;
  } catch (err: any) {
    return err?.response?.data || err.message;
  }
};

export const getFileAccess = async (
  accessKey: string
): Promise<ErrorSuccessResponseInterface> => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_URL}file/access/${accessKey}`,
    });
    const actualResponse = response.data;
    return actualResponse;
  } catch (err: any) {
    return err?.response?.data || err.message;
  }
};

export const getUserAccess = async (
  authToken: string
): Promise<ErrorSuccessResponseInterface> => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_URL}file/all/access`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    const actualResponse = response.data;
    return actualResponse;
  } catch (err: any) {
    return err?.response?.data || err.message;
  }
};

export const revokeAccess = async (
  authToken: string,
  accessId: string
): Promise<ErrorSuccessResponseInterface> => {
  try {
    const response = await axios({
      method: 'delete',
      url: `${process.env.REACT_APP_API_BASE_URL}file/access/revoke/${accessId}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    const actualResponse = response.data;
    return actualResponse;
  } catch (err: any) {
    return err?.response?.data || err.message;
  }
};

export const updateUserAccess = async (
  authToken: string,
  accessId: string,
  dataToUpdate: any
): Promise<ErrorSuccessResponseInterface> => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}file/access/update/${accessId}`,
      data: dataToUpdate,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    const actualResponse = response.data;
    return actualResponse;
  } catch (err: any) {
    return err?.response?.data || err.message;
  }
};

export const deleteFileService = async (
  authToken: string,
  fileId: string
): Promise<ErrorSuccessResponseInterface> => {
  try {
    const response = await axios({
      method: 'delete',
      url: `${process.env.REACT_APP_API_BASE_URL}file/delete/${fileId}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    const actualResponse = response.data;
    return actualResponse;
  } catch (err: any) {
    return err?.response?.data || err.message;
  }
};

export const mintFileService = async (
  mintFileInformation: MintFileInterface
) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}file/mint-file`,
      data: {
        fileId: mintFileInformation?.fileId,
        tokenId: mintFileInformation?.tokenId,
        minted: mintFileInformation?.minted,
        tokenUri: mintFileInformation?.tokenUri,
        name: mintFileInformation?.name,
        description: mintFileInformation?.description,
        animation_url: mintFileInformation?.animation_url,
        image: mintFileInformation?.image,
        external_url: mintFileInformation?.external_url,
        fileType: mintFileInformation?.fileType,
        contractAddress: mintFileInformation?.contractAddress,
      },
      headers: {
        Authorization: `Bearer ${mintFileInformation?.authToken}`,
      },
    });
    const actualResponse = response.data;
    return actualResponse;
  } catch (err: any) {
    console.error('file: fileService.ts ~ err:', err);
    return err?.response?.data || err.message;
  }
};

export const getMintedFiles = async (payload: {
  authToken: string;
  page: number;
  limit: number;
}): Promise<ErrorSuccessResponseInterface> => {
  try {
    console.log('payload ===> ', payload);
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_URL}file/minted-files`,
      params: {
        page: payload?.page,
        limit: payload?.limit,
      },
      headers: {
        Authorization: `Bearer ${payload?.authToken}`,
      },
    });
    const actualResponse = response.data;
    return actualResponse;
  } catch (err: any) {
    console.error('file: fileService.ts ~ err:', err);
    return err?.response?.data || err.message;
  }
};

export const searchFile = async (
  authToken: string,
  fileName: string
): Promise<ErrorSuccessResponseInterface> => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}file/search`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      data: {
        fileName,
      },
    });
    const actualResponse = response.data;
    return actualResponse;
  } catch (err: any) {
    return err?.response?.data || err.message;
  }
};

export const getNetworkUsedStorageSpaceService = async () => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_URL}file/network-used-space`,
      // headers: {
      //   Authorization: `Bearer ${authToken}`,
      // },
    });
    const actualResponse = response.data;
    return actualResponse;
  } catch (err: any) {
    console.error(
      'file: fileService.ts ~ getNetworkUsedStorageSpace ~ err:',
      err
    );
    return err?.response?.data || err.message;
  }
};

export const updateFileFolderNameService = async (
  updateInformation: UpdateInformationInterface
): Promise<ErrorSuccessResponseInterface> => {
  try {
    const { authToken, ...otherBodyData } = updateInformation;
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}file/update-file-folder-name`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      data: otherBodyData,
    });
    const actualResponse = response.data;
    if (actualResponse.success) {
      toast.success(actualResponse?.message);
    }
    return actualResponse;
  } catch (err: any) {
    toast.error(err?.response?.data?.message || err.message);
    return err?.response?.data || err.message;
  }
};
export const moveFileAndFolderService = async (
  payload: MoveFileAndFolderService
) => {
  try {
    const {
      authToken,
      loadUserFiles: _loadUserFiles,
      setMoveFileAndFolder: _setMoveFileAndFolder,
      ...data
    } = payload;
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}file/move-file-or-folder`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      data,
    });
    const actualResponse = response.data;
    return actualResponse;
  } catch (err: any) {
    return err?.response?.data || err.message;
  }
};

export const fetchUsedSpaceGraphDataService = async (
  authToken: string,
  params?: UsedSpaceGraphParamsInterface
) => {
  try {
    // const { authToken, ...params } = payload;
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_URL}file/admin/used-space-graph`,
      params,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    return response?.data?.data;
  } catch (err: any) {
    console.error('err:', err);
    throw new Error(err?.message);
  }
};

export const folderMetadata = async (authToken: string, data: any) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}file/upload-meta`,
      data,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    return response?.data?.data;
  } catch (err: any) {
    console.error('err:', err);
    throw new Error(err?.message);
  }
};

export const grantS3ApiAccess = async (
  data: GrantS3Access,
  authToken: string
): Promise<ErrorSuccessResponseInterface> => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}file/grant-s3-file-access`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      data,
    });
    const actualResponse = response.data;
    return actualResponse;
  } catch (err: any) {
    return err?.response?.data || err.message;
  }
};
